import { Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { ButtonDefault, ButtonIcon, CustomDivider, FieldTagline, FieldUnitDropdown, FieldUnitGroup, FieldsInlineWrapper, FormField, FormGroup, Heading, LabelHeading, LinkPrimary, RadioSwitcherWrapper, RadiosInlineWrapper, RangeRow, RangesWrapper, ScheduleWrapper, SelectField, StampRow, SwitchEnabler, SwitchWrapper, Switcher, TextAreaField, TextNote, TimeCol, TimeSep, TimeText, TimingRow, Title, TypeText, UnitDropButton, UnitDropItemButton, UnitDropdown, WidgetRow, WidgetWrapperBox, Wrapper } from "./style";
import { useContext, useEffect, useState } from "react";
import { UpDownFilledIcon } from "components/Icons/UpDownFilledIcon";
import { ExternalLinkIcon } from "components/Icons/ExternalLinkIcon";
import { Controller, useForm } from "react-hook-form";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { isEmpty } from "lodash";
import EditAvailablity from "./EditAvailablity";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { PlusIcon } from "components/Icons/PlusIcon";
import { AppContext } from "context";
import TimezoneSelect from "react-timezone-select";
import { FormSelectField } from "components/LoginStepOne/style";
import { EventCreatePage } from "./EventCreatePage";
import ComingSoon from "components/ComingSoon";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";



export default function EventSetupTabpanel({ }: any) {
  const naviagte = useNavigate();
  const { userInfo, universalData, member } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [anchorUnitDropEl, setAnchorUnitDropEl] = useState(null);
  const handleUnitDropClick = (event: any) => {
    setAnchorUnitDropEl(event.currentTarget);
  };
  const handleUnitDropClose = () => {
    setAnchorUnitDropEl(null);
  };

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      role: 'member',
      email: ''
    } as any
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    baseApi
      .post("/api/addeventtype", {
        ...data,
        url: `${process.env.REACT_APP_SITE_URL}/${userInfo?.username}/${data?.pageName}`,
        username: userInfo?.username,
        pageName: data?.pageName,
        userId: member.userId,
        duration: data.duration,
        orgId: universalData.org.id,
      })
      .then((res) => {
        toast.success("Event Created - " + res?.data?.savedEvent?.title);
        naviagte(0);
      })
      .catch((err) => toast.error(err?.response?.data?.error))
      .finally(() => setLoading(false));
  };


  return (
    <Wrapper>
      {/* <form action="#"> */}
      <WidgetRow>
        <Heading
          variant="h2"
          className="mb-1"
        >
          Meeting Setup
        </Heading>
        <WidgetWrapperBox>
          {/* <Box sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(31, 31, 31, 0.7)",
            fontSize: "20px",
            lineHeight: 1.2,
            fontWeight: "bold",
            zIndex: 3,
            color: "white"
          }}>
            Coming Soon
          </Box> */}
          <EventCreatePage onSubmit={onSubmit} loading={loading} />
          {/* <Box>
            <FormGroup>
              <LabelHeading
                variant="caption"
              >
                Title
              </LabelHeading>
              <FormField hiddenLabel value="15 Min Meeting" />
            </FormGroup>
            <FormGroup>
              <LabelHeading
                variant="caption"
              >
                Description
              </LabelHeading>
              <TextAreaField name="description" hiddenLabel multiline placeholder="A quick video meeting." />
            </FormGroup>
            <FormGroup>
              <LabelHeading
                variant="caption"
              >
                Url
              </LabelHeading>
              <FieldsInlineWrapper>
                <FieldTagline
                  variant="caption"
                >
                  https://meetings.6ix.com/{userInfo.username}/
                </FieldTagline>
                <FormField hiddenLabel value="15min" />
              </FieldsInlineWrapper>
            </FormGroup>
            <FormGroup>
              <LabelHeading
                variant="caption"
              >
                Duration
              </LabelHeading>
              <FieldUnitGroup>
                <FormField hiddenLabel type="number" value="15" />
                <FieldUnitDropdown>
                  <UnitDropButton type="button" id={"event-type-dropdown-units"} aria-controls={"event-type-dropdown-menu-units"} aria-haspopup="true" aria-expanded={Boolean(anchorUnitDropEl)} onClick={handleUnitDropClick}>
                    <UpDownFilledIcon />
                    Minutes
                  </UnitDropButton>
                  <UnitDropdown id="event-type-dropdown-menu-units" anchorEl={anchorUnitDropEl} open={Boolean(anchorUnitDropEl)} onClose={handleUnitDropClose}>
                    <MenuItem onClick={handleUnitDropClose}>
                      <UnitDropItemButton type="button">
                        Hours
                      </UnitDropItemButton>
                    </MenuItem>
                    <MenuItem onClick={handleUnitDropClose}>
                      <UnitDropItemButton type="button">
                        Minutes
                      </UnitDropItemButton>
                    </MenuItem>
                  </UnitDropdown>
                </FieldUnitDropdown>
              </FieldUnitGroup>
            </FormGroup>
            <FormGroup>
              <SwitchWrapper>
                <Switcher control={<SwitchEnabler defaultChecked />} label="Allow booker to select duration" />
              </SwitchWrapper>
            </FormGroup>
            <FormGroup>
              <LabelHeading
                variant="caption"
              >
                Location
              </LabelHeading>
              <FormField hiddenLabel />
            </FormGroup>
            <FormGroup>
              <LabelHeading
                variant="caption"
              >
                Confirmation
              </LabelHeading>
              <SwitchWrapper>
                <Switcher control={<SwitchEnabler defaultChecked />} label="Request confirmation" />
              </SwitchWrapper>
            </FormGroup>
            <FormGroup>
              <LabelHeading
                variant="caption"
              >
                Timezone
              </LabelHeading>
              <TextNote
                variant="caption"
              >
                Current time: 9:16 PM
              </TextNote>
            </FormGroup>
          </Box> */}
        </WidgetWrapperBox>
      </WidgetRow>
      <EditAvailablity />
      <WidgetRow className="d-none">
        <Heading
          variant="h2"
          className="mb-1"
        >
          Details
        </Heading>
        <WidgetWrapperBox>
          <ComingSoon />
          <FormGroup>
            <LabelHeading
              variant="caption"
            >
              Minimum booking notice
            </LabelHeading>
            <FieldUnitGroup>
              <FormField hiddenLabel type="number" value="15" />
              <FieldUnitDropdown>
                <UnitDropButton type="button" id={"event-type-dropdown-units"} aria-controls={"event-type-dropdown-menu-units"} aria-haspopup="true" aria-expanded={Boolean(anchorUnitDropEl)} onClick={handleUnitDropClick}>
                  <UpDownFilledIcon />
                  Minutes
                </UnitDropButton>
                <UnitDropdown id="event-type-dropdown-menu-units" anchorEl={anchorUnitDropEl} open={Boolean(anchorUnitDropEl)} onClose={handleUnitDropClose}>
                  <MenuItem onClick={handleUnitDropClose}>
                    <UnitDropItemButton type="button">
                      Hours
                    </UnitDropItemButton>
                  </MenuItem>
                  <MenuItem onClick={handleUnitDropClose}>
                    <UnitDropItemButton type="button">
                      Minutes
                    </UnitDropItemButton>
                  </MenuItem>
                </UnitDropdown>
              </FieldUnitDropdown>
            </FieldUnitGroup>
          </FormGroup>
          <FormGroup>
            <LabelHeading
              variant="caption"
            >
              Time-slot intervals
            </LabelHeading>
            <SelectField>
              <Controller
                control={control}
                name="interval"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                  >
                    <MenuItem
                      value="15"
                    >
                      15 mins
                    </MenuItem>
                    <MenuItem
                      value="30"
                    >
                      30 mins
                    </MenuItem>
                  </Select>
                )}
              />
            </SelectField>
          </FormGroup>
          <RangesWrapper>
            <LabelHeading
              variant="caption"
            >
              Opt-in Booking
            </LabelHeading>
            <RangeRow>
              <RadioSwitcherWrapper>
                <RadioGroup
                  defaultValue="days-future"
                  name="opt-in-booking"
                >
                  <FormControlLabel value="female" control={<Radio />} label="" />
                </RadioGroup>
              </RadioSwitcherWrapper>
            </RangeRow>
          </RangesWrapper>
          <CustomDivider />
          <FormGroup>
            <FormSelectField className="timezone">
              <Controller
                control={control}
                defaultValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
                name="timezone"
                render={({ field: { onChange, value } }) => (
                  <TimezoneSelect
                    className="timezone-select-container"
                    classNamePrefix='timezone'
                    value={value}
                    onChange={onChange}
                  />
                )} />
            </FormSelectField>
          </FormGroup>
          {/* <FormGroup>
              <RadiosInlineWrapper>
                <RadioGroup
                  defaultValue="wed"
                  name="opt-in-week-days"
                >
                  <FormControlLabel value="mon" control={<Radio />} label="M" />
                  <FormControlLabel value="tue" control={<Radio />} label="T" />
                  <FormControlLabel value="wed" control={<Radio />} label="W" />
                  <FormControlLabel value="thu" control={<Radio />} label="T" />
                  <FormControlLabel value="fri" control={<Radio />} label="F" />
                  <FormControlLabel value="sat" control={<Radio />} label="S" />
                  <FormControlLabel value="sun" control={<Radio />} label="S" />
                </RadioGroup>
                <ButtonIcon
                  type="button"
                >
                  <DeleteIcon />
                </ButtonIcon>
              </RadiosInlineWrapper>
              <TypeText
                variant="caption"
              >
                9:00 AM until 5:00 PM
              </TypeText>
            </FormGroup> */}
          <ButtonDefault type="button">
            <PlusIcon />
            Add another
          </ButtonDefault>
        </WidgetWrapperBox>
      </WidgetRow>
      {/* </form> */}
    </Wrapper>
  );
}