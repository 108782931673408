import { Description, Heading, Wrapper, HeadingWrapper, StepBar, StepBarsWrapper, StepsWrapper, Title, BoxWrapper, ButtonPrimary, ConntectionsWrpper, ItemRow, IconWrapper, TextWrapper, ItemHeading, ItemDescription, ButtonWrapper, ButtonDefault, StepBackWrapper, ButtonLink, LeftWrapper, RightWrapper } from "./style";
import Icon1 from "../../assets/images/logos_google-calendar.svg"
import Icon2 from "../../assets/images/vscode-icons_file-type-outlook.svg"
import { useAuthState } from "react-firebase-hooks/auth";
import { useContext } from "react";
import { AppContext } from "context";
import baseApi from "api/baseApi";
import { useNavigate } from "react-router-dom";

export default function LoginStepTwo({ setStep }: any) {
  const navigate = useNavigate();
  const { universalData, setSkipStep } = useContext(AppContext)

  const googleCalender = () => {
    const googleLoginUrl = `${process.env.REACT_APP_BASE_URL}/api/auth/google/?userId=${universalData?.userId}&orgId=${universalData?.org.id}`; // Replace with your actual backend URL
    
    window.location.href = googleLoginUrl
  }

  const outlookCalender = () => {
    const outlookLoginURL = `${process.env.REACT_APP_BASE_URL}/api/outlook/auth/openid/?userId=${universalData?.userId}&orgId=${universalData?.org.id}`

    window.location.href = outlookLoginURL
  }


  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading
          variant="h1"
        >
          Connect your calendar
        </Heading>
        <Description
          variant="body1"
        >
          Connect your calendar to automatically check for busy times and new events as they're scheduled.
        </Description>
      </HeadingWrapper>
      <StepsWrapper>
        <Title
          variant="h2"
        >
          Step 2 of 4
        </Title>
        <StepBarsWrapper>
          <StepBar className="active"></StepBar>
          <StepBar className="active"></StepBar>
          <StepBar></StepBar>
          <StepBar></StepBar>
        </StepBarsWrapper>
      </StepsWrapper>
      <BoxWrapper>
        <ConntectionsWrpper>
          <ItemRow>
            <IconWrapper>
              <img src={Icon1} alt="icon1" />
            </IconWrapper>
            <TextWrapper>
              <ItemHeading
                variant="caption"
              >
                Google Calendar
              </ItemHeading>
              <ItemDescription
                variant="body1"
              >
                For personal and business calendars
              </ItemDescription>
            </TextWrapper>
            <ButtonWrapper>
              <ButtonDefault onClick={() => googleCalender()} type="button" sx={{
                "&:hover": {
                  background: "inherit",
                  borderColor: "#373737",
                  color: "#C0C0C0"
                }
              }}>CONNECT</ButtonDefault>
            </ButtonWrapper>
          </ItemRow>
           <ItemRow>
            <IconWrapper>
              <img src={Icon2} alt="icon2" />
            </IconWrapper>
            <TextWrapper>
              <ItemHeading
                variant="caption"
              >
                Office 365 / Outlook.com Calendar
              </ItemHeading>
              <ItemDescription
                variant="body1"
              >
                For personal and business calendars
              </ItemDescription>
            </TextWrapper>
            <ButtonWrapper>
              <ButtonDefault onClick={() => outlookCalender()} type="button" sx={{
                "&:hover": {
                  background: "inherit",
                  borderColor: "#373737",
                  color: "#C0C0C0"
                }
              }}>CONNECT</ButtonDefault>
            </ButtonWrapper>
          </ItemRow>
          {/*<ItemRow>
            <IconWrapper>
              <img src={Icon3} alt="icon3" />
            </IconWrapper>
            <TextWrapper>
              <ItemHeading
                variant="caption"
              >
                CalDav Server
              </ItemHeading>
              <ItemDescription
                variant="body1"
              >
                For personal and business calendars
              </ItemDescription>
            </TextWrapper>
            <ButtonWrapper>
              <ButtonDefault type="button">CONNECT</ButtonDefault>
            </ButtonWrapper>
          </ItemRow>
          <ItemRow>
            <IconWrapper>
              <img src={Icon4} alt="icon4" />
            </IconWrapper>
            <TextWrapper>
              <ItemHeading
                variant="caption"
              >
                Apple Calendar
              </ItemHeading>
              <ItemDescription
                variant="body1"
              >
                For personal and business calendars
              </ItemDescription>
            </TextWrapper>
            <ButtonWrapper>
              <ButtonDefault type="button">CONNECT</ButtonDefault>
            </ButtonWrapper>
          </ItemRow> */}
        </ConntectionsWrpper>
        {/* <ButtonPrimary onClick={() => navigate('/login-step-3')} type="button">CONTINUE</ButtonPrimary> */}
      </BoxWrapper>
      <StepBackWrapper>
        {/* <LeftWrapper>
          <ButtonLink onClick={() => navigate('/')} type="button">
            <ArrowLeftIcon />
            Back
          </ButtonLink>
        </LeftWrapper> */}
        <RightWrapper>
          <ButtonLink onClick={() => {
            setSkipStep(2)
            navigate('/setup/login-step-3')
          }} type="button">
            Skip this step
          </ButtonLink>
        </RightWrapper>
      </StepBackWrapper>
    </Wrapper>
  )
}