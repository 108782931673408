import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArrowLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M12.6673 8H3.33398" stroke="currentColor" strokeWidth="1.336" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M8.00065 12.6654L3.33398 7.9987L8.00065 3.33203" stroke="currentColor" strokeWidth="1.336" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};