import LoginStepOne from "components/LoginStepOne";
import LoginStepTwo from "components/LoginStepTwo";
import { WelcomePageWrapper, AlignWrapper, Align } from "./style";
import LoginStepThree from "components/LoginStepThree";
import LoginStepFour from "components/LoginStepFour";
import { useEffect, useState } from "react";

const stepForm = [LoginStepOne, LoginStepTwo, LoginStepThree, LoginStepFour]

const LoginPageThree = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [])

  if (loading) return null

  return (
    <>
      <div className="sixmeetings-content">
        <div className="sixmeetings-adss">
          <WelcomePageWrapper>
            <AlignWrapper>
              <Align>
                <LoginStepThree />
              </Align>
            </AlignWrapper>
          </WelcomePageWrapper>
        </div>
      </div>
    </>
  );
};
export default LoginPageThree;