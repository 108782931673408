import { styled, Box, Typography, Divider, TextField, Button, TableContainer, FormControl, Tabs, Tab, FormControlLabel, Switch, Menu, DialogContent, Dialog, Link } from '@mui/material';

export const Wrapper = styled('div')`
  .avilability-box {
    background-color: #2b2b2b;
  }
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  line-height: 1.5;
  font-size: 16px;
  color: #e9e9e9;

  @media (min-width: 768px){
    font-size: 20px;
  }
`;

export const TextPara = styled(Typography)`
  font-size: 14px;
  color: #8c8e95;
`;

export const BoxHeading = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  color: #e9e9e9;
`;

export const HeadingWrapper = styled(Box)`
  margin-bottom: 36px;

  @media (min-width: 576px){
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const TitlesWrapper = styled(Box)`
  flex-grow: 1;
`;

export const ScheduleWrapper = styled(Box)`
`;

export const TimingRow = styled(Box)`
  display: flex;

  + div{
    margin-top: 24px;
  }
`;

export const Title = styled(Typography)`
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  display: block;
  flex-grow: 1;
  flex-basis: 0;

  &.unavailable{
    text-decoration: line-through;
    color: #8C8E95;
  }
`;

export const TimeCol = styled(Box)`
  flex-shrink: 0;
  margin-left: auto;
  width: 232px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const StampRow = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TimeSep = styled(Divider)`
  width: 8px;
  border-color: #575757;

  &.hidden{
    opacity: 0;
    visibility: hidden;
  }

  + span{
    text-align: right;
  }
`;

export const TimeText = styled(Typography)`
  display: block;
  min-width: 100px;
  color: #8C8E95;
  font-size: 16px;
  line-height: 20px;
`;

export const ButtonPrimary = styled(Button)`
  min-width: 1px;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 12px 20px;
  width: 100%;
  display: block;
  text-align: center;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
    margin-left: 8px;
    vertical-align: -0.1em;
  }
  
  &:disabled {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const ShooterWidget = styled(Box)`
  background-color: #2b2b2b;
  border: 1px solid #1f1f1f;
  border-radius: 11px;
  padding: 18px;

  @media (min-width: 576px){
    display: flex;
    padding: 24px;
    gap: 24px;
    align-items: center;
  }
`;

export const ShootCol = styled(Box)`

  + div{
    margin-top: 16px;

    @media (min-width: 576px){
      margin-top: 0;
    }
  }

  @media (min-width: 576px){
    width: calc(100% - 262px);

    &:last-child {
      width: 250px;
    }
  }
`;

export const ShootHeading = styled(Typography)`
  color: #E9E9E9;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

export const ShootPara = styled(Typography)`
  color: #C0C0C0;
  font-sizze: 14px;
  line-height: 20px;
  margin-top: 8px;
`;

export const BlockedWrapper = styled(Box)`
  border: 1px solid #373737;
  border-radius: 11px;
  background-color: #2b2b2b;
  padding: 20px;
`;

export const BlockHeading = styled(Typography)`
  color: #EDEDED;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 12px;
`;

export const DullText = styled(Typography)`
  color: #8C8E95;
  font-size: 14px;
  line-height: 20px;
  display: block;
`;

export const CustomSeparator = styled(Divider)`
  border-color: #373737;
  margin-top: 28px;
  margin-bottom: 28px;
`;

export const TextCaption = styled(Typography)`
  color: #E9E9E9;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 24px;
`;

export const SlotsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 24px;
`;

export const BlockSlot = styled(Box)`
  border: 1px solid #373737;
  background-color: #2b2b2b;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 20px;
  padding: 20px;

  .MuiSvgIcon-root{
    width: 20px;
    height: 20px;
  }
`;

export const DatePickerWrapper = styled(Box)`
  width: 100%;
  position: relative;
  
  &,
  .MuiStack-root,
  .MuiStack-root > div,
  .react-datepicker,
  .react-datepicker__month-container,
  .react-datepicker__time-container{
    height: 100%;
  }

  .MuiStack-root{
    padding-top: 0;
  }

  .react-datepicker{
    background-color: #1F1F1F;
    border-radius: 0;
    border: 0;
    display: flex;
    font-family: inherit;
  }

  .react-datepicker__month-container{
    width: 100%;
    padding: 36px;
  }

  .react-datepicker__time-container{
    width: 40%;
    flex-shrink: 0;
    border-left-color: #373737;

    .react-datepicker__header--time{
      display: none;
    }

    .react-datepicker__time,
    .react-datepicker__time-box{
      height: 100%;
    }

    .react-datepicker__time-list{
      height: 400px !important;
    }

    .react-datepicker__time-box{
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .react-datepicker__header,
  .react-datepicker__time{
    background-color: transparent;
    color: #8C8E95;
  }

  .react-datepicker__header{
    border: 0;
    padding: 0;
  }

  .react-datepicker__current-month{
    text-align: left;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    line-height: 1.2;
    margin-bottom: 12px;
  }

  .react-datepicker__day-names{
    display: flex;
    margin-bottom: 4px;
  }

  .react-datepicker__day-name{
    color: #8C8E95;
    font-size: 13px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0;
    padding: 8px;
    width: auto;
  }

  .react-datepicker__month{
    margin: 0;
  }

  .react-datepicker__week{
    display: flex;
    gap: 8px;

    + .react-datepicker__week{
      margin-top: 8px;
    }
  }

  .react-datepicker__day{
    background-color: #131313;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1;
    color: #fff;
    flex-grow: 1;
    flex-basis: 0;
    height: 44px;
    margin: 0;

    &.react-datepicker__day--outside-month,
    &.react-datepicker__day--disabled{
      background-color: #2B2B2B;
      color: #575757;
    }

    &.react-datepicker__day--disabled {
      background-color: #2B2B2B;
      color: #575757;
    }

    &.react-datepicker__day--selected,
    &.react-datepicker__day--today.react-datepicker__day--selected{
      position: relative;
      color: #E3C050;
    }

    &.react-datepicker__day--selected:before,
    &.react-datepicker__day--today.react-datepicker__day--selected:before{
      content: '';
      border-radius: 100%;
      width: 4px;
      height: 4px;
      position: absolute;
      left: 50%;
      bottom: 4px;
      transform: translateX(-50%);
      background-image: linear-gradient(45deg, rgba(227,192,80,1) 0%, rgba(227,192,80,1) 100%);
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .react-datepicker__navigation{
    top: 30px;
    right: calc(40% + 27px);
    left: auto;
  }

  .react-datepicker__navigation--previous{
    transform: translateX(-80px);
  }

  .react-datepicker__navigation-icon{

    &:before{
      width: 12px;
      height: 12px;
      border-width: 2px 2px 0 0;
      border-color: #E9E9E9;
      top: 6px;
      right: 0;
    }
  }

  .react-datepicker__time-list-item{
    font-size: 16px;
    line-height: 1.2;
    padding: 8px 4px !important;
    height: auto !important;
    border: 1px solid transparent;
    border-radius: 8px !important;
    margin-top: 8px;
    margin-bottom: 8px;

    &.react-datepicker__time-list-item--selected,
    &:hover{
      border-color: #E3C050 !important;
      color: #fff !important;
      background-color: #000 !important;
    }
  }
`;