import { BoxWrapper, ButtonPrimary, StepBackWrapper, LeftWrapper, ButtonLink, RightWrapper, SwitchEnabler, SwitchWrapper, AdditionalRow, Switcher, FormGroup, FormSelectField, FieldsWrapper, AdditionalBtns, ButtonIcon, FormAdditionalRow, SepSign, RepeaterWrap, ErrorText } from "./style";
import { Controller, useForm } from "react-hook-form";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { PlusIcon } from "components/Icons/PlusIcon";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { isEmpty } from "lodash";

const sample = {
  "userId": "sTdEvBljUbZ7oBQ9jnLJ7Uw0at84",
  "availability": {
    "sunday": [],
    "monday": [{ startTime: '9:00 am', endTime: '5:00 pm' }],
    "tuesday": [{ startTime: '9:00 am', endTime: '5:00 pm' }],
    "wednesday": [{ startTime: '9:00 am', endTime: '5:00 pm' }],
    "thursday": [{ startTime: '9:00 am', endTime: '5:00 pm' }],
    "friday": [{ startTime: '9:00 am', endTime: '5:00 pm' }],
    "saturday": [],
  }
}

export default function AvailabilityForm({ data, onSubmit, loading, children }: any) {
  const [user] = useAuthState(auth)
  const { control, watch, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: { ...(data?.availability || sample?.availability) } as any,
    mode: 'onBlur'
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BoxWrapper>
          {Object.entries(watch()).map((day: any) =>
            <FormGroup>
              <FormAdditionalRow>
                <SwitchWrapper>
                  <Switcher control={<SwitchEnabler onChange={(e) => {
                    if (e.target.checked) {
                      setValue(day[0], [{ startTime: '9:00 am', endTime: '5:00 pm' }])
                    } else {
                      setValue(day[0], [])
                    }
                  }} defaultChecked={!isEmpty(day[1][0])} />} label={day[0][0].toUpperCase() + day[0].slice(1)} />
                </SwitchWrapper>
                <AdditionalRow>
                  {!isEmpty(day[1][0]) && day[1]?.map((time: any, index: number) =>
                    <RepeaterWrap>
                      <FieldsWrapper>
                        <FormSelectField>
                          <Controller
                            control={control}
                            rules={{ required: { value: true, message: "required" } }}
                            name={`${day[0]}.${index}.startTime`}
                            render={({ field: { onChange, value } }) => (
                              <TimePicker
                                maxTime={dayjs(time.endTime, 'hh:mm a')}
                                value={dayjs(value, 'hh:mm a')}
                                onChange={onChange}
                              />
                            )} />
                          {errors[day[0]]?.[index]?.startTime &&
                            <ErrorText variant="caption">{errors[day[0]]?.[index]?.startTime.message}</ErrorText>
                          }
                        </FormSelectField>
                        <SepSign />
                        <FormSelectField>
                          <Controller
                            control={control}
                            rules={{ required: { value: true, message: "required" } }}
                            name={`${day[0]}.${index}.endTime`}
                            render={({ field: { onChange, value } }) => (
                              <TimePicker
                                minTime={dayjs(time.startTime, 'hh:mm a')}
                                value={dayjs(value, 'hh:mm a')}
                                onChange={onChange}
                              />
                            )} />
                          {errors[day[0]]?.[index]?.endTime &&
                            <ErrorText variant="caption">{errors[day[0]]?.[index]?.endTime.message}</ErrorText>
                          }
                        </FormSelectField>
                      </FieldsWrapper>
                      <AdditionalBtns>
                        <ButtonIcon disabled={index === 0}
                          onClick={() => setValue(day[0], watch(day[0]).filter((v: any, i: any) => i !== index))}
                          type="button">
                          <DeleteIcon />
                        </ButtonIcon>
                        <ButtonIcon
                          onClick={() => {
                            const val = watch(day[0]);
                            val.splice(index + 1, 0, { startTime: '', endTime: '' })
                            setValue(day[0], val)
                          }}
                          type="button">
                          <PlusIcon />
                        </ButtonIcon>
                      </AdditionalBtns>
                    </RepeaterWrap>
                  )}

                </AdditionalRow>
              </FormAdditionalRow>
            </FormGroup>
          )}
          {children}
          <ButtonPrimary disabled={loading} type="submit">CONTINUE</ButtonPrimary>
        </BoxWrapper>
      </form>
    </LocalizationProvider>
  )
}