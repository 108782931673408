import { styled, Box } from '@mui/material';

export const WelcomePageWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #131313;
`;

export const AlignWrapper = styled(Box)`
  display: flex;
  width: 100%;
  min-height: 100%;
`;

export const Align = styled(Box)`
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 6vw;

    @media (max-width: 767px){
      font-size: 30px;
    }
  }

  .icon {
    font-size: 9vw;
    display: block;
    margin: 0 auto;

    @media (max-width: 767px){
      font-size: 30px;
    }
  }

`;