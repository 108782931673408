import baseApi from "api/baseApi"
import { auth } from "../../firebase"
import { useContext, useEffect } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { AppContext } from "context"
import { toast } from "react-toastify"

export const LoginStepHandler = () => {
	const navigate = useNavigate()
	const { universalData, updateUsername, skipStep } = useContext(AppContext)
	const { pathname } = useLocation();

	useEffect(() => {
		if (universalData?.userId) {
			baseApi.get(`/api/currentstage/user/${universalData?.userId}/?orgId=${universalData?.org.id}`)
				.then((res) => {
					const step = res.data;
					updateUsername(step)
					
					if(pathname.includes('/types')) return 
					
					if (step.onboarded && step.onboarded !== '*' && step.username) return navigate('/types')
					else if (step.onboarded === '*') return navigate('/setup')
					else if (!step.onboarded && step.calendar && step.username && step.laststepcompleted === '2') return navigate('/setup/login-step-4')
					else if (!step.onboarded && step.calendar && step.username && step.laststepcompleted === '1') return navigate('/setup/login-step-3')
					else if (!step.onboarded && !step.calendar && step.username && step.laststepcompleted === '0' && skipStep === 2) return navigate('/setup/login-step-3')
					else if (!step.onboarded && !step.calendar && step.username && step.laststepcompleted === '0') return navigate('/setup/login-step-2')
					else if (!step.onboarded && !step.calendar && step.laststepcompleted === '*') return navigate('/setup')
				})
				.catch((err) => toast.error(err?.response?.data?.error))
		}
	}, [universalData?.userId, universalData?.org.id, pathname])




	return (
		<Outlet />
	)
}