import { BlockHeading, BlockSlot, BlockedWrapper, ButtonPrimary, CustomSeparator, DatePickerWrapper, DullText, Heading, HeadingWrapper, ScheduleWrapper, ShootCol, ShootHeading, ShootPara, ShooterWidget, SlotsWrapper, StampRow, TextCaption, TextPara, TimeCol, TimeSep, TimeText, TimingRow, Title, TitlesWrapper, Wrapper } from "./style";
import { InfoIcon } from "components/Icons/InfoIcon";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

export default function TroubleShooter({ }: any) {
  const [startDate, setStartDate] = useState<any>();
  
  return (
    <Wrapper>
      <HeadingWrapper>
        <TitlesWrapper>
          <Heading
            variant="h1"
          >
            Availability
          </Heading>
          <TextPara
            variant="body1"
          >
            Understand why certain times are available and others are blocked.
          </TextPara>
        </TitlesWrapper>
      </HeadingWrapper>
      <BlockedWrapper>
        <BlockHeading
          variant="h3"
        >
          Here is an overview of your day on 
        </BlockHeading>
        <DatePickerWrapper>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              {/* <DateTimePicker
              open={true}
              orientation="landscape"
              className="static-datepicker"
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
            /> */}
              <DatePicker
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </DatePickerWrapper>
        <DullText
          variant="caption"
        >
          Tip: Hover over the bold times for a full timestamp
        </DullText>
        <CustomSeparator />
        <TextCaption
          variant="caption"
        >
          Your day starts at 00:00
        </TextCaption>
        <SlotsWrapper>
          <BlockSlot>
            <InfoIcon />
            calendar_no_busy_slots
          </BlockSlot>
        </SlotsWrapper>
        <TextCaption
          variant="caption"
        >
          Your day starts at 00:00
        </TextCaption>
      </BlockedWrapper>
    </Wrapper>
  );
}