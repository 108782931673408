import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const UpDownFilledIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.9998 10.3574H3.85693L8.42835 14.9288L12.9998 10.3574Z" fill="currentColor"/>
      <path d="M12.9998 7.78711H3.85693L8.42835 3.21569L12.9998 7.78711Z" fill="currentColor"/>
    </SvgIcon>
  );
};