import { styled, Box } from '@mui/material';

export const PageWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  /* background-color: #131313; */
`;

export const ContainerWrapper = styled(Box)`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  
  @media (min-width: 992px){
    padding-left: 35px;
    padding-right: 35px;
  }

  &:after{
    content: '';
    display: block;
    clear: both;
  }
`;