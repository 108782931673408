import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CalendarEmptyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="80"
      height="81"
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M63.3333 14.1896H16.6667C12.9848 14.1896 10 17.1743 10 20.8562V67.5229C10 71.2048 12.9848 74.1896 16.6667 74.1896H63.3333C67.0152 74.1896 70 71.2048 70 67.5229V20.8562C70 17.1743 67.0152 14.1896 63.3333 14.1896Z" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M53.3281 7.53381V20.8671" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M26.6719 7.53381V20.8671" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M10 34.1896H70" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};