import React, { useContext } from "react";
import { ContentArea, ContentColumns, ContentSidebar, SidebarHeading, TabButton, TabsetList, Wrapper } from "./style";
import MemberTabpanel from "components/MemberTabpanel";
import GeneralTabpanel from "components/GeneralTabpanel";
import { AppContext } from "context";
import LogoMarkCircle from "../../assets/images/6ix_mask_circle.png"
import EventTypesTabpanel from "components/EventTypesTabpanel";
import EventSetupTabpanel from "components/EventSetupTabpanel";
import EventBookingsTabpanel from "components/EventBookingsTabpanel";
import AvailabilityTabpanel from "components/AvailabilityTabpanel";
import EventIntegrationsTabpanel from "components/EventIntegrationsTabpanel";
import EventProfileTabpanel from "components/EventProfileTabpanel";
import EventBillingTabpanel from "components/EventBillingTabpanel";
import EventMeetingsTabpanel from "components/EventMeetingsTabpanel";

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        id={`vertical-tabpanel-${index}`}
        {...other}
      >
        {value === 0 && (
          <EventTypesTabpanel />
        )}
        {value === 1 && (
          <EventSetupTabpanel />
        )}
        {/* {value === 2 && (
          <EventMeetingsTabpanel />
        )} */}
        {value === 3 && (
          <AvailabilityTabpanel />
        )}
      </div>
    </>
  );
}

export default function EventMeetingsType({ pTop, pBottom }: any) {
  const [value, setValue] = React.useState(0);
  const { org } = useContext(AppContext);

  const handleTabsChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  return (
    <Wrapper>
      <ContentColumns>
        <ContentSidebar>
          <SidebarHeading
            variant="caption"
          >
            <i>
              <img src={LogoMarkCircle} width="24" height="24" alt="6ix" />
            </i>
            6ix Inc
          </SidebarHeading>
          <TabsetList
            orientation="vertical"
            value={value}
            onChange={handleTabsChange}
          >
            <TabButton label="Event Types" />
            <TabButton label="Bookings" />
            <TabButton label="Meetings" />
            <TabButton label="Availability" />
          </TabsetList>
        </ContentSidebar>
        <ContentArea>
          <TabPanel value={value} index={0}>
            Item One
          </TabPanel>
        </ContentArea>
      </ContentColumns>
    </Wrapper>
  );
}