import { useEffect, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { DescriptionWrapper, ItemHeading, ListingsWrapper, Subtitle } from "components/EventTypesTabpanel/style";
import { ListItem } from "@mui/material";
import { Align, AlignWrapper, Subheading, UserHeading } from "./style";
import { TimeClockIcon } from "components/Icons/TimeClockIcon";
import { Wrapper } from "components/IntroSection/style";
import { eventURl } from "utils/helper";
import baseApiAvoidPreflight from "api/baseApiAvoidPreflight";
import { toast } from "react-toastify";


export default function RescheduleEventList({ }: any) {
  const navigate = useNavigate();
  const { username } = useParams();
  const [events, setEvents] = useState<any>()

  useEffect(() => {
    baseApiAvoidPreflight.get("/api/" + username).then((res) => {
      setEvents(res.data.events)
    }).catch((err) => toast.error(err?.response?.data?.error))
  }, [username])

  
  return (
    <Wrapper>
      <AlignWrapper>
        <Align>
          <UserHeading
            variant="h1"
          >
            {username}
          </UserHeading>
          <ListingsWrapper width={200}>
            {events?.filter((event: any) => event.status === "Active")?.map((event: any) =>
              <ListItem onClick={() => navigate(eventURl(event))} key={event._id}>
                <DescriptionWrapper>
                  <ItemHeading
                    variant="h2"
                  >
                    {event.title}
                    <Subtitle
                      variant="caption"
                    >
                      {event.description}
                    </Subtitle>
                  </ItemHeading>
                  <Subheading
                    variant="caption"
                  >
                    <TimeClockIcon />
                    {event.duration + " min"}
                  </Subheading>
                </DescriptionWrapper>
              </ListItem>
            )}
          </ListingsWrapper>
        </Align>
      </AlignWrapper>
    </Wrapper>
  )
}