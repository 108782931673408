import { styled, Box } from '@mui/material';

export const Section = styled(Box)`
  width: 100%;
  position: relative;
  z-index: 1;
  border: 1px solid #373737;
  background: #1f1f1f;
  border-radius: 8px;
  padding: 20px;
  text-align: center;

  h4 {
    font-weight: bold;
  }
`;

export const IconHolder = styled(Box)`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px;

  svg {
    font-size: 4rem;
    line-height: 1.2;
  }
`;