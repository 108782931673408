import {
  ActionsWrapper,
  AddNewDecription,
  AddNewHeading,
  AddNewModal,
  ButtonDefault,
  ButtonIcon,
  ButtonModalClose,
  ButtonPrimary,
  ButtonWrapper,
  DeleteModal,
  DescriptionWrapper,
  DropItemButton,
  FieldTagline,
  FieldUnitDropdown,
  FieldUnitGroup,
  FieldsInlineWrapper,
  FormField,
  FormGroup,
  Heading,
  HeadingWrapper,
  IconWrapper,
  ItemHeading,
  ItemMenu,
  LabelHeading,
  ListItem,
  ListingsWrapper,
  ModalButtons,
  ModalContent,
  ModalContentWrapper,
  ModalDescription,
  ModalHeader,
  ModalHeading,
  Subheading,
  Subtitle,
  SwitchEnabler,
  SwitchWrapper,
  Switcher,
  SwitcherTag,
  TextAreaField,
  TextPara,
  TitlesWrapper,
  UnitDropdown,
  Wrapper,
  UnitDropButton,
  UnitDropItemButton,
  ErrorText,
  Dropdownmenu,
} from "./style";
import React, { useContext, useEffect, useState } from "react";
import { ArrowRightIcon } from "components/Icons/ArrowRightIcon";
import { TimeClockIcon } from "components/Icons/TimeClockIcon";
import { LinkIcon } from "components/Icons/LinkIcon";
import { EyeViewIcon } from "components/Icons/EyeViewIcon";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  MenuItem,
  MenuList,
  TextField,
  duration,
} from "@mui/material";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { CrossIcon } from "components/Icons/CrossIcon";
import { UpDownFilledIcon } from "components/Icons/UpDownFilledIcon";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Controller, useForm } from "react-hook-form";
import { ChipDeleteIcon } from "components/Icons/ChipDeleteIcon";
import { toast } from "react-toastify";
import { DropDown } from "./DropDown";
import { useNavigate } from "react-router-dom";
import { AppContext } from "context";
import { EventCreatePage } from "components/EventSetupTabpanel/EventCreatePage";

export const CreateEvent = ({
  onSubmit,
  openAddNewModal,
  handleAddNewModalClose,
  loading,
  data,
}: any) => {
  const [booker, setBooker] = useState(false);
  const { userInfo } = useContext(AppContext);

  const [anchorUnitDropEl, setAnchorUnitDropEl] = useState(null);
  const handleUnitDropClick = (event: any) => {
    setAnchorUnitDropEl(event.currentTarget);
  };
  const handleUnitDropClose = () => {
    setAnchorUnitDropEl(null);
  };

  // useEffect(() => {
  // 	if (userInfo) {
  // 		reset({
  // 			username: userInfo?.username
  // 		})
  // 	}
  // }, [userInfo])

  return (
    <AddNewModal open={openAddNewModal} onClose={handleAddNewModalClose}>
      <ModalContent>
        <EventCreatePage onSubmit={onSubmit} data={data} loading={loading} />
      </ModalContent>
    </AddNewModal>
  );
};
