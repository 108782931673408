import React from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Flex from "components/FlexWrapper";
import PageFooterContentAttract from "./PageFooterContentAttract";
import PageFooterContentExpertise from "./PageFooterContentExpertise";
import PageFooterContentInvest from "./PageFooterContentInvest";
import PageFooterMenuCMS from "./PageFooterMenuCMS";


const PageFooterContentLeft = () => {
  return (
    <Flex className="meeting6ix-layout-footer-content-left">
      <PageFooterContentInvest />
      <PageFooterContentAttract />
      <PageFooterContentExpertise />
      {!isMobile ? <PageFooterMenuCMS /> : null}
    </Flex>
  );
};

export default PageFooterContentLeft;
