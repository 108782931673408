import EventFiltersHeadBar from "components/EventFiltersHeadBar";
import {
  ButtonCheckbox,
  ButtonDefault,
  CellHeading,
  ContentArea,
  DataTableWrapper,
  DescrText,
  HeadingWrapper,
  LinkText,
  TextDull,
  TitleHeading,
  TitlesWrapper,
  Wrapper,
} from "./style";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { CheckIcon } from "components/Icons/CheckIcon";
import { FilterBarsIcon } from "components/Icons/FilterBarsIcon";
import { ExternalLinkIcon } from "components/Icons/ExternalLinkIcon";
import baseApi from "api/baseApi";
import { AppContext } from "context";
import { ArrowLeftIcon } from "components/Icons/ArrowLeftIcon";
import { size } from "lodash";
import {
  EmptyDescription,
  EmptyHeading,
  EmptyWrapper,
  IcnWrapper,
} from "components/PastTabpanel/style";
import { CalendarEmptyIcon } from "components/Icons/CalendarEmptyIcon";

export default function EventMeetingsDetail({ meetingDetail, onReset }: any) {
  const [meetings, setMeetings] = useState<any>();
  const [sortMeetings, setSortMeetings] = useState<any>();
  const [filter, setFilter] = useState<any>();

  const applyFilter = (name: string) => {
    if (filter === name) {
      setFilter(undefined);
    } else {
      setFilter(name);
    }
  };

  const sortingFilter = useMemo(
    () =>
      filter
        ? sortMeetings.slice(0).sort(function (a: any, b: any) {
            let textA = a[filter].toString().toUpperCase();
            let textB = b[filter].toString().toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
        : sortMeetings,
    [sortMeetings, filter]
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: formSetValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      durationType: "min",
    } as any,
  });

  const { universalData, member } = useContext(AppContext);
  useEffect(() => {
    baseApi
      .get(
        `/api/eventstats/${meetingDetail.orgId}/${meetingDetail.userId}/${meetingDetail.eventId}`
      )
      .then((res: any) => {
        setMeetings(res.data);
        setSortMeetings(res.data);
      });
  }, [meetingDetail]);

  return (
    <Wrapper>
      <HeadingWrapper>
        <TitlesWrapper
          sx={{
            color: "#EDEDED",
            fontSize: "20px",
            lineHeight: "28px",
            fontWeight: "700",
          }}
        >
          <ButtonDefault
            type="button"
            onClick={onReset}
            sx={{
              marginRight: "15px",
              background: "transparent",
              color: "#C0C0C0",
              fontSize: "14px",
              lineHeight: "20px",
              padding: "10px 16px",
            }}
          >
            <ArrowLeftIcon
              sx={{
                fontSize: "18px",
                marginRight: "8px",
              }}
            />
            Back
          </ButtonDefault>{" "}
          {meetingDetail.eventName}
        </TitlesWrapper>
      </HeadingWrapper>
      <ContentArea>
        <DataTableWrapper>
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                {/* <TableCell>
                  <ButtonCheckbox type="button">
                    <CheckIcon />
                  </ButtonCheckbox>
                </TableCell> */}
                <TableCell>
                  <CellHeading variant="caption">
                    Meeting Attendees
                    <FilterBarsIcon />
                  </CellHeading>
                </TableCell>
                <TableCell>
                  <CellHeading
                    variant="caption"
                    className={(filter === "creator" && "active") || ""}
                  >
                    Meeting Booked
                    <FilterBarsIcon onClick={() => applyFilter("creator")} />
                  </CellHeading>
                </TableCell>
                <TableCell>
                  <CellHeading
                    variant="caption"
                    className={(filter === "status" && "active") || ""}
                  >
                    Meeting Completed
                    <FilterBarsIcon onClick={() => applyFilter("status")} />
                  </CellHeading>
                </TableCell>
                <TableCell>
                  <CellHeading
                    variant="caption"
                    className={(filter === "interval" && "active") || ""}
                  >
                    Meeting Length
                    <FilterBarsIcon onClick={() => applyFilter("interval")} />
                  </CellHeading>
                </TableCell>
                <TableCell>
                  <CellHeading variant="caption">
                    Meeting Feedback
                    <FilterBarsIcon />
                  </CellHeading>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortMeetings?.length > 0 ? (
                <>
                  {sortMeetings?.map((meeting: any) => (
                    <TableRow>
                      {/* <TableCell>
                        <ButtonCheckbox type="button">
                          <CheckIcon />
                        </ButtonCheckbox>
                      </TableCell> */}
                      <TableCell>
                        <DescrText variant="caption" sx={{ color: "#E9E9E9" }}>
                          {meeting?.attendees?.map(
                            (attendee: any, index: any) => (
                              <>
                                {attendee.email || "N/A"}
                                {size(meeting?.attendees) > 0 &&
                                index !== size(meeting?.attendees) - 1
                                  ? ", "
                                  : ""}
                              </>
                            )
                          )}
                        </DescrText>
                      </TableCell>
                      <TableCell>
                        <TitleHeading variant="caption">
                          {meeting?.creator || "N/A"}
                        </TitleHeading>
                      </TableCell>
                      <TableCell>
                        <TitleHeading variant="caption">
                          {meeting?.status || "N/A"}
                        </TitleHeading>
                      </TableCell>
                      <TableCell>
                        <TitleHeading variant="caption">
                          {meeting?.interval || "0"} min
                        </TitleHeading>
                      </TableCell>
                      <TableCell>
                        <TitleHeading variant="caption">
                          {meeting?.description || "N/A"}
                        </TitleHeading>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <td colSpan={7}>
                <EmptyWrapper>
                  <IcnWrapper>
                    <CalendarEmptyIcon />
                  </IcnWrapper>
                  <EmptyHeading variant="h2">
                    No Attendees Found
                  </EmptyHeading>
                  {/* <EmptyDescription variant="body1">
                    You have no Upcoming bookings. Your upcoming bookings will
                    show up here.
                  </EmptyDescription> */}
                </EmptyWrapper>
                </td>
              )}
            </TableBody>
          </Table>
        </DataTableWrapper>
      </ContentArea>
    </Wrapper>
  );
}
