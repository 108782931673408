import { Typography } from "@mui/material";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Section, IconHolder } from "./style";

const InvalidMeetingPage = () => {

  return (
    <Section>
      <IconHolder><LinkOffIcon /></IconHolder> 
      <Typography variant="h4">Sorry, this meeting is no longer available</Typography>
    </Section>
  );
};
export default InvalidMeetingPage;
