import { Box } from "@mui/material";
import { ExternalLinkIcon } from "components/Icons/ExternalLinkIcon";
import { time } from "console";
import { isEmpty } from "lodash";
import { BlockHeading, BlockSlot, BlockedWrapper, ButtonPrimary, CustomSeparator, DatePickerWrapper, DullText, Heading, HeadingWrapper, ScheduleWrapper, ShootCol, ShootHeading, ShootPara, ShooterWidget, SlotsWrapper, StampRow, TextCaption, TextPara, TimeCol, TimeSep, TimeText, TimingRow, Title, TitlesWrapper, Wrapper } from "./style";
import { InfoIcon } from "components/Icons/InfoIcon";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import EditAvailablity from "components/EventSetupTabpanel/EditAvailablity";
import TroubleShooter from "./TroubleShooter";

export default function AvailabilityTabpanel({ }: any) {
  const [startDate, setStartDate] = useState<any>();
  const [showTroubleShooter, setShowTroubleShooter] = useState<any>(false);
  
  return (
    <>
      {showTroubleShooter ? <TroubleShooter /> : 
      
      <Wrapper>
        <HeadingWrapper>
          <TitlesWrapper>
            <Heading
              variant="h1"
            >
              Availability
            </Heading>
            <TextPara
              variant="body1"
            >
              Configure times when you are available for bookings.
            </TextPara>
          </TitlesWrapper>
        </HeadingWrapper>
        <EditAvailablity hideTitle />
        <ShooterWidget className="d-none">
          <ShootCol>
            <ShootHeading
              variant="h3"
            >
              Something doesn't look right?
            </ShootHeading>
            <ShootPara
              variant="body1"
            >
              Troubleshoot your availability to explore why your times are showing as they are.
            </ShootPara>
          </ShootCol>
          <ShootCol>
            <ButtonPrimary type="button" disabled={true} onClick={() => setShowTroubleShooter(true)}>
              Launch troubleshooter
            </ButtonPrimary>
          </ShootCol>
        </ShooterWidget>
      </Wrapper>
      }
    </>
  );
}