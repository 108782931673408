import {
  ButtonPrimary,
  ButtonTexture,
  ButtonWrapper,
  ContentArea,
  DecrWrap,
  EntActWrap,
  EntHeading,
  EntrWrapper,
  EntryRow,
  Heading,
  HeadingWrapper,
  IcnWrapper,
  TabButton,
  TabsetList,
  TextPara,
  TextureWrap,
  TitlesWrapper,
  Wrapper,
} from "./style";
import React, { useContext, useEffect, useState } from "react";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { useForm } from "react-hook-form";
import Icon1 from "../../assets/images/logos_google-calendar.svg";
import Icon2 from "../../assets/images/vscode-icons_file-type-outlook.svg";
import Icon3 from "../../assets/images/calendar.svg";
import Icon4 from "../../assets/images/calendar_10.svg";
import Icon5 from "../../assets/images/crank-shafts-merged.png";
import Icon6 from "../../assets/images/code.png";
import { CopyIcon } from "components/Icons/CopyIcon";
import { AppContext } from "context";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ComingSoon from "components/ComingSoon";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { EyeViewIcon } from "components/Icons/EyeViewIcon";

export default function EventIntegrationsTabpanel({}: any) {
  const [user] = useAuthState(auth);
  const [eventTypes, setEvenTypes] = useState<any>([]);
  const { universalData, userInfo, member } = useContext(AppContext);
  const [calendarInformation, setCalendarInformation] = useState<any>(null);
  

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: formSetValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      durationType: "min",
    } as any,
  });

  const [value, setValue] = React.useState(0);
  const handleTabsChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorUnitDropEl, setAnchorUnitDropEl] = useState(null);
  const handleUnitDropClick = (event: any) => {
    setAnchorUnitDropEl(event.currentTarget);
  };
  const handleUnitDropClose = () => {
    setAnchorUnitDropEl(null);
  };

  const [openModal, setOpen] = React.useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => {
    setOpen(false);
  };

  const [openAddNewModal, setAddNewOpen] = React.useState(false);
  const handleAddNewModalOpen = () => setAddNewOpen(true);
  const handleAddNewModalClose = () => {
    reset();
    setAddNewOpen(false);
  };

  const googleCalender = () => {
    const googleLoginUrl = `${process.env.REACT_APP_BASE_URL}/api/auth/google/?userId=${universalData?.userId}&orgId=${universalData?.org.id}`; // Replace with your actual backend URL

    window.location.href = googleLoginUrl;
  };

  const outlookCalender = () => {
    const outlookLoginURL = `${process.env.REACT_APP_BASE_URL}/api/outlook/auth/openid/?userId=${universalData?.userId}&orgId=${universalData?.org.id}`;

    window.location.href = outlookLoginURL;
  };

  useEffect(() => {
		if (member?.userId) {
			baseApi.get(`/api/currentstage/user/${member?.userId}/?orgId=${universalData?.org.id}`)
				.then((res) => {
					const step = res.data;
          setCalendarInformation(step)
				})
				.catch((err) => toast.error(err?.response?.data?.error))
		}
	}, [universalData, member, member?.userId])

  return (
    <Wrapper>
      <HeadingWrapper>
        <TitlesWrapper>
          <Heading variant="h1">Integrations</Heading>
          <TextPara variant="body1">Connect your favourite apps.</TextPara>
        </TitlesWrapper>
      </HeadingWrapper>
      <ContentArea>
        <EntryRow>
          <Heading variant="h2">Calendars</Heading>
          <TextPara variant="body1">
            Configure how your event types should interact with your calendars.
          </TextPara>
          {calendarInformation?.onboarded === "123" && (
            member?.userType === "New User" && member?.userId === universalData?.userId ?
            <>
              <Box
                sx={{
                  color: "#fff",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundColor: "#2b2b2b",
                  border: "2px solid rgb(43, 43, 43)",
                  borderRadius: "7px",
                  padding: "20px",
                  zIindex: "8",
                  marginTop: "20px",

                  ".info-text" : {
                    fontSize: "16px",
                    lineHeight: "22px",
                    color: "#fff",
                    margin: "0 0 20px",
                    textAlign: "center"
                  }
                }}
              >
                <TextPara variant="body1" className="info-text">You are not onboarded on meetings.6ix.com please onboard by clicking the button below.</TextPara>
                <ButtonPrimary
                  type="button"
                  onClick={() => window.location.href="/setup"}
                >
                  Onboard Now
                </ButtonPrimary>
              </Box>
            </>
            :
            <Box
              sx={{
                color: "#fff",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: "#2b2b2b",
                border: "2px solid rgb(43, 43, 43)",
                borderRadius: "7px",
                padding: "20px",
                zIindex: "8",
                marginTop: "20px",

                ".info-text" : {
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#fff",
                  margin: "0 0 20px",
                  textAlign: "center"
                }
              }}
            >
              <TextPara variant="body1" className="info-text">Please invite your org members to onboard to meetings.6ix.com</TextPara>
              <ButtonPrimary
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(`${process.env.REACT_APP_SITE_URL}/setup` || "https://meetings.6ix.com/setup");
                  toast.success("Copied to Clipboard");
                }}
              >
                Copy to Clipboard <EyeViewIcon />
              </ButtonPrimary>
            </Box>
          )}
          {(calendarInformation?.calendarType === "google" || calendarInformation?.calendar === false) && (
            <EntrWrapper>
              <DecrWrap>
                <IcnWrapper>
                  <img src={Icon1} width={"44"} height={"44"} alt="" />
                </IcnWrapper>
                <TextureWrap>
                  <EntHeading variant="h3">Google Calendar</EntHeading>
                  <TextPara variant="body1">
                    For personal and business calendars
                  </TextPara>
                </TextureWrap>
                <EntActWrap>
                  {calendarInformation?.calendarType === "google" && (
                    <ButtonPrimary
                      className="small"
                      type="button"
                      onClick={() => googleCalender()}
                      disabled={member?.userId !== universalData?.userId}
                    >
                      Reconnect
                    </ButtonPrimary>
                  )}
                  {userInfo?.calendarType === "None" ? (
                    <ButtonPrimary onClick={() => googleCalender()} className="small default" type="button" disabled={member?.userId !== universalData?.userId}>
                      Connect
                    </ButtonPrimary>
                  ) : (
                    <ButtonPrimary className="small default" type="button" disabled={member?.userId !== universalData?.userId}>
                      <DoneAllIcon sx={{ mr: 1 }} /> Connected
                    </ButtonPrimary>
                  )}
                </EntActWrap>
              </DecrWrap>
            </EntrWrapper>
          )}
          {(calendarInformation?.calendarType === "outlook" || calendarInformation?.calendar === false) && (
            <EntrWrapper>
              <DecrWrap>
                <IcnWrapper>
                  <img src={Icon2} width={"44"} height={"44"} alt="" />
                </IcnWrapper>
                <TextureWrap>
                  <EntHeading variant="h3">
                    Office 365 / Outlook.com Calendar
                  </EntHeading>
                  <TextPara variant="body1">
                    For personal and business calendars
                  </TextPara>
                </TextureWrap>
                <EntActWrap>
                  {calendarInformation?.calendarType === "outlook" && (
                    <ButtonPrimary
                      className="small"
                      type="button"
                      onClick={() => outlookCalender()}
                      disabled={member?.userId !== universalData?.userId}
                    >
                      Reconnect
                    </ButtonPrimary>
                  )}
                  {userInfo?.calendarType === "None" ? (
                    <ButtonPrimary onClick={() => outlookCalender()} className="small default" type="button" disabled={member?.userId !== universalData?.userId}>
                      Connect
                    </ButtonPrimary>
                  ) : (
                    <ButtonPrimary className="small default" type="button" disabled={member?.userId !== universalData?.userId}>
                      <DoneAllIcon sx={{ mr: 1 }} /> Connected
                    </ButtonPrimary>
                  )}
                </EntActWrap>
              </DecrWrap>
            </EntrWrapper>
          )}
          <EntrWrapper className="d-none">
            <ComingSoon />
            <DecrWrap>
              <IcnWrapper>
                <img src={Icon3} width={"44"} height={"44"} alt="" />
              </IcnWrapper>
              <TextureWrap>
                <EntHeading variant="h3">CalDav Server</EntHeading>
                <TextPara variant="body1">
                  For personal and business calendars
                </TextPara>
              </TextureWrap>
              <EntActWrap>
                <ButtonPrimary className="small" type="button">
                  Connect
                </ButtonPrimary>
              </EntActWrap>
            </DecrWrap>
          </EntrWrapper>
          <EntrWrapper className="d-none">
            <ComingSoon />
            <DecrWrap>
              <IcnWrapper>
                <img src={Icon4} width={"44"} height={"44"} alt="" />
              </IcnWrapper>
              <TextureWrap>
                <EntHeading variant="h3">Apple Calendar</EntHeading>
                <TextPara variant="body1">
                  For personal and business calendars
                </TextPara>
              </TextureWrap>
              <EntActWrap>
                <ButtonPrimary className="small" type="button">
                  Connect
                </ButtonPrimary>
              </EntActWrap>
            </DecrWrap>
          </EntrWrapper>
        </EntryRow>
        <EntryRow className="d-none">
          <Heading variant="h2">Webhooks</Heading>
          <TextPara variant="body1">
            Receive Cal meeting data at a specified URL, in real-time, when an
            event is scheduled or cancelled.
          </TextPara>
          <EntrWrapper>
            <ComingSoon />
            <DecrWrap>
              <IcnWrapper>
                <img src={Icon5} width={"44"} height={"44"} alt="" />
              </IcnWrapper>
              <TextureWrap>
                <EntHeading variant="h3">Webhooks</EntHeading>
                <TextPara variant="body1">Automation</TextPara>
              </TextureWrap>
              <EntActWrap>
                <ButtonPrimary className="small" type="button">
                  New Webhook
                </ButtonPrimary>
              </EntActWrap>
            </DecrWrap>
          </EntrWrapper>
        </EntryRow>
        <EntryRow className="d-none">
          <Heading variant="h2">iframe Embed</Heading>
          <TextPara variant="body1">
            The easiest way to embed meeting.6ix.com on your website.
          </TextPara>
          <EntrWrapper>
            <ComingSoon />
            <DecrWrap>
              <IcnWrapper>
                <img src={Icon6} width={"44"} height={"44"} alt="" />
              </IcnWrapper>
              <TextureWrap>
                <EntHeading variant="h3">Standard iframe</EntHeading>
                <TextPara variant="body1">
                  Embed your calendar within your webpage
                </TextPara>
              </TextureWrap>
              <EntActWrap>
                <ButtonTexture type="button">
                  &lt;iframe src="https://app&hellip;
                  <CopyIcon />
                </ButtonTexture>
              </EntActWrap>
            </DecrWrap>
          </EntrWrapper>
          <EntrWrapper>
            <ComingSoon />
            <DecrWrap>
              <IcnWrapper>
                <img src={Icon6} width={"44"} height={"44"} alt="" />
              </IcnWrapper>
              <TextureWrap>
                <EntHeading variant="h3">
                  Responsive full screen iframe
                </EntHeading>
                <TextPara variant="body1">
                  A fullscreen scheduling experience on your website
                </TextPara>
              </TextureWrap>
              <EntActWrap>
                <ButtonTexture type="button">
                  &lt;iframe src="https://app&hellip;
                  <CopyIcon />
                </ButtonTexture>
              </EntActWrap>
            </DecrWrap>
          </EntrWrapper>
        </EntryRow>
      </ContentArea>
    </Wrapper>
  );
}
