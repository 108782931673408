import { PageWrapper, ContainerWrapper, Wrapper } from "./style";
import EventMeetingsType from "components/EventMeetingsType";

const TypesMeeting = () => {
  return (
    <>
      <div className="sixmeetings-content">
        <div className="sixmeetings-adss">
          <PageWrapper>
            <ContainerWrapper>
              <EventMeetingsType />
            </ContainerWrapper>
          </PageWrapper>
        </div>
      </div>
    </>
  );
};
export default TypesMeeting;