import { styled, Box } from '@mui/material';

export const WelcomePageWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #131313;
`;

export const AlignWrapper = styled(Box)`
  min-height: inherit;
  display: flex;
  width: 100%;
`;

export const Align = styled(Box)`
  margin: auto;
  padding: 95px 15px 34px;
  width: 100%;

  @media (min-width: 768px){
    padding-bottom: 95px;
  }
`;