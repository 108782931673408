import {
  ActionsWrapper,
  AddNewDecription,
  AddNewHeading,
  AddNewModal,
  ButtonDefault,
  ButtonIcon,
  ButtonModalClose,
  ButtonPrimary,
  ButtonWrapper,
  DeleteModal,
  DescriptionWrapper,
  DropItemButton,
  FieldTagline,
  FieldUnitDropdown,
  FieldUnitGroup,
  FieldsInlineWrapper,
  FormField,
  FormGroup,
  Heading,
  HeadingWrapper,
  IconWrapper,
  ItemHeading,
  ItemMenu,
  LabelHeading,
  ListItem,
  ListingsWrapper,
  ModalButtons,
  ModalContent,
  ModalContentWrapper,
  ModalDescription,
  ModalHeader,
  ModalHeading,
  Subheading,
  Subtitle,
  SwitchEnabler,
  SwitchWrapper,
  Switcher,
  SwitcherTag,
  TextAreaField,
  TextPara,
  TitlesWrapper,
  UnitDropdown,
  Wrapper,
  UnitDropButton,
  UnitDropItemButton,
  ErrorText,
  Dropdownmenu,
} from "./style";
import React, { useContext, useEffect, useState } from "react";
import { ArrowRightIcon } from "components/Icons/ArrowRightIcon";
import { TimeClockIcon } from "components/Icons/TimeClockIcon";
import { LinkIcon } from "components/Icons/LinkIcon";
import { EyeViewIcon } from "components/Icons/EyeViewIcon";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  MenuItem,
  MenuList,
  TextField,
  duration,
} from "@mui/material";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { CrossIcon } from "components/Icons/CrossIcon";
import { UpDownFilledIcon } from "components/Icons/UpDownFilledIcon";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Controller, useForm } from "react-hook-form";
import { ChipDeleteIcon } from "components/Icons/ChipDeleteIcon";
import { toast } from "react-toastify";
import { DropDown } from "./DropDown";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "context";
import { CreateEvent } from "./CreateEvent";
import { eventURl } from "utils/helper";

export default function EventTypesTabpanel() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const [eventTypes, setEvenTypes] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [booker, setBooker] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const { userInfo, universalData, member } = useContext(AppContext);
  const [event, setEvent] = useState<any>();

  const [anchorUnitDropEl, setAnchorUnitDropEl] = useState(null);
  const handleUnitDropClick = (event: any) => {
    setAnchorUnitDropEl(event.currentTarget);
  };
  const handleUnitDropClose = () => {
    setAnchorUnitDropEl(null);
  };

  const [openModal, setOpen] = React.useState(false);
  const handleModalClose = () => {
    setOpen(false);
  };

  const [openAddNewModal, setAddNewOpen] = React.useState<any>(false);
  const handleAddNewModalOpen = () => setAddNewOpen(true);
  const handleAddNewModalClose = () => {
    setEvent(undefined);
    setBooker(false);
    setAddNewOpen(false);
    navigate("/types");
  };

  const statusHandler = (data: any) => {
    baseApi
      .post("/api/updateeventtype/" + data._id, {
        ...data,
        status: data.status === "Active" ? false : "Active",
        orgId: universalData.org.id,
      })
      .then((res) => {
        setRefetch((prev) => !prev);
        toast.success("Event Updated - " + res?.data?.updatedEvent?.title);
        handleAddNewModalClose();
      })
      .catch((err) => toast.error(err?.response?.data?.error));
  };

  const handleDeleteEvent = (data: any) => {
    baseApi
      .delete(
        `/api/remove/events/${universalData.org.id}/${member?.userId}/${data._id}`
      )
      .then((res) => {
        toast.success(res?.data?.message);
        setRefetch((prev) => !prev);
      })
      .catch((err) => toast.error(err?.response?.data?.error));
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    baseApi
      .post(
        data._id ? "/api/updateeventtype/" + data._id : "/api/addeventtype",
        {
          ...data,
          url: `${process.env.REACT_APP_SITE_URL}/${userInfo?.username}/${data?.pageName}`,
          username: userInfo?.username,
          userId: member?.userId,
          duration: data.duration,
          orgId: universalData.org.id,
          meetroom: data.meetroom,
        }
      )
      .then((res) => {
        setRefetch((prev) => !prev);
        if (data._id) {
          toast.success("Event Updated - " + res?.data?.updatedEvent?.title);
        } else {
          toast.success("Event Created - " + res?.data?.savedEvent?.title);
        }
        handleAddNewModalClose();
      })
      .catch((err) => toast.error(err?.response?.data?.error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setEvenTypes([]);
    if (member?.userId && member?.userType !== "New User") {
      setFetching(true);
      baseApi
        .post("/api/geteventtype", {
          userId: member?.userId,
          orgId: universalData?.org.id,
        })
        .then((res) => setEvenTypes(res.data.eventTypes))
        .catch((err) => toast.error(err?.response?.data?.error))
        .finally(() => setFetching(false));
    }
  }, [member?.userType, member?.userId, refetch]);

  return (
    <Wrapper>
      {member?.userType !== "New User" ? (
        <HeadingWrapper>
          <TitlesWrapper>
            <Heading variant="h1">Meeting Types</Heading>
            <TextPara variant="body1">
              Create events to share for people to book on your calendar.
            </TextPara>
          </TitlesWrapper>
        </HeadingWrapper>
      ) : (
        <>
          {member?.userType === "New User" &&
          member?.userId === universalData?.userId ? (
            <>
              <HeadingWrapper>
                <TitlesWrapper>
                  <Heading variant="h1">Event Types</Heading>
                  <TextPara variant="body1">
                    Create events to share for people to book on your calendar.
                  </TextPara>
                </TitlesWrapper>
              </HeadingWrapper>
              <Box
                sx={{
                  color: "#fff",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundColor: "#2b2b2b",
                  border: "2px solid rgb(43, 43, 43)",
                  borderRadius: "7px",
                  padding: "20px",
                  zIindex: "8",

                  ".info-text": {
                    fontSize: "16px",
                    lineHeight: "22px",
                    color: "#fff",
                    margin: "0 0 20px",
                    textAlign: "center",
                  },
                }}
              >
                <TextPara variant="body1" className="info-text">
                  You are not onboarded on meetings.6ix.com please onboard by
                  clicking the button below.
                </TextPara>
                <ButtonPrimary
                  type="button"
                  onClick={() => (window.location.href = "/setup")}
                >
                  Onboard Now
                </ButtonPrimary>
              </Box>
            </>
          ) : (
            <>
              <HeadingWrapper>
                <TitlesWrapper>
                  <Heading variant="h1">Event Types</Heading>
                  <TextPara variant="body1">
                    Create events to share for people to book on your calendar.
                  </TextPara>
                </TitlesWrapper>
              </HeadingWrapper>
              <Box
                sx={{
                  color: "#fff",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundColor: "#2b2b2b",
                  border: "2px solid rgb(43, 43, 43)",
                  borderRadius: "7px",
                  padding: "20px",
                  zIindex: "8",

                  ".info-text": {
                    fontSize: "16px",
                    lineHeight: "22px",
                    color: "#fff",
                    margin: "0 0 20px",
                    textAlign: "center",
                  },
                }}
              >
                <TextPara variant="body1" className="info-text">
                  Please invite your org members to onboard to meetings.6ix.com
                </TextPara>
                <ButtonPrimary
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_SITE_URL}/setup` ||
                        "https://meetings.6ix.com/setup"
                    );
                    toast.success("Copied to Clipboard");
                  }}
                >
                  Copy to Clipboard <EyeViewIcon />
                </ButtonPrimary>
              </Box>
            </>
          )}
        </>
      )}
      {fetching || !eventTypes ? (
        <Box
          sx={{
            color: "#E9E9E9",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "#2b2b2b",
            zIindex: "8",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <ListingsWrapper>
          {eventTypes?.map((event: any) => (
            <ListItem key={event._id} className="no-style">
              <DescriptionWrapper>
                <ItemHeading variant="h2">
                  {event.title}
                  <Subtitle variant="caption">{event.description}</Subtitle>
                </ItemHeading>
                <Subheading variant="caption">
                  <TimeClockIcon />
                  {event.duration + " min"}
                </Subheading>
              </DescriptionWrapper>
              <ActionsWrapper>
                {/* <SwitcherTag
              variant="caption"
            >
              Hidden
            </SwitcherTag> */}
                <Switcher
                  control={
                    <SwitchEnabler
                      defaultChecked={event.status === "Active"}
                      onClick={() => statusHandler(event)}
                      disabled={member?.userId !== universalData?.userId}
                    />
                  }
                  label=""
                />
                <ButtonIcon
                  onClick={() => navigate(eventURl(event))}
                  type="button"
                >
                  <EyeViewIcon />
                </ButtonIcon>
                <ButtonIcon
                  onClick={() => {
                    navigator.clipboard.writeText(
                      process.env.REACT_APP_SITE_URL + eventURl(event)
                    );
                    toast.success("Copied to Clipboard");
                  }}
                  type="button"
                >
                  <LinkIcon />
                </ButtonIcon>
                <Dropdownmenu
                  onSubmit={onSubmit}
                  reset={setEvent}
                  event={event}
                  eventsList={eventTypes}
                  setBooker={setBooker}
                  handleAddNewModalOpen={handleAddNewModalOpen}
                  handleDeleteEvent={handleDeleteEvent}
                  inAccessible={member?.userId !== universalData?.userId}
                />
              </ActionsWrapper>
            </ListItem>
          ))}
        </ListingsWrapper>
      )}
      <DeleteModal open={openModal} onClose={handleModalClose}>
        <ModalContent>
          <IconWrapper>
            <DeleteIcon />
          </IconWrapper>
          <ModalHeading variant="h3">Delete Event Type?</ModalHeading>
          <ModalDescription variant="body1">15 Min Meeting</ModalDescription>
          <ModalButtons>
            <ButtonDefault type="button" onClick={handleModalClose}>
              Cancel
            </ButtonDefault>
            <ButtonPrimary type="button">Yes, Delete</ButtonPrimary>
          </ModalButtons>
        </ModalContent>
      </DeleteModal>

      <CreateEvent
        data={event}
        openAddNewModal={openAddNewModal || searchParams?.get("new-event")}
        handleAddNewModalClose={handleAddNewModalClose}
        loading={loading}
        onSubmit={onSubmit}
      />
    </Wrapper>
  );
}
