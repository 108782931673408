import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "routes";
import { Context } from "context";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <Context>
      <BrowserRouter>
        <div className="sixmeetings">
          <AppRouter />
        </div>
      </BrowserRouter>
      <ToastContainer
          closeOnClick={true}
          hideProgressBar={true}
          autoClose={3000}
          theme={"dark"}
          position="bottom-left"
        />
    </Context>
  );
};

export default App;
