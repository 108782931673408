import { styled, Box } from '@mui/material';

export const PageWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  /* background-color: #131313; */
`;

export const ContainerWrapper = styled(Box)`
  width: 100%;
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  .types-block {
    padding-left: 1px;
    padding-right: 1px;
  }


  &:after{
    content: '';
    display: block;
    clear: both;
  }
`;