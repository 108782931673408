import React from "react";
import Flex from "components/FlexWrapper";
import PageFooterContentLeft from "./PageFooterContentLeft";
import PageFooterContentRight from "./PageFooterContentRight";

const PageFooterContent = () => {
  return (
    <Flex className="meeting6ix-layout-footer-content">
      <PageFooterContentLeft />
      <PageFooterContentRight />
    </Flex>
  );
};

export default PageFooterContent;
