import React, { useState } from "react";
import { Link } from "react-router-dom";
import { List, ListItemButton, ListItemText, Menu } from "@mui/material";
import AngleIcon from "assets/images/icons/AngleIcon";
import { BASE_SITE_URL } from "config";

const HeaderExpertise = () => {
  const [headerMenuEl, setHeaderMenuEl] = useState(null);
  const headerMenuOpen = Boolean(headerMenuEl);
  const headerMenuOpenReq = (event: any) => {
    setHeaderMenuEl(event.currentTarget);
  };
  const headerMenuClose = () => {
    setHeaderMenuEl(null);
  };

  return (
    <>
      <div
        className={`meeting6ix-layout-header-right-item-btn meeting6ix-layout-header-right-item-btn-full ${
          headerMenuOpen ? "active" : ""
        }`}
        id="header-expertise-menu-button"
        aria-controls={headerMenuOpen ? "header-expertise-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerMenuOpen ? "true" : undefined}
        onClick={headerMenuOpenReq}
      >
        <div className="meeting6ix-layout-header-right-item-btn-text">
          Share Expertise
        </div>
        {headerMenuOpen ? <AngleIcon up /> : <AngleIcon small />}
      </div>

      <Menu
        id="header-expertise-menu"
        anchorEl={headerMenuEl}
        open={headerMenuOpen}
        onClose={headerMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-expertise-menu-button",
        }}
        className="meeting6ix-layout-header-right-item-dropdown meeting6ix-layout-header-right-item-dropdown-users"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List
          component="nav"
          className="meeting6ix-layout-header-right-item-dropdown-users-list"
        >
          <a
            className="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters meeting6ix-layout-header-right-item-dropdown-users-list-item"
            href={`${BASE_SITE_URL}/6ix/broadcast`}
            onClick={headerMenuClose}
          >
            <ListItemText
              className="meeting6ix-layout-header-right-item-dropdown-users-list-item-text"
              primary="Broadcast Interviews"
            />
          </a>
        </List>
      </Menu>
    </>
  );
};

export default HeaderExpertise;
