import { useRef, useState, useEffect } from "react";
import { CustomDivider, LaningPageWrapper } from "./style";
import IntroSection from "components/IntroSection";
import BlogsSection from "components/BlogsSection";
import IconsSection from "components/IconsSection";
import PresentationSection from "components/PresentationSection";
import ListingSection from "components/ListingSection";
import UpdatesSection from "components/UpdatesSection";
import CapabilitiesSection from "components/CapabilitiesSection";
import ConditionsSection from "components/ConditionsSection";
import MarketBlogsSection from "components/MarketBlogsSection";
import CoursesSection from "components/CoursesSection";
import CardBox from "components/CardBox";
import CourseColumn from "components/CourseColumn";
import SectionHeader from "components/SectionHeader";
import ThreeColumnsGrid from "components/ThreeColumnsGrid";
import ProductColumnsSection from "components/ProductColumnsSection";
import SponsorsSection from "components/SponsorsSection";
import TestimonialsSection from "components/TestimonialsSection";
import VideosSection from "components/VideosSection";
import Image1 from "../../assets/images/img09.jpg"
import Image2 from "../../assets/images/img10.jpg"
import Image3 from "../../assets/images/img11.jpg"
import Image4 from "../../assets/images/img12.jpg"
import Image5 from "../../assets/images/img13.jpg"
import Image6 from "../../assets/images/img14.jpg"
import Image7 from "../../assets/images/img15.jpg"
import Image8 from "../../assets/images/img16.jpg"
import Image9 from "../../assets/images/img17.jpg"
import Image10 from "../../assets/images/img18.jpg"
import Image11 from "../../assets/images/img19.jpg"
import Image12 from "../../assets/images/img20.jpg"
import Image13 from "../../assets/images/img21.jpg"
import Image14 from "../../assets/images/img22.jpg"
import Image15 from "../../assets/images/img23.jpg"
import Image16 from "../../assets/images/img24.jpg"
import Image17 from "../../assets/images/img25.jpg"
import Image18 from "../../assets/images/img26.jpg"
import Image19 from "../../assets/images/img27.jpg"
import Image20 from "../../assets/images/img28.jpg"
import Image21 from "../../assets/images/img29.jpg"
import Image22 from "../../assets/images/img38.jpg"
import Image23 from "../../assets/images/img39.jpg"
import Image24 from "../../assets/images/img40.jpg"
import Image25 from "../../assets/images/img41.jpg"
import Image26 from "../../assets/images/img42.jpg"
import Image27 from "../../assets/images/img43.jpg"
import Image28 from "../../assets/images/img44.jpg"
import Image29 from "../../assets/images/img45.jpg"
import Image30 from "../../assets/images/img46.jpg"
import Image31 from "../../assets/images/img47.jpg"
import Image32 from "../../assets/images/img48.jpg"
import Image33 from "../../assets/images/img49.jpg"
import AimsSection from "components/AimsSection";
import PricingSection from "components/PricingSection";
import ReportSection from "components/ReportSection";
import RichtextSection from "components/RichtextSection";
import FormSection from "components/FormSection";
import ScheduleSection from "components/ScheduleSection";
import StickyButtons from "components/StickyButtons";
import { Carousel } from "react-responsive-carousel";
import { useInView } from "react-intersection-observer";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import EventTypes from "components/EventTypes";
import PageFooter from "components/PageFooter";


const HomePage = () => {
  const [fixedAction, setFixedAction] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dataFetched, setDataFetched] = useState(false);
  const getTocuhSection = useRef(null);
  const { ref: tocuhSectionref, inView: tocuhSectionVisible } = useInView({
    threshold: 0,
  });

  const refferOnTouch = () => {
    getTocuhSection.current?.scrollIntoView({ behavior: "smooth" });
  };


  const { ref: landingIntroAction, inView: landingIntroActionVisible } =
    useInView({
      threshold: 0,
    });
  useEffect(() => {
    if (!dataFetched) {
      setTimeout(function () {
        setDataFetched(true);
      }, 2000);
    }
  }, [dataFetched]);

  useEffect(() => {
    if (landingIntroActionVisible || tocuhSectionVisible) {
      setFixedAction(false);
    } else {
      setFixedAction(true);
    }
  }, [landingIntroActionVisible, tocuhSectionVisible]);

  return (
    <>
        <EventTypes />
        <div className="sixmeetings-content">
          <div className="sixmeetings-adss">
            <LaningPageWrapper>
              <div ref={landingIntroAction}>
                <IntroSection pTop="0" pBottom="125px" refferOnTouch={refferOnTouch} />
              </div>
              <div className="d-none">
                <BlogsSection pTop="125px" pBottom="125px" bgColor="#1f1f1f" />
                <IconsSection pTop="125px" pBottom="125px" />
                <PresentationSection pTop="125px" pBottom="125px" bgColor="#1f1f1f" />
                <ListingSection pTop="125px" pBottom="125px" />
                <UpdatesSection pTop="125px" pBottom="125px" bgColor="#1f1f1f" />
                <CapabilitiesSection pTop="125px" pBottom="125px" />
                <ConditionsSection pTop="125px" pBottom="125px" bgColor="#1f1f1f" />
                <MarketBlogsSection pTop="125px" pBottom="125px" />
                <CoursesSection pTop="125px" pBottom="125px" bgColor="#1f1f1f">
                  <SectionHeader
                    headingLarge
                    heading="Set a campaign goal such as generating investor meetings or capturing emails"
                  />
                  {isMobile ? (
                    <Carousel
                      preventMovementUntilSwipeScrollTolerance={true}
                      swipeScrollTolerance={50}
                      showThumbs={false}
                      showArrows={true}
                      className="leads-landing-page-slider"
                    >
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image1}
                          heading="Investor Meetings"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image2}
                          heading="Capture Emails"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image3}
                          heading="Download Investor Materials"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image4}
                          heading="Watch Videos"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image5}
                          heading="Generate Clicks"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image6}
                          heading="Generate Impressions"
                        />
                      </CardBox>
                    </Carousel>
                    ) : (
                      <ThreeColumnsGrid>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image1}
                            heading="Investor Meetings"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image2}
                            heading="Capture Emails"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image3}
                            heading="Download Investor Materials"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image4}
                            heading="Watch Videos"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image5}
                            heading="Generate Clicks"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image6}
                            heading="Generate Impressions"
                          />
                        </CardBox>
                      </ThreeColumnsGrid>
                    )
                  }
                </CoursesSection>
                <CustomDivider />
                <CoursesSection pTop="125px" pBottom="125px" bgColor="#1f1f1f">
                  <SectionHeader
                    headingLarge
                    heading="Upload your own creative and copy or work with our team to create the campaign"
                  />
                  {isMobile ? (
                    <Carousel
                      preventMovementUntilSwipeScrollTolerance={true}
                      swipeScrollTolerance={50}
                      showThumbs={false}
                      showArrows={true}
                      className="leads-landing-page-slider"
                    >
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image7}
                          heading="Display Ad"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image8}
                          heading="Native Ad"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image9}
                          heading="Video Ad"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image22}
                          heading="Twitter Ad"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image23}
                          heading="Facebook Ad"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image24}
                          heading="Instagram Ad"
                        />
                      </CardBox>
                    </Carousel>
                    ) : (
                      <ThreeColumnsGrid>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image7}
                            heading="Display Ad"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image8}
                            heading="Native Ad"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image9}
                            heading="Video Ad"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image22}
                            heading="Twitter Ad"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image23}
                            heading="Facebook Ad"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image24}
                            heading="Instagram Ad"
                          />
                        </CardBox>
                      </ThreeColumnsGrid>
                    )
                  }
                </CoursesSection>
                <CustomDivider />
                <CoursesSection pTop="125px" pBottom="125px" bgColor="#1f1f1f">
                  <SectionHeader
                    headingLarge
                    heading="Go live with a small campaign and scale your budget as the ad performs"
                  />
                  {isMobile ? (
                    <Carousel
                      preventMovementUntilSwipeScrollTolerance={true}
                      swipeScrollTolerance={50}
                      showThumbs={false}
                      showArrows={true}
                      className="leads-landing-page-slider"
                    >
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image25}
                          heading="Flexible minimum spend"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image11}
                          heading="Up and running in minutes"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image12}
                          heading="Get results, then scale"
                        />
                      </CardBox>
                    </Carousel>
                    ) : (
                      <ThreeColumnsGrid>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image25}
                            heading="Flexible minimum spend"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image11}
                            heading="Up and running in minutes"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image12}
                            heading="Get results, then scale"
                          />
                        </CardBox>
                      </ThreeColumnsGrid>
                    )
                  }
                </CoursesSection>
                <CustomDivider />
                <CoursesSection pTop="125px" pBottom="125px" bgColor="#1f1f1f">
                  <SectionHeader
                    headingLarge
                    heading="Respond to investors requesting meetings and requesting more information"
                  />
                  {isMobile ? (
                    <Carousel
                      preventMovementUntilSwipeScrollTolerance={true}
                      swipeScrollTolerance={50}
                      showThumbs={false}
                      showArrows={true}
                      className="leads-landing-page-slider"
                    >
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image13}
                          heading="Receive Meeting Requests"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image14}
                          heading="Review Investor Information"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image15}
                          heading="Accept or Reject Meetings"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image31}
                          heading="Automatic Calendar Integration"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image32}
                          heading="Easy Investor Outreach"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image33}
                          heading="Keep Track of Deal Stages"
                        />
                      </CardBox>
                    </Carousel>
                    ) : (
                      <ThreeColumnsGrid>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image13}
                            heading="Receive Meeting Requests"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image14}
                            heading="Review Investor Information"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image15}
                            heading="Accept or Reject Meetings"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image31}
                            heading="Automatic Calendar Integration"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image32}
                            heading="Easy Investor Outreach"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image33}
                            heading="Keep Track of Deal Stages"
                          />
                        </CardBox>
                      </ThreeColumnsGrid>
                    )
                  }
                </CoursesSection>
                <CustomDivider />
                <CoursesSection pTop="125px" pBottom="125px" bgColor="#1f1f1f">
                  <SectionHeader
                    headingLarge
                    heading="Report on Your Ad Performance on the Aggregate and Individual Level"
                  />
                  {isMobile ? (
                    <Carousel
                      preventMovementUntilSwipeScrollTolerance={true}
                      swipeScrollTolerance={50}
                      showThumbs={false}
                      showArrows={true}
                      className="leads-landing-page-slider"
                    >
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image16}
                          heading="Investor Composition"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image17}
                          heading="Shareholder Composition"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image18}
                          heading="Sentiment Composition"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image19}
                          heading="Geography information"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image20}
                          heading="Firmographic information"
                        />
                      </CardBox>
                      <CardBox>
                        <CourseColumn
                          imageUrl={Image21}
                          heading="Demographic information"
                        />
                      </CardBox>
                    </Carousel>
                    ) : (
                      <ThreeColumnsGrid>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image16}
                            heading="Investor Composition"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image17}
                            heading="Shareholder Composition"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image18}
                            heading="Sentiment Composition"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image19}
                            heading="Geography information"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image20}
                            heading="Firmographic information"
                          />
                        </CardBox>
                        <CardBox>
                          <CourseColumn
                            imageUrl={Image21}
                            heading="Demographic information"
                          />
                        </CardBox>
                      </ThreeColumnsGrid>
                    )
                  }
                </CoursesSection>
                <ProductColumnsSection pTop="125px" pBottom="125px" />
                <SponsorsSection pTop="125px" pBottom="125px" bgColor="#1f1f1f" />
                <TestimonialsSection pTop="125px" pBottom="125px" />
                <VideosSection pTop="125px" pBottom="125px" bgColor="#1f1f1f" />
                <ScheduleSection pTop="125px" pBottom="125px" />
                <AimsSection pTop="125px" pBottom="125px" bgColor="#1f1f1f" />
                {/* <PricingSection pTop="125px" pBottom="125px" /> */}
                <ReportSection pTop="125px" pBottom="125px" />
                <RichtextSection pTop="125px" pBottom="125px" bgColor="#1f1f1f" />
                
                <div ref={tocuhSectionref}>
                  <section ref={getTocuhSection}>
                    <FormSection pTop="125px" pBottom="125px" />
                  </section>
                </div>
                {fixedAction && dataFetched && 1 > 2 &&
                  <StickyButtons buttonPrimaryLabel="GET IN TOUCH" buttonSecondaryLabel="LAUNCH A CAMPAIGN" refferOnTouch={refferOnTouch} />
                }
              </div>
            </LaningPageWrapper>
          </div>
        </div>
        <PageFooter />
    </>
  );
};
export default HomePage;
