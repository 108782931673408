import { styled, Typography, Box, Tabs, Tab } from '@mui/material';

export const Wrapper = styled(Box)`
  width: 100%;
  padding-top: 91px;
  padding-bottom: 24px;
`;

export const SidebarHeading = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #C0C0C0;
  border-bottom: 1px solid #2B2B2B;
  padding-bottom: 16px;
  margin-bottom: 16px;
  display: block;

  i{
    vertical-align: -0.4em;
    margin-right: 8px;
    line-height: 0;

    img{
      max-width: 100%;
      height: auto;
    }
  }
`;

export const ContentColumns = styled(Box)`

  @media (min-width: 768px){
    display: flex;
    gap: 20px;
    width: 100%;
  }
  @media (min-width: 1200px){
    gap: 36px;
  }
`;

export const ContentSidebar = styled(Box)`
  margin-bottom: 36px;

  @media (min-width: 768px){
    /* width: 220px; */
    flex-shrink: 0;
    margin-bottom: 0;
  }
`;

export const ContentArea = styled(Box)`
  width: 100%;
  position: relative;
`;

export const UserHeading = styled(Typography)`
  font-size: 12px;
  color: #a1a1a1;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 20px;
`;

export const IconText = styled(`span`)`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid #575757;
  background-color: #373737;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .MuiSvgIcon-root{
    width: 20px;
    height: 20px;
  }
`;

export const TabsetList = styled(Tabs)`
  
  .MuiTabs-indicator{
    display: none;
  }
`;

export const TabButton = styled(Tab)`
  line-height: 20px;
  font-size: 14px;
  color: #575757;
  padding: 8px 0;
  text-align: left;
  text-transform: none;
  min-width: 1px;
  display: block;
  width: 100%;
  min-height: 1px;
  max-width: none;
  transition: color 0.3s ease;
  align-items: flex-start;

  &:hover,
  &.Mui-selected{
    color: #fff;
  }
`;