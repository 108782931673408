import React, { useContext, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import {
  AnchorLink,
  ButtonDropdownWrapper,
  ButtonOpener,
  CourseMenu,
  DescriptionWrapper,
  TextPara,
  ProfileImgWrapper,
} from "./style";
import { DownIcon } from "components/Icons/DownIcon";
import { TickIcon } from "components/Icons/TickIcon";
import { createOrg, getUserList, switchOrgList } from "../../firebase";
import { UserIcon } from "components/Icons/UserIcon";
import { AppContext } from "context";
import avatar from "../../assets/images/avatar.png";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function UserDropdown({ label, id }: any) {
  const { pathname } = useLocation();
  const [userList, setUserList] = useState<any>();
  const { universalData, setMember, member } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeUser = (item: any) => {
    setMember(item);
  };

  const setSelectedUser = (usersList: any) => {
    let selectedMember = null;

    usersList.map((listItem: any) => {
      if(listItem?.userId === universalData?.userId) {
        selectedMember = listItem;
      }
    });

    // usersList.map((listItem: any) => {
    //   if(listItem.userType === "Existing User" && listItem?.userId === universalData?.userId) {
    //     selectedMember = listItem;
    //   }
    // });

    if(!selectedMember) {
      usersList.map((listItem: any) => {
        if(listItem.userType === "Existing User") {
          selectedMember = listItem;
        }
      });
    }
    

    if(!selectedMember) {
      usersList.map((listItem: any) => {
        if(listItem.role === "owner") {
          selectedMember = listItem;
          return selectedMember;
        }
      });
    }

    return selectedMember;
  };

  useEffect(() => {
    if (universalData?.userId) {
      getUserList(universalData).then((res) => {
        if (!member) {
          const filteredMembers = res.data.data.list.filter((listItem: any) => {
            if (listItem.userId === universalData.userId) {
              return listItem
            }

            if(listItem.userType === "Existing User" && listItem?.userId === member?.userId) {
              return listItem;
            } else if (listItem.userType === "Existing User") {
              return listItem
            } else if (listItem.role === "owner") {
              return listItem;
            }
        });
          setMember(setSelectedUser(filteredMembers));
        }
        setUserList(res.data.data.list);
      });
    }
  }, [universalData?.userId]);

  return (
    <ButtonDropdownWrapper>
      <ButtonOpener
        id={id}
        aria-controls={`${label}-menu`}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "calc(100% - 27px)",
          }}
        >
          <ProfileImgWrapper>
            {member ? (
              <img
                src={
                  member?.profile?.profileImage ||
                  `https://ui-avatars.com/api/?name=${member?.profile?.name?.replaceAll(
                    " ",
                    "+"
                  )}&background=random`
                }
                alt="profile"
              />
            ) : (
              <UserIcon />
            )}
          </ProfileImgWrapper>
          <span className="text-span">{member?.profile.name}</span>
        </Box>
        <i className={`icn-wrap ${Boolean(anchorEl) ? "expanded" : ""}`}>
          <DownIcon />
        </i>
      </ButtonOpener>
      <CourseMenu
        id={`${label}-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {userList?.map((item: any, v: any) => (
          <MenuItem onClick={handleClose}>
            <AnchorLink onClick={() => changeUser(item)}>
              {/* <AnchorLink> */}
              {item?.userId === member?.userId && (
                <i className="tick-icn">
                  <TickIcon />
                </i>
              )}
              {/* <ProfileImgWrapper>
                {item?.orgLogo ? (
                  <img src={item?.orgLogo} alt="logo" />
                ) : (
                  <UserIcon />
                )}
              </ProfileImgWrapper> */}
              <DescriptionWrapper className="DescriptionWrapper">
                <TextPara variant="body1">{item?.profile?.name}</TextPara>
                <TextPara variant="body1" className="email">
                  {item?.profile?.email}
                </TextPara>
              </DescriptionWrapper>
            </AnchorLink>
          </MenuItem>
        ))}
      </CourseMenu>
    </ButtonDropdownWrapper>
  );
}
