
import { createContext, useState } from "react"
import baseApi from "api/baseApi";

export const AppContext = createContext<any>(undefined)

export const Context = ({ children }: any) => {
	const [userInfo, setUserInfo] = useState();
	const [member, setMember] = useState();
	const [skipStep, setSkipStep] = useState(0);
	const [universalData, setUniversalData] = useState<any>();
	const [balance, setBalance] = useState(0);
	const [org, setOrg] = useState<any>('');
	const [orgList, setOrgList] = useState<any>([]);
	const [loginRequest, setLoginRequest] = useState(false);
	const [openSidebar, setOpenSidebar] = useState(false);


	const updateOrg = (data: string) => {
		if (data !== org) {
			localStorage.setItem('org', JSON.stringify(data));
			setOrg(data)
		}
	}

	const updateUsername = (user: any) => {
		setUserInfo(user)
	}

	const updateOrgList = (universalData: any) => {
		baseApi.post('/api/v1/getorglist', {
			userId: universalData?.userId,
			org: universalData?.org?.id,
		}).then((res) => setOrgList(res.data)).catch((err) => Promise.reject(err))
	}

	return (
		<AppContext.Provider value={{
			balance, org,
			updateUsername, userInfo,
			updateOrg, orgList, updateOrgList, setUniversalData, universalData, 
			member, setMember,
			loginRequest,
			setLoginRequest,
			skipStep, setSkipStep,
			openSidebar, setOpenSidebar,
		}}>
			{children}
		</AppContext.Provider>
	);
}