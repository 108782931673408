import React, { useEffect, useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import "react-image-crop/src/ReactCrop.scss";
import { toast } from "react-toastify";
import { ButtonModalClose, ButtonPrimary, ButtonSecondary, ButtonsWrapper, EditButtonsWrapper, EditCropImageModal } from "./style";

const ImageCropper = ({ open, onCancel, data, onUpdate, size }: any) => {
  const [crop, setCrop] = useState<any>();
  const [completedCrop, setCompletedCrop] = useState<any>();
  const [aspect, setAspect] = useState<any>(data?.ratio);
  const [feature, setFeature] = useState<any>(data?.feature?.[0] || "");
  const imgRef = useRef(null);

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: any
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: data?.minWidth < mediaWidth ? "%" : ("px" as any),
          width: data?.minWidth < mediaWidth ? "90" : data?.minWidth,
          //height: data?.minHeight,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad() {
    if (aspect) {
      const { width, height }: any = imgRef.current;
      const newCrop = convertToPixelCrop(
        centerAspectCrop(width, height, aspect),
        width,
        height
      );
      setCrop(newCrop);
    }
  }
  useEffect(() => {
    if (feature === "freedom") {
      setAspect(undefined);
    } else {
      setAspect(data?.ratio);
    }
  }, [feature, data]);

  useEffect(() => {
    if (aspect && imgRef?.current) {
      const { width, height } = imgRef.current;
      const newCrop = convertToPixelCrop(
        centerAspectCrop(width, height, aspect),
        width,
        height
      );
      setCrop(newCrop);
      setCompletedCrop(newCrop);
    }
  }, [aspect]);

  const getCroppedImg = () => {
    const image: any = imgRef.current;
    const previewCanvas = document.createElement("canvas");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    previewCanvas.width = crop.width;
    previewCanvas.height = crop.height;
    const ctx: any = previewCanvas.getContext("2d");

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    return new Promise((resolve, reject) => {
      previewCanvas.toBlob((blob: any) => {
        if (!blob) {
          reject({ message: "Image crop is empty" });
          return;
        }
        blob["name"] = "image.png";
        resolve(blob);
      }, "image/png");
    });
  };
  const onSave = async () => {
    try {
      const croppedImage = await getCroppedImg();
      //formData.append("file", croppedImage, "croppedImage.jpeg");
      // console.log(URL.createObjectURL(croppedImage), "croppedImage");
      onUpdate(croppedImage);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <EditCropImageModal
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog "
      maxWidth={size || "sm"}
      fullWidth={true}
    >
      {data?.title && (
        <DialogTitle>{data?.title}</DialogTitle>
      )}
      <DialogContent>
        <ReactCrop
          className="eventsCrop-box"
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          minHeight={data?.minHeight}
          minWidth={data?.minWidth}
          circularCrop={feature === "circle"}
          keepSelection
        >
          <img
            className="eventsCrop-box-img"
            src={data?.image}
            alt=""
            onLoad={onImageLoad}
            ref={imgRef}
          />
        </ReactCrop>
      </DialogContent>
      <EditButtonsWrapper>
        {data?.feature?.map((item: any) => (
          <Button
            size="medium"
            className={`eventsCrop-actions-btn ${
              feature === item ? "active" : ""
            } `}
            onClick={() => setFeature(item)}
          >
            {item}
          </Button>
        ))}
      </EditButtonsWrapper>
      <ButtonsWrapper className="has-paddings">
        <ButtonSecondary
          onClick={onCancel}
          className="custom-dialog-btn custom-dialog-btn-no"
        >
          Cancel
        </ButtonSecondary>
        <ButtonPrimary
          onClick={onSave}
          className="custom-dialog-btn custom-dialog-btn-yes"
        >
          Crop
        </ButtonPrimary>
      </ButtonsWrapper>
    </EditCropImageModal>
  );
};

export default ImageCropper;
