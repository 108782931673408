import { useEffect, useState } from "react";
import { WelcomePageWrapper, AlignWrapper, Align } from "./style";
import LoginStepTwo from "components/LoginStepTwo";

const LoginPageTwo = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [])

  if (loading) return null

  return (
    <>
      <div className="sixmeetings-content">
        <div className="sixmeetings-adss">
          <WelcomePageWrapper>
            <AlignWrapper>
              <Align>
                <LoginStepTwo />
              </Align>
            </AlignWrapper>
          </WelcomePageWrapper>
        </div>
      </div>
    </>
  );
};
export default LoginPageTwo;