import EventTypes from "components/EventTypes";
import { PageWrapper, ContainerWrapper } from "./style";

const Types = () => {
  return (
    <>
      <div className="sixmeetings-content">
        <div className="sixmeetings-adss">
          <PageWrapper>
            <ContainerWrapper>
              <EventTypes />
            </ContainerWrapper>
          </PageWrapper>
        </div>
      </div>
    </>
  );
};
export default Types;