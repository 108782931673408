import { styled, Box, Button, Link, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

export const Wrapper = styled(Box)`
  background-color: #1F1F1F;
  padding: 8px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid rgb(43, 43, 43);

  @media (min-width: 768px){
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const NavWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-grow: 1;

  @media (min-width: 768px){
    gap: 10px;
  }
`;

export const Logo = styled(Link)`
  line-height: 0;
  display: block;
  margin-left: 8px;
  
  img{
    max-width: 100%;
    height: auto;
  }
`;
