import { styled, Box, Typography, Divider, Button, TextField, FormControl } from '@mui/material';

export const Wrapper = styled('div')`
  color: #C0C0C0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
`;

export const HeadingWrapper = styled(Box)`
  margin-bottom: 36px;
  text-align: center;
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 12px;
  color: #fff;
`;

export const Description = styled(Typography)`
  font-size: 14px;
  line-height: 1.5;

  @media (min-width: 768px){
    font-size: 16px;
  }
`;

export const Title = styled(Typography)`
  font-size: 18px;
  line-height: 22px;
  display: block;
  color: #E9E9E9;
`;

export const StepsWrapper = styled(Box)`
  margin-bottom: 36px;
`;

export const StepBarsWrapper = styled(Box)`
  margin-top: 16px;
  display: flex;
  gap: 12px;
`;

export const StepBar = styled(Box)`
  flex-grow: 1;
  flex-basis: 0;
  height: 8px;
  border-radius: 99px;
  background-color: #2B2B2B;

  &.active{
    background-color: #E2BF78;
  }
`;

export const BoxWrapper = styled(Box)`
  border: 1px solid #373737;
  border-radius: 11px;
  background-color: #1f1f1f;
  padding: 20px;
`;

export const ButtonPrimary = styled(Button)`
  margin-top: 32px;
  display: block;
  min-width: 1px;
  width: 100%;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 13px 20px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;

export const StepBackWrapper = styled(Box)`
  display: flex;
  gap: 10px;
  margin-top: 36px;
`;

export const LeftWrapper = styled(Box)`
  margin-right: auto;
`;

export const UploadWrapper = styled(Box)`
  
`;

export const ButtonUploader = styled(Button)`
  min-width: 1px;
  background-color: transparent;
  border: 0;
  color: #e9e9e9;
  border-radius: 0;
  text-transform: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 12px;

  &:hover{
    background-color: transparent;
    color: #e9e9e9;
    box-shadow: none;

    .MuiTypography-caption{
      background-color: #000;
      border-color: #E3C050;
      color: #fff;
    }
  }
`;

export const PicWrapper = styled(`span`)`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #2B2B2B;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8C8E95;
  position: relative;
  overflow: hidden;

  .MuiSvgIcon-root{
    width: 24px;
    height: 24px;
  }

  img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const LabelText = styled(Typography)`
  display: inline-block;
  vertical-align: top;
  border: 1px solid #373737;
  background-color: #2B2B2B;
  border-radius: 4px;
  padding: 7px 10px;
  color: #8C8E95;
  font-size: 12px;
  line-height: 14px;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;

  .MuiSvgIcon-root{
    width: 14px;
    height: 14px;
    margin-left: 6px;
    vertical-align: middle;
  }
`;

export const ErrorText = styled(Typography)`
  font-size: 14px;
  line-height: 1;
  color: #E64135;
  display: block;
  margin-top: 14px;
`;

export const FormGroup = styled(Box)`
  
  + div{
    margin-top: 24px;
  }
`;

export const TextNote = styled(Typography)`
  font-size: 13px;
  line-height: 15px;
  display: block;
  color: #8C8E95;
  margin-top: 8px;
`;

export const LabelHeading = styled(Typography)`
  font-size: 13px;
  line-height: 15px;
  display: block;
  color: #8C8E95;
  margin-bottom: 8px;
`;

export const TextAreaField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 99px;
  font-size: 14px;
  line-height: 18px;

  textarea{
    padding: 0;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  > div{
    display: block;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }
`;

export const ButtonLink = styled(Button)`
  min-width: 1px;
  background-color: transparent;
  color: #8C8E95;
  text-transform: none;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding: 0;

  &:hover{
    background-color: transparent;
    color: #E9E9E9;
    border-color: transparent;
  }
`;