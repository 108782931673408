import { useContext, useEffect, useState } from "react";
import {
  Description,
  Heading,
  Wrapper,
  HeadingWrapper,
  StepBar,
  StepBarsWrapper,
  StepsWrapper,
  Title,
  BoxWrapper,
  ActionBtnsWrapper,
  ButtonDefault,
  SeperatorOr,
  FormGroup,
  LabelHeading,
  FormField,
  CaptionWrapper,
  FormSelectField,
  TextNote,
  ButtonPrimary,
  ErrorText,
  PicWrapper,
  ProfileWrapper,
} from "./style";
import { Controller, useForm } from "react-hook-form";
import TimezoneSelect from "react-timezone-select";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { toast } from "react-toastify";
import { getCurrentTimeInTimezone } from "utils/helper";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { AppContext } from "context";
import { getUserProfile } from "api/userApi";
import { UserForm } from "./UserForm";
import { Box, Modal } from "@mui/material";
import EventProfileTabpanel from "components/EventProfileTabpanel";
import { UserIcon } from "components/Icons/UserIcon";
import { RightWrapper } from "components/LoginStepTwo/style";
import { ButtonLink } from "components/AvailabilityForm/style";

export default function LoginStepOne({ setStep }: any) {
  const navigate = useNavigate();
  const [editProfile, setEditProfile] = useState(false);
  const [importFrom, setImport] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>();
  const { universalData } = useContext(AppContext);
  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data: any) => {
    setLoading(true);
    baseApi
      .post("/api/addusername", {
        userId: universalData?.userId,
        orgId: universalData?.org.id,
        ...data,
        timezone: data.timezone.value || data.timezone,
        email: universalData?.email,
      })
      .then((res) => {
        toast.success(res?.data?.message);
        navigate("/setup/login-step-2");
      })
      .catch((err) => toast.error(err?.response?.data?.error))
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   if (universalData?.userId && universalData?.org?.id) {
  //     getUserProfile(universalData?.userId, universalData?.org?.id).then(
  //       (res) => setUserData(res.data)
  //     );
  //   }
  // }, [universalData?.userId, universalData?.org?.id]);

  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading variant="h1">Welcome to meetings.6ix.com</Heading>
        <Description variant="body1">
          Tell us what to call you and let us know what timezone you’re in.{" "}
          <br />
          You’ll be able to edit this later.
        </Description>
      </HeadingWrapper>
      <StepsWrapper>
        <Title variant="h2">Step 1 of 4</Title>
        <StepBarsWrapper>
          <StepBar className="active"></StepBar>
          <StepBar></StepBar>
          <StepBar></StepBar>
          <StepBar></StepBar>
        </StepBarsWrapper>
      </StepsWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BoxWrapper>
          <ActionBtnsWrapper>
            {!importFrom && false && (
              <>
                <ButtonDefault
                  onClick={() => setImport("Calendly")}
                  type="button"
                >
                  Import from Calendly
                </ButtonDefault>
                <ButtonDefault
                  onClick={() => setImport("SavvyCal")}
                  type="button"
                >
                  Import from SavvyCal
                </ButtonDefault>
              </>
            )}
            {importFrom && false && (
              <>
                <FormGroup>
                  <Title>{importFrom}</Title>
                  <LabelHeading variant="caption">Access Token</LabelHeading>
                  <FormField
                    fullWidth
                    {...register("access_token")}
                    hiddenLabel
                  />
                  <ButtonPrimary type="button">Import</ButtonPrimary>
                </FormGroup>
              </>
            )}
          </ActionBtnsWrapper>
          {/* <SeperatorOr>
            <span>or</span>
          </SeperatorOr> */}

          <FormGroup>
            <ProfileWrapper>
              <PicWrapper>
                {universalData ? (
                  <img
                    src={
                      universalData?.profileImage ||
                      `https://ui-avatars.com/api/?name=${universalData?.name?.replaceAll(
                        " ",
                        "+"
                      )}&background=random`
                    }
                    alt="profile"
                  />
                ) : (
                  <UserIcon />
                )}
              </PicWrapper>
              <ButtonPrimary
                className="btn-edit"
                onClick={() => setEditProfile(true)}
              >
                Edit Profile
              </ButtonPrimary>
            </ProfileWrapper>
            <LabelHeading variant="caption">Username</LabelHeading>
            <CaptionWrapper>
              <label htmlFor="username" className="label-caption">
                https://meetings.6ix.com/
              </label>
              <FormField
                id="username"
                {...register("username", {
                  required: { value: true, message: "username is required" },
                })}
                hiddenLabel
              />
            </CaptionWrapper>
          </FormGroup>
          <FormGroup>
            <LabelHeading variant="caption">
              Default URL for meetings
            </LabelHeading>
            <FormField
              id="meetroom"
              {...register("meetroom", {
                required: { value: true, message: "Meetroom is required" },
              })}
              hiddenLabel
            />
            {errors.meetroom && (
              <ErrorText variant="caption">{errors.meetroom.message}</ErrorText>
            )}
          </FormGroup>

          {/* <FormGroup>
            <LabelHeading
              variant="caption"
            >
              Full name
            </LabelHeading>
            <FormField {...register('fullName', { required: { value: true, message: "full name is required" } })} hiddenLabel />
            {errors.fullName &&
              <ErrorText variant="caption">{errors.fullName.message}</ErrorText>
            }
          </FormGroup> */}

          {/* <FormGroup>
            <UserForm data={userData} />
          </FormGroup> */}
          <FormGroup>
            <LabelHeading variant="caption">Timezone</LabelHeading>
            <FormSelectField className="timezone">
              <Controller
                control={control}
                defaultValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
                name="timezone"
                render={({ field: { onChange, value } }) => (
                  <TimezoneSelect
                    className="timezone-select-container"
                    classNamePrefix="timezone"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormSelectField>
            <TextNote variant="caption">
              {watch("timezone") &&
                "Current Time " +
                  getCurrentTimeInTimezone(
                    watch("timezone").value || watch("timezone").timezone
                  )}
            </TextNote>
          </FormGroup>
          <ButtonPrimary disabled={!isEmpty(errors) || loading} type="submit">
            CONTINUE
          </ButtonPrimary>
        </BoxWrapper>
      </form>
      <Modal open={editProfile} onClose={() => setEditProfile(false)}>
        <EventProfileTabpanel onClose={() => setEditProfile(false)} />
      </Modal>
    </Wrapper>
  );
}
