import { styled, Box, Typography, Divider, TextField, Button, TableContainer, FormControl, Tabs, Tab, FormControlLabel, Switch, Menu, DialogContent, Dialog, Link } from '@mui/material';

export const Wrapper = styled('div')`
  
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  line-height: 1.5;
  font-size: 16px;
  color: #e9e9e9;

  @media (min-width: 768px){
    font-size: 20px;
  }
`;

export const TextPara = styled(Typography)`
  font-size: 14px;
  color: #8c8e95;
`;

export const BoxHeading = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  color: #e9e9e9;
`;

export const CustomDivider = styled(Divider)`
  margin-bottom: 24px;
  margin-top: 24px;
  border-color: #373737;
`;

export const HeadingWrapper = styled(Box)`
  margin-bottom: 36px;

  @media (min-width: 576px){
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const TitlesWrapper = styled(Box)`
  flex-grow: 1;
`;

export const ButtonWrapper = styled(Box)`
  margin-left: auto;
  flex-shrink: 0;
  margin-top: 24px;

  @media (min-width: 576px){
    margin-top: 0;
  }
`;

export const ButtonPrimary = styled(Button)`
  min-width: 1px;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 13px 20px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }
`;

export const ErrorText = styled(Typography)`
  font-size: 12px;
  line-height: 1;
  color: #E64135;
  display: block;
  margin-top: 4px;
`;

export const ListingsWrapper = styled(Box)`
  
`;

export const ListItem = styled(Box)`
  border: 1px solid #373737;
  background-color: #1F1F1F;
  border-radius: 8px;
  position: relative;
  padding: 14px;

  @media (min-width: 576px){
    padding: 24px;
  }
  @media (min-width: 992px){
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  + div{
    margin-top: 16px;
  }
`;

export const DescriptionWrapper = styled(Box)`
  flex-grow: 1;
`;

export const ItemHeading = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height 22px;
  color: #E9E9E9;
`;

export const Subtitle = styled(Typography)`
  color: #8C8E95;
  font-size: 14px;
  margin-left: 8px;
`;

export const Subheading = styled(Typography)`
  color: #8C8E95;
  font-size: 14px;
  line-height: 18px;
  display: block;
  margin-top: 10px;

  .MuiSvgIcon-root{
    width: 20px;
    height: 20px;
    vertical-align: -0.2em;
    margin-right: 8px;
  }
`;

export const ActionsWrapper = styled(Box)`
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 6px;
  margin-top: 10px;

  @media (min-with: 992px){
    margin-top: 0;
    max-width: 40%;
  }
  @media (min-width: 1200px){
    gap: 12px;
  }
`;

export const SwitchEnabler = styled(Switch)`
  padding: 0;
  width: 40px;
  height: 24px;

  .MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked{
    color: #fff;
    transform: translateX(17px);

    + .MuiSwitch-track{
      background-color: #342F1D !important;
      border-color: #E3C050;
      opacity: 1 !important;
    }

    .MuiSwitch-thumb{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABySURBVHgBhc3BCcAgDAVQQ8dQoUsIHh2hI7hJ7SYdpb3qoUvoHDYRBClWA0n+4ZEAm5RSytKGEdJa7zlnN4Qtwn1APR1COP+Q994tUsoHADbOOUsp3T1EmSC9N4iNEMJgtl9UYIzxqhh77aECabS4h6he7xlKGxACUUEAAAAASUVORK5CYII=');
      background-size: 10px 8px;
    }
  }

  .MuiSwitch-thumb{
    width: 18px;
    height: 18px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACRSURBVHgBvZBNCsQgDIWtcwzxDt34g6u5WZ3bzDW61cUcQoS5RZoUAvaPdtM+EB6PmHyJEI/KGNOfZZJNCKGXUv6stQNn3vuBsvbTi00p5a+17tBGpZRA/waAiO+Tc/5yXbdGwK5UNE+h4pRS3EVqBAd+icTMjIHsI+PVWsdNW1raOQe4dGzxKNu73qwrZ71fE4jDOaPY3r10AAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 12px 12px;
  },

  .MuiSwitch-track{
    background-color: #373737 !important;
    border: 1px solid #373737;
    border-radius: 99px;
    height: 22px;
    opacity: 1 !important;
  }

  .MuiSwitch-switchBase{
    padding: 2px;
  }
`;

export const Switcher = styled(FormControlLabel)`
  margin-right: 0;
  margin-left: 0;
  
  .MuiFormControlLabel-label{
    margin-left: 0;
    display: none;
  }
`;

export const ButtonIcon = styled(Button)`
  min-width: 1px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #373737;
  color: #C0C0C0;
  background-color: transparent;
  border-radius: 8px;

  &:hover,
  &[aria-expanded="true"]{
    background-color: #000;
    border-color: #E3C050;
    color: #fff;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
  }
`;

export const SwitcherTag = styled(Typography)`
  display: inline-block
  vertical-align: top;
  color: #8C8E95;
  font-size: 14px;
  line-height: 18px;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #2B2B2B;

  @media (min-width: 576px){
    font-size: 16px;
    line-height: 20px;
    margin-right: 8px;
  }
`;

export const ItemMenu = styled(Menu)`

  > .MuiPaper-elevation{
    background-color: #1f1f1f !important;
    border: 1px solid #2B2B2B !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.4) !important;
    margin-top: 0;
    border-radius: 11px;
    min-width: 1px;
    width: 145px;

    .MuiMenu-list{
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      > li{
        padding: 4px;

        &:hover{
          cursor: default;
          background-color: transparent;
        }
      }
    }

    .MuiMenuItem-root{
      color: #fff;
      text-transform: uppercase;
    }
  }
`;

export const DropItemButton = styled(Button)`
  display: block;
  width: 100%;
  color: #C0C0C0;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-transform: none;
  padding: 8px 12px;

  &.delete{
    color: #E64135;

    &:hover{
      background-color: #E64135;
      color: #fff;
    }
  }

  &:hover{
    color: #fff;
    background-color: #2b2b2b;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
    margin-right: 12px;
    vertical-align: -0.15em;
  }
`;

export const DeleteModal = styled(Dialog)`
  box-shadow: none;

  .MuiPaper-elevation{
    border: 1px solid #373737;
    border-radius: 11px;
    background: #1f1f1f;
    width: 100%;
    color: #C0C0C0;

    @media (min-width: 576px){
      width: 320px;
    }
  }

  .MuiModal-backdrop{
    background-color: rgba(19, 19, 19, 0.9);
  }
`;

export const ModalContent = styled(DialogContent)`
  padding: 24px;
  text-align: center;
`;

export const IconWrapper = styled(Box)`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(230, 65, 53, 0.3);
  color: #E64135;
  border-radius: 100%;
  margin: 0 auto 13px;

  .MuiSvgIcon-root{
    width: 22px;
    height: 22px;
  }
`;

export const ModalHeading = styled(Typography)`
  color: #EDEDED;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
`;

export const ModalDescription = styled(Typography)`
  font-size: 14px;
  line-height: 21px;
`;

export const ModalButtons = styled(Box)`
  margin-top: 21px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  > button{
    flex-grow: 1;
    flex-basis: 0;
    min-width: 100%;

    @media (min-width: 576px){
      min-width: calc(50% - 6px);
    }
  }
`;

export const ButtonDefault = styled(Button)`
  background-color: #2B2B2B;
  border: 1px solid #373737;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 13px 20px;
  color: #fff;
  border-radius: 8px;

  &:hover{
    color: #fff;
    background-color: #454648;
    border-color: #e3c050;
  }
`;

export const AddNewModal = styled(Dialog)`
  box-shadow: none;

  .MuiPaper-elevation{
    border: 1px solid #373737;
    border-radius: 11px;
    background: #1f1f1f;
    width: 100%;
    color: #C0C0C0;

    @media (min-width: 576px){
      width: 500px;
    }
  }

  .MuiDialogContent-root{
    text-align: left;
  }

  .MuiModal-backdrop{
    background-color: rgba(19, 19, 19, 0.9);
  }
`;

export const ModalHeader = styled(Box)`
  padding-right: 15px;
  margin-bottom: 24px;
`;

export const FieldsInlineWrapper = styled(Box)`

  @media (min-width: 576px){
    display: flex;
    gap: 12px;
  }
`;

export const FieldTagline = styled(Typography)`
  border-radius: 6px 6px 0 0;
  background-color: #373737;
  padding: 12px;
  color: #8C8E95;
  font-size: 14px;
  line-height: 18px;
  flex-shrink: 0;
  white-space: nowrap;
  display: block;
  margin-bottom: -6px;

  @media (min-width: 576px){
    margin-bottom: 0;
    font-size: 16px;
    border-radius: 6px;
    line-height: 20px;
  }
`;

export const TextAreaField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 120px;
  font-size: 14px;
  line-height: 18px;

  textarea{
    padding: 0;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  > div{
    display: block;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }
`;

export const AddNewHeading = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
  line-heightL 27px;
  color: #EDEDED;
`;

export const AddNewDecription = styled(Typography)`
  color: #8C8E95;
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
`;

export const ButtonModalClose = styled(Button)`
  min-width: 1px;
  background-color: transparent;
  color: #8C8E95;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;

  &:hover{
    background-color: transparent;
    color: #fff;
  }

  .MuiSvgIcon-root{
    width: 12px;
    height: 12px;
  }
`;

export const ModalContentWrapper = styled(Box)`
`;

export const FormGroup = styled(Box)`
  
  + div{
    margin-top: 24px;
  }
`;

export const LabelHeading = styled(Typography)`
  font-size: 13px;
  line-height: 1.5;
  font-weight: 400;
  color: #8C8E95;
  margin-bottom: 6px;
`;

export const FormField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 46px;
  font-size: 16px;
  line-height: 18px;

  input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;

    &[type="number"]{
      -moz-appearance: textfield;
      
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }
`;

export const ContentArea = styled(Box)`
  width: 100%;
  position: relative;
`;

export const DataTableWrapper = styled(Box)`
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 11px;
  /* padding: 12px; */

  &,
  table{
    background-color: #2b2b2b;
  }

  td,
  th{
    border: 0;
    padding: 12px;

    /* &:nth-child(1){
      width: 44px;
    }

    &:nth-child(2){
      width: 36%;
    } */

    &:first-child {
      padding-left: 24px;
      width: 36%;
    }

    &:last-child {
      padding-right: 24px;
    }
  }

  thead {
    th {
      padding-top: 12px;
    }
  }

  tbody{

    td{
      vertical-align: top;
    }
  }
`;

export const ButtonCheckbox = styled(Button)`
  border: 1px solid #8C8E95;
  border-radius: 4px;
  min-width: 1px;
  padding: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background-color: transparent;
  color: #E3C050;

  &.checked{

    .MuiSvgIcon-root{
      opacity: 1;
      border-color: currentColor;
    }
  }

  .MuiSvgIcon-root{
    width: 14px;
    height: 14px;
    opacity: 0;
  }
`;

export const CellHeading = styled(Typography)`
  color: #C0C0C0;
  font-size: 14px;
  line-height: 1;
  display: flex;
  gap: 4px;
  align-items: center;

  &.active{
    color: #fff;

    .MuiSvgIcon-root{
      color: #E3C050;
    }
  }

  .MuiSvgIcon-root{
    width: 16px;
    height: 16px;
  }
`;

export const TitleHeading = styled(Typography)`
  color: #E9E9E9;
  font-size: 16px;
  line-height: 1.2;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LinkText = styled(Link)`
  color: #E3C050;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  position: relative;
  display: inline-flex;
  overflow: hidden;
  max-width: 100%;
  padding-right: 20px;

  .link-holder {
    display: inline-block;
    vertical-align: top;
    text-decoration: underline;
    text-decoration-color: currentColor;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  

  &:hover{
    text-decoration: none;

    .link-holder {
      text-decoration: none;
    }
  }

  .MuiSvgIcon-root{
    width: 16px;
    height: 16px;
    color: #8C8E95;
    vertical-align: -1px;
    position: absolute;
    right: 0;
    top: 1px;
  }
`;

export const DescrText = styled(Typography)`
  color: #8C8E95;
  font-size: 16px;
  line-height: 1.2;
  display: block;
  margin-top: 6px;
`;

export const TextDull = styled(Typography)`
  color: #8C8E95;
  font-size: 16px;
  line-height: 1.2;
`;