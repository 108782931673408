import baseApi from "api/baseApi";
import { CrossIcon } from "components/Icons/CrossIcon";
import { ButtonPrimary, CustomDivider, DescrRow, DescrTag, HeadHeading, HeadIcn, HeadWrap, InfoList, InfoTextDescr, ListDescr, ListTitle, ScheduleSuccessWrapper, TextBold, TextSubtitle, WrapFoot } from "components/RescheduleEventSection/style";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, CircularProgress, ListItem } from "@mui/material";
import { formattedDateString, formattedTime, getCanceledEventDuration, getDuration } from "utils/helper";
import { size } from "lodash";

export const EventCancelation = () => {
  const { eventId } = useParams();
  const [eventData, setEventData] = useState<any>({});
  const [eventError, setEventError] = useState<any>(false);

  useEffect(() => {
    baseApi.post("/api/delete/events/" + eventId).then((res) => {
      setEventData(res?.data?.canceledEvent)
      toast.success("Event Cancelled");
    }).catch((err) => {
      setEventError(true)
      toast.error(err?.response?.data?.error);
    });
  }, [eventId]);
  
  const resetHandler = () => {
    window.location.href = "https://6ix.com"
  };

  if (size(eventData) < 1)
    return (
      <Box
        sx={{
          color: "#E9E9E9",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor: "#2b2b2b",
          zIindex: "8",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );

    if(eventError) {
      return(
        <ScheduleSuccessWrapper sx={{ marginTop: "100px" }}>
          <HeadWrap>
            <HeadIcn className="error">
              <CrossIcon />
            </HeadIcn>
            <HeadHeading
              variant="h3"
            >
              Oops! Something Went Wrong. Please try again.
            </HeadHeading>
          </HeadWrap>
          <CustomDivider />
          <WrapFoot>
            <ButtonPrimary onClick={() => resetHandler()} type="button">Home</ButtonPrimary>
          </WrapFoot>
        </ScheduleSuccessWrapper>
      )
    }
    
  return (
    <ScheduleSuccessWrapper sx={{ marginTop: "100px" }}>
      <HeadWrap>
        <HeadIcn className="error">
          <CrossIcon />
        </HeadIcn>
        <HeadHeading
          variant="h3"
        >
          Your meeting has been cancelled
        </HeadHeading>
      </HeadWrap>
      <CustomDivider />
      <InfoList>
        <ListItem>
          <ListTitle
            variant="caption"
          >
            What
          </ListTitle>
          <ListDescr
          >
            <DescrRow>
              <InfoTextDescr
                variant="body1"
              >
                <strong>{eventData?.title}</strong>
              </InfoTextDescr>
            </DescrRow>
          </ListDescr>
        </ListItem>
        <ListItem>
          <ListTitle
            variant="caption"
          >
            When
          </ListTitle>
          <ListDescr
          >
            <DescrRow>
              <InfoTextDescr
                variant="body1"
              >
                {formattedDateString(eventData?.start)}<br />
								{formattedTime(eventData?.start)} - {` ${getCanceledEventDuration(eventData)} min`}
              </InfoTextDescr>
            </DescrRow>
          </ListDescr>
        </ListItem>
        <ListItem>
						<ListTitle
							variant="caption"
						>
							Who
						</ListTitle>
						<ListDescr
						>
							<DescrRow>
								<InfoTextDescr
									variant="body1"
								>
									<TextBold
										variant="caption"
									>
										{eventData?.creator}
										<DescrTag
											className="warning"
											variant="caption"
										>
											Host
										</DescrTag>
									</TextBold>
									<TextSubtitle
										variant="caption"
									>
										Reason for cancellation (optional)
									</TextSubtitle>
								</InfoTextDescr>
							</DescrRow>
							<DescrRow>
								<InfoTextDescr
									variant="body1"
								>
									{eventData?.attendees?.map((item: any) =>
										<TextBold
											variant="caption"
										>

											{item.email}
										</TextBold>
									)}
								</InfoTextDescr>
							</DescrRow>
						</ListDescr>
					</ListItem>
      </InfoList>
      <CustomDivider />
      <WrapFoot>
        <ButtonPrimary onClick={() => resetHandler()} type="button">Done</ButtonPrimary>
      </WrapFoot>
    </ScheduleSuccessWrapper>
  );
};
