import { Typography } from "@mui/material";
import { WelcomePageWrapper, AlignWrapper, Align } from "./style";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export const PageNotFound = () => {
    return (
        <div className="sixmeetings-content">
            <div className="sixmeetings-adss">
                <WelcomePageWrapper>
                    <AlignWrapper>
                        <Align>
                            <Typography variant="h1">
                                <ErrorOutlineOutlinedIcon className="icon" />
                                Page Not found
                            </Typography>
                        </Align>
                    </AlignWrapper>
                </WelcomePageWrapper>
            </div>
        </div>
    )
}