import { Box } from "@mui/material";
import React, { useContext } from "react";

export default function ComingSoon({ textAlign='center' }: any) {
  return (
    <Box sx={{
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: textAlign,
      background: "rgba(31, 31, 31, 0.7)",
      fontSize: "20px",
      lineHeight: 1.2,
      fontWeight: "bold",
      zIndex: 3,
      color: "white",
      padding: "10px"
    }}>
      Coming Soon
    </Box>
  )
};
