import { styled } from "@mui/material";
import { Description, Heading, Wrapper, HeadingWrapper, StepBar, StepBarsWrapper, StepsWrapper, Title, BoxWrapper, ButtonPrimary, StepBackWrapper, ButtonLink, LeftWrapper, ButtonUploader, UploadWrapper, PicWrapper, LabelText, FormGroup, LabelHeading, TextAreaField, TextNote, ErrorText } from "./style";
import { UserIcon } from "components/Icons/UserIcon";
import { DownloadPresentationIcon } from "components/Icons/DownloadPresentationIcon";
import { UploadIcon } from "components/Icons/UploadIcon";
import { useForm, useFormContext } from "react-hook-form";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { toast } from "react-toastify";
import baseApi from "api/baseApi";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "context";

export default function LoginStepFour({ setStep }: any) {
  const navigate = useNavigate();
  const { universalData } = useContext(AppContext);
  const { register, control, formState: { errors }, handleSubmit } = useForm();

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const onSubmit = (data: any) => {
    baseApi.post('/api/adduserprofile', {
      userId: universalData?.userId,
      orgId: universalData?.org.id,
      ...data
    }).then((res) => {
      toast.success(res?.data?.message)
      navigate('/types')
    }).catch((err) => toast.error(err?.response?.data?.error))
  }

  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading
          variant="h1"
        >
          Nearly there
        </Heading>
        <Description
          variant="body1"
        >
          Last thing, a brief description about you and a photo really help you get bookings and let people know who they’re booking with.
        </Description>
      </HeadingWrapper>
      <StepsWrapper>
        <Title
          variant="h2"
        >
          Step 4 of 4
        </Title>
        <StepBarsWrapper>
          <StepBar className="active"></StepBar>
          <StepBar className="active"></StepBar>
          <StepBar className="active"></StepBar>
          <StepBar className="active"></StepBar>
        </StepBarsWrapper>
      </StepsWrapper>
      <BoxWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <UploadWrapper>
              <ButtonUploader
                role={undefined}
                variant="contained"
                tabIndex={-1}
                sx={{
                  "&:active, &:hover, &:focus": {
                    boxShadow: "none",
                    background: "none"
                  },
                  ".MuiTouchRipple-root" : {
                    display: "none"
                  }
                }}
              // startIcon={<CloudUploadIcon />}
              >
                <VisuallyHiddenInput type="file" />
                <PicWrapper>
                  {universalData ? (
                    <img
                      src={
                        universalData?.profileImage ||
                        `https://ui-avatars.com/api/?name=${universalData?.name?.replaceAll(
                          " ",
                          "+"
                        )}&background=random`
                      }
                      alt="profile"
                    />
                  ) : (
                    <UserIcon />
                  )}
                </PicWrapper>
                <LabelText
                  variant="caption"
                >
                  Add profile photo
                  <UploadIcon />
                </LabelText>
              </ButtonUploader>
            </UploadWrapper>
          </FormGroup>
          <FormGroup sx={{
              ".Mui-focused .MuiOutlinedInput-notchedOutline" : {
                left: "0 !important",
                width: "100% !important"
              }
            }}>
            <LabelHeading
              variant="caption"
            >
              About
            </LabelHeading>
            <TextAreaField {...register('bio', { required: { value: true, message: "Bio is required" } })} hiddenLabel multiline />
            <TextNote
              variant="caption"
            >
              A few sentences about yourself. This will appear on your personal url page.
            </TextNote>
            {errors.bio &&
              <ErrorText variant="caption">{errors.bio.message}</ErrorText>
            }
          </FormGroup>
          <ButtonPrimary type="submit">FINISH</ButtonPrimary>
        </form>
      </BoxWrapper>
      {/* <StepBackWrapper>
        <LeftWrapper>
          <ButtonLink onClick={() => navigate('/login-step-3')} type="button">
            Prev step
          </ButtonLink>
        </LeftWrapper>
      </StepBackWrapper> */}
    </Wrapper>
  )
}