import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LinkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M7.2002 9.54494C7.45908 9.90436 7.78938 10.2018 8.16868 10.417C8.54798 10.6322 8.96741 10.7601 9.39853 10.7922C9.82964 10.8243 10.2623 10.7597 10.6673 10.6028C11.0723 10.4459 11.44 10.2004 11.7456 9.88299L13.5541 8.0049C14.1031 7.41455 14.4069 6.62386 14.4001 5.80315C14.3932 4.98243 14.0762 4.19735 13.5174 3.617C12.9585 3.03664 12.2025 2.70745 11.4122 2.70031C10.6219 2.69318 9.86051 3.00868 9.29203 3.57887L8.25516 4.64938" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M9.90019 8.31598C9.64131 7.99649 9.31101 7.73214 8.93171 7.54085C8.55241 7.34956 8.13298 7.2358 7.70187 7.2073C7.27075 7.1788 6.83804 7.23622 6.43308 7.37567C6.02812 7.51511 5.66039 7.73332 5.35482 8.01549L3.54631 9.6849C2.99725 10.2097 2.69344 10.9125 2.70031 11.642C2.70718 12.3715 3.02417 13.0694 3.58303 13.5853C4.14188 14.1011 4.89787 14.3938 5.68818 14.4001C6.47849 14.4064 7.23988 14.126 7.80836 13.6192L8.8392 12.6676" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};