import React from "react";
import { Link } from "react-router-dom";
import Flex from "components/FlexWrapper";
import PageFooterLanguage from "./PageFooterLanguage";

const PageFooterMenuCMS = () => {
  return (
    <Flex className="meeting6ix-layout-footer-cmsmenu">
      <Flex className="meeting6ix-layout-footer-cmsmenu-inner">
        <div className="meeting6ix-layout-footer-cmsmenu-link">
          <a
            className="meeting6ix-layout-footer-cmsmenu-link-btn"
            href="https://6ix.com/privacy-policy"
          >
            Privacy Policy
          </a>
        </div>
        <div className="meeting6ix-layout-footer-cmsmenu-link">
          <a
            className="meeting6ix-layout-footer-cmsmenu-link-btn"
            href="https://6ix.com/terms"
          >
            Terms of Service
          </a>
        </div>
        <div className="meeting6ix-layout-footer-cmsmenu-link">
          <PageFooterLanguage />
        </div>
      </Flex>
    </Flex>
  );
};

export default PageFooterMenuCMS;
