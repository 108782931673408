import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DragMoveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="4.5" y="1.8" width="2.7" height="2.7" rx="0.9" fill="currentColor"/>
      <rect x="10.8" y="1.8" width="2.7" height="2.7" rx="0.9" fill="currentColor"/>
      <rect x="4.5" y="7.2" width="2.7" height="2.7" rx="0.9" fill="currentColor"/>
      <rect x="10.8" y="7.2" width="2.7" height="2.7" rx="0.9" fill="currentColor"/>
      <rect x="4.5" y="12.6" width="2.7" height="2.7" rx="0.9" fill="currentColor"/>
      <rect x="10.8" y="12.6" width="2.7" height="2.7" rx="0.9" fill="currentColor"/>
    </SvgIcon>
  );
};