import { styled, Box, Typography, Divider, Button, TextField, FormControl } from '@mui/material';

export const Wrapper = styled('div')`
  color: #C0C0C0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
`;

export const HeadingWrapper = styled(Box)`
  margin-bottom: 36px;
  text-align: center;
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 12px;
  color: #fff;
`;

export const Description = styled(Typography)`
  font-size: 14px;
  line-height: 1.5;

  @media (min-width: 768px){
    font-size: 16px;
  }
`;

export const Title = styled(Typography)`
  font-size: 18px;
  line-height: 22px;
  display: block;
  color: #E9E9E9;
`;

export const StepsWrapper = styled(Box)`
  margin-bottom: 36px;
`;

export const StepBarsWrapper = styled(Box)`
  margin-top: 16px;
  display: flex;
  gap: 12px;
`;

export const StepBar = styled(Box)`
  flex-grow: 1;
  flex-basis: 0;
  height: 8px;
  border-radius: 99px;
  background-color: #2B2B2B;

  &.active{
    background-color: #E2BF78;
  }
`;

export const BoxWrapper = styled(Box)`
  border: 1px solid #373737;
  border-radius: 11px;
  background-color: #1f1f1f;
  padding: 20px;
`;

export const ButtonPrimary = styled(Button)`
  margin-top: 32px;
  display: block;
  min-width: 1px;
  width: 100%;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 13px 20px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;

export const ConntectionsWrpper = styled(Box)`
  
`;

export const ItemRow = styled(Box)`
  background-color: #2B2B2B;
  border: 1px solid #373737;
  border-radius: 8px;
  padding: 11px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;

  @media (min-width: 576px){
    flex-wrap: nowrap;
  }

  + div{
    margin-top: 24px;
  }
`;

export const IconWrapper = styled(Box)`
  width: 100%;

  @media (min-width: 576px){
    flex-shrink: 0;
    width: 40px;
  }

  img{
    height: auto;
    max-width: 100%;
    vertical-align: top;
  }
`;

export const TextWrapper = styled(Box)`
  flex-grow: 1;
`;

export const ItemHeading = styled(Typography)`
  font-weight: 700;
  color: #EDEDED;
  font-size: 14px;
  line-height: 19px;
`;

export const ItemDescription = styled(Typography)`
  color: #8C8E95;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
`;

export const ButtonWrapper = styled(Box)`
  flex-shrink: 0;
`;

export const ButtonDefault = styled(Button)`
  display: block;
  min-width: 1px;
  width: 100%;
  background-color: transparent;
  color: #C0C0C0;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #373737;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 8px 20px;

  &:hover{
    background-color: #000;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;

export const StepBackWrapper = styled(Box)`
  display: flex;
  gap: 10px;
  margin-top: 36px;
`;

export const LeftWrapper = styled(Box)`
  margin-right: auto;
`;

export const RightWrapper = styled(Box)`
  margin-left: auto;
`;

export const ButtonLink = styled(Button)`
  min-width: 1px;
  background-color: transparent;
  color: #8C8E95;
  text-transform: none;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding: 0;

  &:hover{
    background-color: transparent;
    color: #E9E9E9;
    border-color: transparent;
  }
`;