import {
  AddNewDecription,
  AddNewHeading,
  AddNewModal,
  ButtonDefault,
  ButtonIcon,
  ButtonModalClose,
  ButtonPrimary,
  ButtonWrapper,
  DeleteModal,
  DescriptionWrapper,
  DropItemButton,
  FieldTagline,
  FieldUnitDropdown,
  FieldUnitGroup,
  FieldsInlineWrapper,
  FormField,
  FormGroup,
  Heading,
  HeadingWrapper,
  IconWrapper,
  ItemHeading,
  ItemMenu,
  LabelHeading,
  ListItem,
  ListingsWrapper,
  ModalButtons,
  ModalContent,
  ModalContentWrapper,
  ModalDescription,
  ModalHeader,
  ModalHeading,
  Subheading,
  Subtitle,
  SwitchEnabler,
  SwitchWrapper,
  Switcher,
  SwitcherTag,
  TextAreaField,
  TextPara,
  TitlesWrapper,
  UnitDropdown,
  Wrapper,
  UnitDropButton,
  UnitDropItemButton,
  ErrorText,
  Dropdownmenu,
} from "../EventTypesTabpanel/style";
import React, { useContext, useEffect, useState } from "react";
import { ArrowRightIcon } from "components/Icons/ArrowRightIcon";
import { TimeClockIcon } from "components/Icons/TimeClockIcon";
import { LinkIcon } from "components/Icons/LinkIcon";
import { EyeViewIcon } from "components/Icons/EyeViewIcon";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  MenuItem,
  MenuList,
  TextField,
  duration,
} from "@mui/material";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { CrossIcon } from "components/Icons/CrossIcon";
import { UpDownFilledIcon } from "components/Icons/UpDownFilledIcon";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Controller, useForm } from "react-hook-form";
import { ChipDeleteIcon } from "components/Icons/ChipDeleteIcon";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AppContext } from "context";

export const EventCreatePage = ({ data, onSubmit, loading }: any) => {
  const naviagte = useNavigate();
  const [booker, setBooker] = useState(false);
  const { userInfo, universalData, member } = useContext(AppContext);
  const [user] = useAuthState(auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: formSetValue,
    watch,
    reset,
    control,
  } = useForm({
    defaultValues: {
      durationType: "min",
    } as any,
  });

  const [anchorUnitDropEl, setAnchorUnitDropEl] = useState(null);
  const handleUnitDropClick = (event: any) => {
    setAnchorUnitDropEl(event.currentTarget);
  };
  const handleUnitDropClose = () => {
    setAnchorUnitDropEl(null);
  };

  // useEffect(() => {
  // 	if (userInfo) {
  // 		reset({
  // 			username: userInfo?.username
  // 		})
  // 	}
  // }, [userInfo])

  useEffect(() => {
    if (data) reset(data);
    else reset({});
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        <AddNewHeading variant="h2">Add a new meeting type</AddNewHeading>
        <AddNewDecription variant="body1">
          Create a new meeting type for people to book times with.
        </AddNewDecription>
      </ModalHeader>
      <ModalContentWrapper>
        <FormGroup>
          <LabelHeading variant="caption">Title</LabelHeading>
          <FormField
            {...register("title", {
              required: { value: true, message: "Title is required" },
            })}
            onChange={(e) =>
              formSetValue(
                "pageName",
                e.target.value.replaceAll(" ", "-")
              )
            }
            hiddenLabel
            placeholder="Quick chat"
          />
          {errors.title && (
            <ErrorText variant="caption">{errors.title.message}</ErrorText>
          )}
        </FormGroup>
        <FormGroup>
          <LabelHeading variant="caption">Page Name</LabelHeading>
          <FieldsInlineWrapper>
            <FieldTagline variant="caption">
              https://meetings.6ix.com/{userInfo?.username}
            </FieldTagline>
            <FormField
              {...register("pageName", {
                required: { value: true, message: "Page Name is required" },
              })}
              hiddenLabel
            />
          </FieldsInlineWrapper>
          {errors.pageName && (
            <ErrorText variant="caption">{errors.pageName.message}</ErrorText>
          )}
        </FormGroup>
        <FormGroup>
          <LabelHeading variant="caption">
            Default URL for meetings
          </LabelHeading>
          <FormField
            id="meetroom"
            {...register("meetroom", {
              required: { value: true, message: "Meetroom is required" },
            })}
            hiddenLabel
          />
          {errors.meetroom && (
            <ErrorText variant="caption">{errors.meetroom.message}</ErrorText>
          )}
        </FormGroup>
        <FormGroup className="text-area">
          <LabelHeading variant="caption">Description</LabelHeading>
          <TextAreaField
            {...register("description", {
              required: { value: true, message: "Description is required" },
            })}
            hiddenLabel
            multiline
            placeholder="A quick video meeting."
          />
          {errors.description && (
            <ErrorText variant="caption">
              {errors.description.message}
            </ErrorText>
          )}
        </FormGroup>

        <FormGroup>
          <LabelHeading variant="caption">Duration</LabelHeading>
          <FieldUnitGroup>
            <FormField
              {...register("duration", {
                required: { value: true, message: "Duration is required" },
              })}
              hiddenLabel
              type="number"
              defaultValue={15}
            />
            {errors.duration && (
              <ErrorText variant="caption">{errors.duration.message}</ErrorText>
            )}
            <FieldUnitDropdown>
              <UnitDropButton
                type="button"
                id={"event-type-dropdown-units"}
                aria-controls={"event-type-dropdown-menu-units"}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorUnitDropEl)}
                onClick={() => null}
              >
                <UpDownFilledIcon />
                {watch("durationType") === "hour" ? "Hour" : "Minutes"}
              </UnitDropButton>
              <UnitDropdown
                id="event-type-dropdown-menu-units"
                anchorEl={anchorUnitDropEl}
                open={Boolean(anchorUnitDropEl)}
                onClose={handleUnitDropClose}
              >
                <MenuItem onClick={handleUnitDropClose}>
                  {/* <UnitDropItemButton
                    onClick={() => formSetValue("durationType", "hour")}
                    type="button"
                  >
                    Hours
                  </UnitDropItemButton> */}
                </MenuItem>
                <MenuItem onClick={handleUnitDropClose}>
                  <UnitDropItemButton
                    onClick={() => formSetValue("durationType", "min")}
                    type="button"
                  >
                    Minutes
                  </UnitDropItemButton>
                </MenuItem>
              </UnitDropdown>
            </FieldUnitDropdown>
          </FieldUnitGroup>
        </FormGroup>
        {/* <FormGroup>
					<SwitchWrapper>
						<Switcher className="label-no-hide" control={<SwitchEnabler checked={booker} onChange={() => {
							if (booker === true) formSetValue('bookerDurations', [])
							setBooker((prev) => !prev)
						}} />} label="Allow booker to select duration" />
						{booker &&
							<FormGroup>
								<Controller
									control={control}
									name="bookerDurations"
									render={({ field }) => (
										<Autocomplete
											{...field}
											multiple
											id="tags"
											options={[]}
											freeSolo

											renderTags={(value, getTagProps) =>
												watch("bookerDurations").map((tag: string, index: number) => (
													<Chip deleteIcon={<ChipDeleteIcon />} label={tag} {...getTagProps({ index })} />
												))
											}
											renderInput={(params) => (
												<FormField className="in-chip" {...params} />
											)}
											onChange={(e: any, value: any) => {
												const data = value.filter((tag: any) => (tag !== '' && !isNaN(Number(tag))));
												field.onChange(data)
											}}
										/>
									)}
								/>
							</FormGroup>
						}
					</SwitchWrapper>
				</FormGroup> */}
      </ModalContentWrapper>
      <ModalButtons>
        <ButtonPrimary disabled={loading} type="submit">
          {data?._id ? "UPDATE" : "CREATE"}
        </ButtonPrimary>
      </ModalButtons>
    </form>
  );
};
