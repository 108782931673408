import { styled, Box, Typography, Divider, TextField, FormControl, Button } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 24px;
`;

export const FilterCol = styled(Box)`
  width: calc(50% - 5px);
`;

export const SearchFieldWrapper = styled(Box)`
  position: relative;
  max-width: 400px;
`;

export const IconLabel = styled(`span`)`
  position: absolute;
  line-height: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #575757;
  z-index: 1;
  left: 12px;

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
  }
`;

export const FormField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 44px;
  font-size: 16px;
  line-height: 20px;

  input{
    padding: 8px 12px 8px 40px;
    color: #e9e9e9;
    font-size: 16px;
    line-height: 20px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }
`;

export const SelectField = styled(FormControl)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 19px;

  &.no-drop {
    &:after {
      display: none;
    }
  }

  &:after{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(45deg);
    border: solid currentColor;
    border-width: 0 1px 1px 0;
    content: '';
    width: 8px;
    height: 8px;
    margin-top: -2px;
    pointer-events: none;
  }

  .MuiInputBase-input{
    padding: 10px 12px 6px;
    color: #e9e9e9;
    font-size: 16px;
    line-height: 19px;
  }

  input{

    + .MuiSvgIcon-root{
      color: #fff;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }

  .MuiChip-filled{
    background-color: #373737;
    color: #E9E9E9;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;

    .MuiSvgIcon-root{
      width: 12px;
      height: 12px;
      color: #8C8E95;
    }
  }

  .MuiSelect-icon{
    display: none;
  }
`;

export const FormGroup = styled(Box)`
`;

export const LabelHeading = styled(Typography)`
  font-size: 13px;
  line-height: 1.5;
  font-weight: 400;
  color: #8C8E95;
  margin-bottom: 6px;
  display: block;
`;

export const FormColsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

export const FormCol = styled(Box)`
  min-width: 150px;
`;

export const ButtonLink = styled(Button)`
  color: #E9E9E9;
  min-width: 1px;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  text-decoration: underline;
  text-transform: none;
  margin-top: 25px;
  margin-left: 12px;

  &:hover{
    color: #fff;
    background-color: transparent;
    text-decoration: none;
  }
`;