import { styled, Box, Typography, Button, Menu, Link, TextField, FormControlLabel, Switch, FormControl, Divider } from '@mui/material';

export const Wrapper = styled('div')`
  
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  line-height: 1.5;
  font-size: 16px;
  color: #EDEDED;

  @media (min-width: 768px){
    font-size: 20px;
  }

  &.mb-1{
    margin-bottom: 12px;
  }
`;

export const WidgetRow = styled(Box)`
  
  + div{
    margin-top: 24px;
  }
`;

export const WidgetWrapperBox = styled(Box)`
  border: 1px solid #373737;
  background-color: #1F1F1F;
  border-radius: 11px;
  padding: 18px;
  position: relative;

  @media (min-width: 576px){
    padding: 24px;
  }
`;

export const FormGroup = styled(Box)`
  
  + div{
    margin-top: 24px;
  }
`;

export const LabelHeading = styled(Typography)`
  font-size: 13px;
  line-height: 1.5;
  font-weight: 400;
  color: #8C8E95;
  margin-bottom: 6px;
`;

export const FormField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 46px;
  font-size: 16px;
  line-height: 18px;

  input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;

    &[type="number"]{
      -moz-appearance: textfield;
      
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }
`;

export const SwitchWrapper = styled(Box)`

  @media (min-width: 576px){
    flex-shrink: 0;
    margin-top: 9px;
  }
  
  .MuiFormControlLabel-root{
    margin-left: 0;
    margin-right: 0;
  }

  .MuiFormControlLabel-label{
    margin-left: 16px;
    display: block;
    color: #fff;
  }
`;

export const FieldUnitGroup = styled(Box)`
  position: relative;
`;

export const FieldUnitDropdown = styled(Box)`
  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  display: flex;
`;

export const UnitDropButton = styled(Button)`
  min-width: 1px;
  background-color: transparent;
  color: #8C8E95;
  font-size: 16px;
  line-height: 1;
  border: 0;
  margin-top: auto;
  margin-bottom: auto;
  text-transform: none;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover{
    background-color: #2b2b2b;
    color: #fff;
  }

  > .MuiSvgIcon-root{
    width: 16px;
    height: 16px;
    margin-right: 11px;
  }
`;

export const UnitDropdown = styled(Menu)`

  > .MuiPaper-elevation{
    background-color: #1f1f1f !important;
    border: 1px solid #2B2B2B !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.4) !important;
    margin-top: 0;
    border-radius: 11px;
    min-width: 1px;
    width: 145px;

    .MuiMenu-list{
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      > li{
        padding: 4px;

        &:hover{
          cursor: default;
          background-color: transparent;
        }
      }
    }

    .MuiMenuItem-root{
      color: #fff;
      text-transform: uppercase;
    }
  }
`;

export const UnitDropItemButton = styled(Button)`
  min-width: 1px;
  background-color: transparent;
  color: #8C8E95;
  font-size: 16px;
  line-height: 1;
  border: 0;
  text-transform: none;
  display: block;
  width: 100%;
  text-align: left;
  padding: 14px 12px;

  &:hover{
    background-color: #2b2b2b;
    color: #fff;
  }
`;

export const FieldsInlineWrapper = styled(Box)`
  display: flex;
`;

export const FieldTagline = styled(Typography)`
  border-radius: 6px;
  background-color: #373737;
  padding: 12px;
  color: #8C8E95;
  font-size: 16px;
  line-height: 20px;
  flex-shrink: 0;
  white-space: nowrap;
  margin-right: -6px;

  + div{
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const TextAreaField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 120px;
  font-size: 14px;
  line-height: 18px;

  textarea{
    padding: 0;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  > div{
    display: block;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }
`;

export const SwitchEnabler = styled(Switch)`
  padding: 0;
  width: 40px;
  height: 24px;

  .MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked{
    color: #fff;
    transform: translateX(17px);

    + .MuiSwitch-track{
      background-color: #342F1D !important;
      border-color: #E3C050;
      opacity: 1 !important;
    }

    .MuiSwitch-thumb{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABySURBVHgBhc3BCcAgDAVQQ8dQoUsIHh2hI7hJ7SYdpb3qoUvoHDYRBClWA0n+4ZEAm5RSytKGEdJa7zlnN4Qtwn1APR1COP+Q994tUsoHADbOOUsp3T1EmSC9N4iNEMJgtl9UYIzxqhh77aECabS4h6he7xlKGxACUUEAAAAASUVORK5CYII=');
      background-size: 10px 8px;
    }
  }

  .MuiSwitch-thumb{
    width: 18px;
    height: 18px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACRSURBVHgBvZBNCsQgDIWtcwzxDt34g6u5WZ3bzDW61cUcQoS5RZoUAvaPdtM+EB6PmHyJEI/KGNOfZZJNCKGXUv6stQNn3vuBsvbTi00p5a+17tBGpZRA/waAiO+Tc/5yXbdGwK5UNE+h4pRS3EVqBAd+icTMjIHsI+PVWsdNW1raOQe4dGzxKNu73qwrZ71fE4jDOaPY3r10AAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 12px 12px;
  },

  .MuiSwitch-track{
    background-color: #373737 !important;
    border: 1px solid #373737;
    border-radius: 99px;
    height: 22px;
    opacity: 1 !important;
  }

  .MuiSwitch-switchBase{
    padding: 2px;
  }
`;

export const Switcher = styled(FormControlLabel)`
  margin-right: 0;
  margin-left: 0;
  
  .MuiFormControlLabel-label{
    margin-left: 0;
    display: none;
  }
`;

export const FormSelectField = styled(FormControl)`
  color: #575757;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  min-height: 46px;
  font-size: 14px;
  line-height: 18px;

  &:after{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(45deg);
    border: solid currentColor;
    border-width: 0 1px 1px 0;
    content: '';
    width: 8px;
    height: 8px;
    margin-top: -2px;
    pointer-events: none;
  }

  &.timezone{

> div,
> div > div[class*="control"]{
  min-height: 42px;
  height: auto;

  &:focus {
    box-shadow: 0 0 0 1px #E3C050 !important;
  }
}

> div > div[class*="css-t3"] {
  box-shadow: 0 0 0 1px #E3C050 !important;
}

> div > div[class*="control"]{
  background-color: transparent;
  color: inherit;
  border: 0;

  &:focus {
    box-shadow: 0 0 0 1px #E3C050 !important;
  }
}

[class*="IndicatorsContainer"]{
  display: none;
}

[class*="-menu"]{
  background-color: #131313;
  border: 1px solid #373737;
  margin-top: 0;

  > * > *{

    &:hover{
      background-color: #454648;
      color: #fff;
    }
  }
  }
}

  .MuiInputBase-input{
    padding: 8px;
    color: #575757;
    font-size: 14px;
    line-height: 28px;
  }

  input{

    + .MuiSvgIcon-root{
      color: #fff;
      top: 10px;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }

  .MuiSelect-icon{
    display: none;
  }
`;

export const TextNote = styled(Typography)`
  font-size: 13px;
  line-height: 15px;
  display: block;
  color: #8C8E95;
  margin-top: 8px;
`;

export const ScheduleWrapper = styled(Box)`
`;

export const TimingRow = styled(Box)`

  @media (min-width: 768px){
    display: flex;
  }

  + div{
    margin-top: 24px;
  }
`;

export const Title = styled(Typography)`
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  display: block;
  flex-grow: 1;
  flex-basis: 0;

  &.unavailable{
    text-decoration: line-through;
    color: #8C8E95;
  }
`;

export const TimeCol = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;

  @media (min-width: 768px){
    margin-top: 3px;
    margin-bottom: 3px;
    flex-shrink: 0;
    margin-left: auto;
    width: 232px;
  }
`;

export const StampRow = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TimeSep = styled(Divider)`
  width: 8px;
  border-color: #575757;

  &.hidden{
    opacity: 0;
    visibility: hidden;
  }

  + span{
    text-align: right;
  }
`;

export const TimeText = styled(Typography)`
  display: block;
  min-width: 100px;
  color: #8C8E95;
  font-size: 16px;
  line-height: 20px;
`;

export const LinkPrimary = styled(Button)`
  min-width: 1px;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 12px 20px;
  width: 100%;
  display: block;
  text-align: center;

  &:disabled {
    background-color: #000;
    color: #E9E9E9;
    border-color: #E3C050;
    opacity: 0.5;
  }

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
    margin-left: 8px;
    vertical-align: -0.1em;
  }
`;

export const CustomDivider = styled(Divider)`
  border-color: #373737;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const SelectField = styled(FormControl)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 19px;

  &:after{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(45deg);
    border: solid currentColor;
    border-width: 0 1px 1px 0;
    content: '';
    width: 8px;
    height: 8px;
    margin-top: -2px;
    pointer-events: none;
  }

  .MuiInputBase-input{
    padding: 10px 12px 6px;
    color: #e9e9e9;
    font-size: 16px;
    line-height: 19px;
  }

  input{

    + .MuiSvgIcon-root{
      color: #fff;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }

  .MuiChip-filled{
    background-color: #373737;
    color: #E9E9E9;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;

    .MuiSvgIcon-root{
      width: 12px;
      height: 12px;
      color: #8C8E95;
    }
  }

  .MuiSelect-icon{
    display: none;
  }
`;

export const RangesWrapper = styled(Box)`
`;

export const RangeRow = styled(Box)`
`;

export const RadioSwitcherWrapper = styled(FormControl)`
`;

export const RadiosInlineWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  
  .MuiFormGroup-root{
    gap: 8px;
    flex-direction: row;

    .MuiFormControlLabel-root{
      margin-left: 0;
      margin-right: 0;
    }

    .MuiRadio-root{
      display: none;

      &.Mui-checked{

        + .MuiFormControlLabel-label{
          background-color: #000;
          border-color: #E3C050;
          font-weight: 700;
          color: #fff;
        }
      }
    }

    .MuiFormControlLabel-label{
      display: block;
      background-color: #2B2B2B;
      border-radius: 8px;
      color: #C0C0C0;
      padding: 11px 12px;
      font-size: 16px;
      line-height: 1;
      border: 1px solid transparent;
      transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
      min-width: 40px;
      min-height: 40px;
      text-align: center;

      &:hover{
        border-color: #E3C050;
      }
    }
  }
`;

export const ButtonIcon = styled(Button)`
  min-width: 1px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #373737;
  color: #C0C0C0;
  background-color: transparent;
  border-radius: 8px;

  &.Mui-disabled{
    color: #575757;
  }

  &:hover{
    background-color: #2b2b2b;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
  }
`;

export const TypeText = styled(Typography)`
  display: block;
  color: #C0C0C0;
  font-size: 16px;
  line-height: 20px;
  margin-top: 18px;
`;

export const ButtonDefault = styled(Button)`
  background-color: #131313;
  border: 1px solid #373737;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 10px 16px;
  color: #fff;
  border-radius: 8px;
  text-transform: none;
  gap: 8px;
  margin-top: 18px;

  &:hover{
    color: #fff;
    background-color: #454648;
    border-color: #e3c050;
  }

  .MuiSvgIcon-root{
    width: 20px;
    height: 20px;
    margin-top: -1px;
  }
`;