import LoginStepOne from "components/LoginStepOne";
import { WelcomePageWrapper, AlignWrapper, Align } from "./style";
import { useEffect, useState } from "react";

const LoginPageOne = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [])

  if (loading) return null

  return (
    <>
      <div className="sixmeetings-content">
        <div className="sixmeetings-adss">
          <WelcomePageWrapper>
            <AlignWrapper>
              <Align>
                <LoginStepOne />
                {/* <LoginStepOne /> */}
                {/* <LoginStepTwo /> */}
                {/* <LoginStepThree /> */}
                {/* <LoginStepFour /> */}
              </Align>
            </AlignWrapper>
          </WelcomePageWrapper>
        </div>
      </div>
    </>
  );
};
export default LoginPageOne;