import React from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import PageFooterMenuCMS from "./PageFooterMenuCMS";

const PageFooterBottom = () => {
  return (
    <div className="meeting6ix-layout-footer-bottom">
      <div className="meeting6ix-layout-footer-bottom-text">
        believe in capitalism again
      </div>
      {isMobile ? <PageFooterMenuCMS /> : null}
    </div>
  );
};

export default PageFooterBottom;
