import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const UserAddIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6665 14.5V13.1667C10.6665 12.4594 10.3856 11.7811 9.88546 11.281C9.38536 10.781 8.70708 10.5 7.99984 10.5H3.33317C2.62593 10.5 1.94765 10.781 1.44755 11.281C0.947456 11.7811 0.666504 12.4594 0.666504 13.1667V14.5"
        stroke="#C0C0C0"
        fill="none"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M5.66667 7.83333C7.13943 7.83333 8.33333 6.63943 8.33333 5.16667C8.33333 3.69391 7.13943 2.5 5.66667 2.5C4.19391 2.5 3 3.69391 3 5.16667C3 6.63943 4.19391 7.83333 5.66667 7.83333Z"
        stroke="#C0C0C0"
        fill="none"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M13.3335 5.83325V9.83325"
        stroke="#C0C0C0"
        fill="none"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M15.3335 7.83325H11.3335"
        stroke="#C0C0C0"
        fill="none"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </SvgIcon>
  );
};
