import { ButtonPrimary, ScheduleSuccessWrapper, HeadIcn, HeadWrap, HeadHeading, CustomDivider, InfoList, ListDescr, ListTitle, WrapFoot, DescrRow, InfoTextDescr, TextBold, TextSubtitle, DescrTag } from "./style";
import { ListItem } from "@mui/material";
import { CrossIcon } from "components/Icons/CrossIcon";
import { formattedDateString, formattedTime, getDuration } from "utils/helper";

export const CancelEvent = ({ eventInfo, step, resetHandler }: any) => {

	return (
    <ScheduleSuccessWrapper>
      <HeadWrap>
        <HeadIcn className="error">
          <CrossIcon />
        </HeadIcn>
        <HeadHeading
          variant="h3"
        >
          Your meeting has been cancelled
        </HeadHeading>
      </HeadWrap>
      <CustomDivider />
      <InfoList>
        <ListItem>
          <ListTitle
            variant="caption"
          >
            What
          </ListTitle>
          <ListDescr
          >
            <DescrRow>
              <InfoTextDescr
                variant="body1"
              >
                <strong>{eventInfo?.summary}</strong>
              </InfoTextDescr>
            </DescrRow>
          </ListDescr>
        </ListItem>
        <ListItem>
          <ListTitle
            variant="caption"
          >
            When
          </ListTitle>
          <ListDescr
          >
            <DescrRow>
              <InfoTextDescr
                variant="body1"
              >
                {formattedDateString(eventInfo?.start?.dateTime)}<br />
								{formattedTime(eventInfo?.start?.dateTime)} - {` ${getDuration(eventInfo)} min`}
              </InfoTextDescr>
            </DescrRow>
          </ListDescr>
        </ListItem>
        <ListItem>
						<ListTitle
							variant="caption"
						>
							Who
						</ListTitle>
						<ListDescr
						>
							<DescrRow>
								<InfoTextDescr
									variant="body1"
								>
									<TextBold
										variant="caption"
									>
										{eventInfo?.creator?.email}
										<DescrTag
											className="warning"
											variant="caption"
										>
											Host
										</DescrTag>
									</TextBold>
									<TextSubtitle
										variant="caption"
									>
										Reason for cancellation (optional)
									</TextSubtitle>
								</InfoTextDescr>
							</DescrRow>
							<DescrRow>
								<InfoTextDescr
									variant="body1"
								>
									{eventInfo?.attendees?.map((item: any) =>
										<TextBold
											variant="caption"
										>

											{item.email}
										</TextBold>
									)}
								</InfoTextDescr>
							</DescrRow>
						</ListDescr>
					</ListItem>
      </InfoList>
      <CustomDivider />
      <WrapFoot>
        <ButtonPrimary onClick={() => resetHandler()} type="button">Done</ButtonPrimary>
      </WrapFoot>
    </ScheduleSuccessWrapper>
	)
}