import { styled, Box, Typography, Divider, Button, TextField, FormControl, Switch, FormControlLabel } from '@mui/material';

export const Wrapper = styled('div')`
  color: #C0C0C0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 660px;
`;

export const HeadingWrapper = styled(Box)`
  margin-bottom: 36px;
  text-align: center;
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 12px;
  color: #fff;
`;

export const Description = styled(Typography)`
  font-size: 14px;
  line-height: 1.5;

  @media (min-width: 768px){
    font-size: 16px;
  }
`;

export const ErrorText = styled(Typography)`
  font-size: 12px;
  line-height: 1;
  color: #E64135;
  display: block;
  margin-top: 4px;
`;

export const Title = styled(Typography)`
  font-size: 18px;
  line-height: 22px;
  display: block;
  color: #E9E9E9;
`;

export const StepsWrapper = styled(Box)`
  margin-bottom: 36px;
`;

export const StepBarsWrapper = styled(Box)`
  margin-top: 16px;
  display: flex;
  gap: 12px;
`;

export const StepBar = styled(Box)`
  flex-grow: 1;
  flex-basis: 0;
  height: 8px;
  border-radius: 99px;
  background-color: #2B2B2B;

  &.active{
    background-color: #E2BF78;
  }
`;

export const BoxWrapper = styled(Box)`
  border: 1px solid #373737;
  border-radius: 11px;
  background-color: #1f1f1f;
  padding: 20px;
`;

export const ButtonPrimary = styled(Button)`
  margin-top: 32px;
  display: block;
  min-width: 1px;
  width: 100%;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 13px 20px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;

export const StepBackWrapper = styled(Box)`
  display: flex;
  gap: 10px;
  margin-top: 36px;
`;

export const LeftWrapper = styled(Box)`
  margin-right: auto;
`;

export const RightWrapper = styled(Box)`
  margin-left: auto;
`;

export const ButtonLink = styled(Button)`
  min-width: 1px;
  background-color: transparent;
  color: #8C8E95;
  text-transform: none;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding: 0;

  &:hover{
    background-color: transparent;
    color: #E9E9E9;
    border-color: transparent;
  }
`;

export const FormGroup = styled(Box)`
  
  + div{
    margin-top: 24px;
  }
`;

export const FormAdditionalRow = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 576px){
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
`;

export const AdditionalRow = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  flex-grow: 1;
`;

export const RepeaterWrap = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  flex-grow: 1;
  flex-wrap: wrap;

  @media (min-width: 576px){
    flex-wrap: nowrap;
  }

  &.hidden{
    display: none;
  }
`;

export const FieldsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-grow: 1;
`;

export const SwitchWrapper = styled(Box)`

  @media (min-width: 576px){
    flex-shrink: 0;
    margin-top: 9px;
    width: 143px;
  }
  @media (min-width: 768px){
    width: 183px;
  }
  
  .MuiFormControlLabel-root{
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Switcher = styled(FormControlLabel)`
  
  .MuiFormControlLabel-label{
    margin-left: 16px;
  }
`;

export const SepSign = styled(Box)`
  background-color: #575757;
  height: 1px;
  width: 8px;
  display: block;
  flex-shrink: 0;
`;

export const AdditionalBtns = styled(Box)`
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: center;

  @media (min-width: 576px){
    flex-shrink: 0;
    width: 92px;
    justify-content: flex-start;
  }
`;

export const ButtonIcon = styled(Button)`
  min-width: 1px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #373737;
  color: #C0C0C0;
  background-color: transparent;
  border-radius: 8px;

  &.Mui-disabled{
    color: #575757;
  }

  &:hover{
    background-color: #2b2b2b;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
  }
`;

export const SwitchEnabler = styled(Switch)`
  padding: 0;
  width: 40px;
  height: 24px;

  .MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked{
    color: #fff;
    transform: translateX(17px);

    + .MuiSwitch-track{
      background-color: #342F1D !important;
      border-color: #E3C050;
      opacity: 1 !important;
    }

    .MuiSwitch-thumb{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABySURBVHgBhc3BCcAgDAVQQ8dQoUsIHh2hI7hJ7SYdpb3qoUvoHDYRBClWA0n+4ZEAm5RSytKGEdJa7zlnN4Qtwn1APR1COP+Q994tUsoHADbOOUsp3T1EmSC9N4iNEMJgtl9UYIzxqhh77aECabS4h6he7xlKGxACUUEAAAAASUVORK5CYII=');
      background-size: 10px 8px;
    }
  }

  .MuiSwitch-thumb{
    width: 18px;
    height: 18px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACRSURBVHgBvZBNCsQgDIWtcwzxDt34g6u5WZ3bzDW61cUcQoS5RZoUAvaPdtM+EB6PmHyJEI/KGNOfZZJNCKGXUv6stQNn3vuBsvbTi00p5a+17tBGpZRA/waAiO+Tc/5yXbdGwK5UNE+h4pRS3EVqBAd+icTMjIHsI+PVWsdNW1raOQe4dGzxKNu73qwrZ71fE4jDOaPY3r10AAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 12px 12px;
  },

  .MuiSwitch-track{
    background-color: #373737 !important;
    border: 1px solid #373737;
    border-radius: 99px;
    height: 22px;
    opacity: 1 !important;
  }

  .MuiSwitch-switchBase{
    padding: 2px;
  }
`;

export const FormSelectField = styled(FormControl)`
  color: #E9E9E9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 42px;
  font-size: 14px;
  line-height: 18px;
  flex-grow: 1;

  &:after{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(45deg);
    border: solid currentColor;
    border-width: 0 1px 1px 0;
    content: '';
    width: 8px;
    height: 8px;
    margin-top: -2px;
    pointer-events: none;
  }

  .MuiInputBase-input{
    padding: 8px;
    color: #E9E9E9;
    font-size: 14px;
    line-height: 28px;
  }

  input{

    + .MuiSvgIcon-root{
      color: #fff;
      top: 10px;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }

  .MuiSelect-icon{
    display: none;
  }

  .MuiInputAdornment-positionEnd{

    svg{
      opacity: 0;
    }
  }
`;