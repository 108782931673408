import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Rings } from "react-loader-spinner";
import { signInWithUserEmailLinkVerify } from "../firebase";
import { SESSION_AUTH } from "utils/auth";

let reqCheck = true;
const Login = () => {
  const navigate = useNavigate();
  const { SET_USER_SESSION } = SESSION_AUTH();

  const VerifyLink = async (mode, actionCode, continueUrl) => {
    if (reqCheck && mode) {

      reqCheck = false;
      let email;
      let url;
      let dataReturn;
      try {
        if (continueUrl) {
          url = new URL(continueUrl);
          email = url?.searchParams?.get("ref_l_email");
        }
      } catch (err) {
        email = "";
      }
      switch (mode) {
        case "signIn":
          reqCheck = false;
          const data = await signInWithUserEmailLinkVerify(email);
          if (data) {
            SET_USER_SESSION(data)
          }
          if (url) {
            navigate(url?.pathname || "/");
          } else {
            navigate("/");
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const mode = params.get("mode");
    // Get the one-time code from the query parameter.
    const actionCode = params.get("oobCode");
    // (Optional) Get the continue URL from the query parameter if available.
    const continueUrl = params.get("continueUrl");

    VerifyLink(mode, actionCode, continueUrl);
  });
  return (
    <div>
      <Rings
        height="80"
        width="80"
        color="#fff"
        radius="6"
        wrapperStyle={{}}
        wrapperClass="sixAiLoader"
        visible={true}
        ariaLabel="rings-loading"
      />
    </div>
  );
};
export default Login;

