import { CalendarEmptyIcon } from "components/Icons/CalendarEmptyIcon";
import { BookHeading, BookWidget, BookingsList, BreadSubtitle, ButtonDefault, ButtonsGroup, DescriptionWrap, FirstCol, SecondCol, StatItem, TextList, Wrapper } from "../UpcomingTabpanel/style";
import { CrossIcon } from "components/Icons/CrossIcon";
import { CalendarIcon } from "components/Icons/CalendarIcon";
import UpcomingsEditDropdown from "components/UpcomingsEditDropdown";
import { formattedDateString, formattedTime } from "utils/helper";
import { TimeClockIcon } from "components/Icons/TimeClockIcon";
import { EmptyDescription, EmptyHeading, EmptyWrapper, IcnWrapper } from "./style";

export default function CancelledTabpanel({ bookings }: any) {
  return (
    <Wrapper>

      {bookings?.length > 0 ?
        <BookingsList>
          {bookings?.map((booking: any) =>
            <BookWidget>
              <DescriptionWrap>
                <FirstCol>
                  <StatItem
                    variant="caption"
                  >
                    <CalendarIcon />
                    {formattedDateString(booking.start)}
                  </StatItem>
                  <StatItem
                    variant="caption"
                  >
                    <TimeClockIcon />
                    {formattedTime(booking.start)}
                  </StatItem>
                </FirstCol>
                <SecondCol>
                  <BookHeading
                    variant="h2"
                  >
                    {booking.title}
                    {/* <BreadSubtitle
                    variant="caption"
                  >
                    with Daniel Barankin
                  </BreadSubtitle> */}
                  </BookHeading>
                </SecondCol>
              </DescriptionWrap>
              <ButtonsGroup>
                {/* <ButtonDefault type="button">
                  <CrossIcon />
                  Cancel
                </ButtonDefault> */}
                <UpcomingsEditDropdown label="Edit" id="Upcomng-Edit" />
              </ButtonsGroup>
            </BookWidget>
          )}
          {/* <TextList
            variant="caption"
          >
            No more results
          </TextList> */}
        </BookingsList>
        :
        <EmptyWrapper>
          <IcnWrapper>
            <CalendarEmptyIcon />
          </IcnWrapper>
          <EmptyHeading
            variant="h2"
          >
            You have no cancelled bookings
          </EmptyHeading>
          <EmptyDescription
            variant="body1"
          >
            Your cancelled bookings will show up here.
          </EmptyDescription>
        </EmptyWrapper>
      }
    </Wrapper >
  );
}