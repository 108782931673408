import { styled, Box, Button, Link, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';

export const ButtonDropdownWrapper = styled('div')`

  @media (min-width: 768px){
    margin-right: 0;
  }
  @media (min-width: 992px){
    margin-right: 0;
  }
`;

export const StdMenu = styled(Menu)`

  > .MuiPaper-elevation{
    background-color: #1f1f1f !important;
    border: 1px solid #2B2B2B !important;
    box-shadow: none !important;
    margin-top: 8px;
    border-radius: 11px;
    min-width: 1px;
    width: 230px;

    .MuiMenu-list{
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      > li{
        padding: 0;

        &:hover{
          cursor: default;
          background-color: transparent;
        }
      }
    }

    .MuiMenuItem-root{
      color: #fff;
      text-transform: uppercase;
    }
  }
`;

export const ButtonOpener = styled(Button)`
  background-color: transparent;
  border: 1px solid #373737;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px 16px;
  color: #C0C0C0;
  border-radius: 8px;
  text-transform: none;
  gap: 8px;

  &:hover{
    color: #fff;
    background-color: #454648;
    border-color: #e3c050;
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.3);
  }

  .icn-wrap{
    margin-left: 4px;
    line-height: 0;
    margin-right: -3px;
  }

  .MuiSvgIcon-root{
    width: 16px;
    height: 16px;
  }
`;

export const ButtonItem = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  text-transform: none;
  padding: 11px 21px;
  font-weight: 400;
  text-align: left;
  min-width: 1px;
  gap: 12px;
  border-radius: 0;
  white-space: normal;
  
  &:hover{
    background-color: #2b2b2b;
    color: #fff;
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.3);
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }
`;