import {
  ButtonPrimary,
  ButtonTexture,
  ButtonWrapper,
  ContentArea,
  DecrWrap,
  EntActWrap,
  EntHeading,
  EntrWrapper,
  EntryRow,
  ErrorText,
  FormField,
  FormGroup,
  Heading,
  HeadingWrapper,
  IcnWrapper,
  LabelHeading,
  TabButton,
  TabsetList,
  TextPara,
  TextureWrap,
  TitlesWrapper,
  Wrapper,
} from "./style";
import React, { useContext, useEffect, useState } from "react";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { useForm } from "react-hook-form";
import Icon1 from "../../assets/images/logos_google-calendar.svg";
import Icon2 from "../../assets/images/vscode-icons_file-type-outlook.svg";
import Icon3 from "../../assets/images/calendar.svg";
import Icon4 from "../../assets/images/calendar_10.svg";
import Icon5 from "../../assets/images/crank-shafts-merged.png";
import Icon6 from "../../assets/images/code.png";
import { CopyIcon } from "components/Icons/CopyIcon";
import { AppContext } from "context";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ComingSoon from "components/ComingSoon";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { EyeViewIcon } from "components/Icons/EyeViewIcon";
import { size } from "lodash";

export default function EventSettingsTabpanel({}: any) {
  const [user] = useAuthState(auth);
  const { universalData, userInfo, member } = useContext(AppContext);
  const [meetroomValue, setMeetRoomValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);


  useEffect(() => {
		if (member?.userId) {
			baseApi.get(`/api/currentstage/user/${member?.userId}/?orgId=${universalData?.org.id}`)
				.then((res) => {
					const step = res.data;
          setMeetRoomValue(step.meetroom	)
				})
				.catch((err) => toast.error(err?.response?.data?.error))
		}
	}, [refetch, universalData, member, member?.userId])

  const handleMeetRoomUpdate = () => {
    setLoading(true);
    baseApi
      .post("/api/addusername", {
        userId: universalData?.userId,
        orgId: universalData?.org.id,
        email: universalData?.email,
        meetroom: meetroomValue
      })
      .then((res) => {
        toast.success("Meetroom Updated Successfully");
        setRefetch((prev) => !prev);
      })
      .catch((err) => toast.error(err?.response?.data?.error))
      .finally(() => setLoading(false));
  };

  return (
    <Wrapper>
      <HeadingWrapper>
        <TitlesWrapper>
          <Heading variant="h1">Settings</Heading>
          <TextPara variant="body1">Configure your universal settings </TextPara>
        </TitlesWrapper>
      </HeadingWrapper>
      <ContentArea>
        <EntryRow>
          <Heading variant="h2">Default Meeting Room</Heading>
          <TextPara variant="body1">
            Select your preferred virtual meeting space
          </TextPara>
          
          <EntrWrapper>
            <DecrWrap>
              <TextureWrap>
                <FormGroup>
                  <LabelHeading variant="caption">
                    Enter your meeting URL (include https://) 
                  </LabelHeading>
                  <FormField
                    id="meetroom"
                    value={meetroomValue}
                    onChange={(e: any) => setMeetRoomValue(e.target.value)}
                    hiddenLabel
                  />
                </FormGroup>
              </TextureWrap>
              <EntActWrap>
                <ButtonPrimary className="small" type="button" disabled={!meetroomValue || loading} onClick={handleMeetRoomUpdate}>
                  Update
                </ButtonPrimary>
              </EntActWrap>
            </DecrWrap>
          </EntrWrapper>
        </EntryRow>
      </ContentArea>
    </Wrapper>
  );
}
