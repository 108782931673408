import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const UploadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M12.25 9.25V11.5833C12.25 11.8928 12.1271 12.1895 11.9083 12.4083C11.6895 12.6271 11.3928 12.75 11.0833 12.75H2.91667C2.60725 12.75 2.3105 12.6271 2.09171 12.4083C1.87292 12.1895 1.75 11.8928 1.75 11.5833V9.25" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M9.91634 5.16667L6.99967 2.25L4.08301 5.16667" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M7 2.25V9.25" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};