import dayjs from "dayjs";
import { isObject } from "lodash";

export const dateHelper = (date: string) => {
  const inputDate = new Date(date);
  const options = { month: "short", day: "numeric", year: "numeric" } as any;
  const formattedDate = inputDate.toLocaleDateString("en-US", options);

  return formattedDate;
};

export const role = (data: string) => data.split("_").slice(1).join(" ");

export const tagDelete = (value: any[], index: number) =>
  value.filter((v: any, i: any) => index !== i);

export const usdDollar = (data: any) => {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return USDollar.format(data);
};

export const leadBuilder = (data: any) => ({
  ...data,
  age: data?.age?.join("-") || "18-65",
  positivePhraseMatchKeywords: data?.positivePhraseMatchKeywords || [],
  positiveExactMatchKeywords: data?.positiveExactMatchKeywords || [],
  negativePhraseMatchKeywords: data?.negativePhraseMatchKeyvwords || [],
  negativeExactMatchKeywords: data?.negativeExactMatchKeywords || [],
  campaignStartDate: data?.campaignStartDate?.$d || dayjs(),
  campaignEndDate: data?.campaignEndDate?.$d || dayjs(),
});

// Assuming 'value' represents the selected timezone value

// Arrow function to get the current time in the selected timezone
export const getCurrentTimeInTimezone = (timezone: any) => {
  // Get current time in UTC
  const currentTimeUTC = new Date();
  // Get current time in the selected timezone
  const currentTimeInTimezone = new Date(
    currentTimeUTC.toLocaleString("en-US", { timeZone: timezone })
  );
  // Format the time as desired
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedTime = currentTimeInTimezone.toLocaleTimeString(
    "en-US",
    options as any
  );
  return formattedTime;
};

export const formattedTime = (time: any) => {
  const date = new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero if minutes < 10

  return formattedHours + ":" + formattedMinutes + " " + ampm;
};

export const availablityTimeBuilder = (data: any) => {
  return {
    monday: data?.monday?.map((t: any) => ({
      startTime:
        typeof t.startTime === "string"
          ? t.startTime
          : formattedTime(t.startTime),
      endTime:
        typeof t.endTime === "string" ? t.endTime : formattedTime(t.endTime),
    })),
    tuesday: data?.tuesday?.map((t: any) => ({
      startTime:
        typeof t.startTime === "string"
          ? t.startTime
          : formattedTime(t.startTime),
      endTime:
        typeof t.endTime === "string" ? t.endTime : formattedTime(t.endTime),
    })),
    wednesday: data?.wednesday?.map((t: any) => ({
      startTime:
        typeof t.startTime === "string"
          ? t.startTime
          : formattedTime(t.startTime),
      endTime:
        typeof t.endTime === "string" ? t.endTime : formattedTime(t.endTime),
    })),
    thursday: data?.thursday?.map((t: any) => ({
      startTime:
        typeof t.startTime === "string"
          ? t.startTime
          : formattedTime(t.startTime),
      endTime:
        typeof t.endTime === "string" ? t.endTime : formattedTime(t.endTime),
    })),
    friday: data?.friday?.map((t: any) => ({
      startTime:
        typeof t.startTime === "string"
          ? t.startTime
          : formattedTime(t.startTime),
      endTime:
        typeof t.endTime === "string" ? t.endTime : formattedTime(t.endTime),
    })),
    saturday: data?.saturday?.map((t: any) => ({
      startTime:
        typeof t.startTime === "string"
          ? t.startTime
          : formattedTime(t.startTime),
      endTime:
        typeof t.endTime === "string" ? t.endTime : formattedTime(t.endTime),
    })),
    sunday: data?.sunday?.map((t: any) => ({
      startTime:
        typeof t.startTime === "string"
          ? t.startTime
          : formattedTime(t.startTime),
      endTime:
        typeof t.endTime === "string" ? t.endTime : formattedTime(t.endTime),
    })),
  };
};

export const formattedDateString = (data: any) => {
  const date = dayjs(data);
  const formattedDate = date.locale("en").format("dddd, MMMM D, YYYY");
  return formattedDate;
};


export const formatedDateStringCalender = (data: any) => {
  const date = dayjs(data);
  const formattedDate = date.locale("en").format("ddd D");
  return formattedDate;
};

export const eventURl = (event: any) => {
  const uri = event.url.split("://")[1].split("/");

  // return `/${uri[1]}/${uri[2]}?_id=${event._id}&userId=${event.userId}&orgId=${event.orgId}&title=${event.title}&description=${event.description}&duration=${event.duration}&timezone=${event.timezone}`;

  return `/${uri[1].replaceAll("'", "%27")}/${uri[2].replaceAll("'", "%27")}`
};

export const rescheduleBookingURL = (username: string, booking: any) => {
  return `/reschedule/${username}/${booking?.eventid}/${booking?.uid}/${booking?.orgId}`
}

export const rescheduleURl = (eventInfo: any, event: any) => {

  // return `/reschedule/${eventInfo?.id}/${eventInfo?.uid}/${event?.orgId}/?_id=${event._id}&userId=${event.userId}&orgId=${event.orgId}&title=${event.title}&description=${event.description}&duration=${event.duration}&timezone=${event.timezone}`;
  return `/reschedule/${event.username}/${eventInfo?.id}/${event?.userId}/${event?.orgId}`
};

export const getDuration = (eventInfo: any) => {
  const a = new Date(eventInfo?.start?.dateTime);
  const b = new Date(eventInfo?.end?.dateTime);
  const diff = b.getTime() - a.getTime();

  return Math.floor(diff / 60000);
};

export const getCanceledEventDuration = (eventInfo: any) => {
  const a = new Date(eventInfo?.start);
  const b = new Date(eventInfo?.end);
  const diff = b.getTime() - a.getTime();

  return Math.floor(diff / 60000);
};

export const googleEventHandler = (
  event: any,
  start: any,
  end: any,
  data: any
) => {
  return {
    userId: event?.userId,
    orgId: event?.orgId,
    eventTypeId: event?._id,
    payload: {
      summary: event.title,
      description: event.description,
      start: {
        dateTime: start,
        // timezone: data?.timezone?.value || data?.timezone || "UTC",
      },
      end: {
        dateTime: end,
        // timezone: data?.timezone?.value || data?.timezone || "UTC",
      },
      attendees: [
        { email: data.email },
        ...data.guests.map((guest: any) => ({
          email: guest,
        })),
      ],
      location: "Event Location",
      // conferenceData: {
      //   createRequest: {
      //     requestId: "ednuaeie8w92398233jej",
      //     conferenceSolutionKey: {
      //       type: "hangoutsMeet",
      //     },
      //   },
      // },
      sendNotifications: true,
      reminders: {
        useDefault: false,
        overrides: [
          { method: "popup", minutes: 10 },
          { method: "email", minutes: 60 },
        ],
      },
    },
  };
};

export const outlookEventHandler = (
  event: any,
  start: any,
  end: any,
  data: any
) => {
  return {
    payload: {
      subject: event.title,
      body: {
        contentType: "HTML",
        content: event.description,
      },
      start: {
        dateTime: start,
        // timezone: data?.timezone?.value || data?.timezone || "UTC",
      },
      end: {
        dateTime: end,
        // timezone: data?.timezone?.value || data?.timezone || "UTC",
      },
      location: {
        displayName: "Conference Room 1",
      },
      attendees: [
        { emailAddress: { address: data.email } },
        ...data.guests.map((guest: any) => ({ emailAddress: { address: guest } })),
      ],
      isOnlineMeeting: true,
      onlineMeetingProvider: "teamsForBusiness",
    },
    userId: event?.userId,
    orgId: event?.orgId,
    eventTypeId: event?._id,
  };
};


export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


export const convertDateToTimeZone = (date: any, timezone: string) => {
  if(date &&  timezone) {

    const da = new Date(date)

    const options = {
      timeZone: timezone,
    };

    return da.toLocaleString("en-US", options)
  }

  return null
}