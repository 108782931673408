import {
  ButtonColorPicker,
  ButtonDefault,
  ButtonEdit,
  ButtonPrimary,
  ButtonTexture,
  ButtonWrapper,
  ButtonsWrapper,
  ContentArea,
  CustomDivider,
  DecrWrap,
  EditProfileWidget,
  EntActWrap,
  EntHeading,
  EntrWrapper,
  EntryRow,
  FieldActionBtns,
  FielderWrapper,
  FormField,
  FormGroup,
  FormScrollWrap,
  HalfCol,
  HalfWrapper,
  Heading,
  HeadingWrapper,
  LabelHeading,
  PicWrap,
  TextAreaField,
  TitlesWrapper,
  UploadPicWrapper,
  VisuallyHiddenInput,
  Wrapper,
} from "./style";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useState } from "react";
import { getUserProfile, updateUserProfile } from "../../firebase";
import { useForm } from "react-hook-form";
import { PencilIcon } from "components/Icons/PencilIcon";
import { UserIcon } from "components/Icons/UserIcon";
import { AppContext } from "context";
import { toast } from "react-toastify";
import ImageCropper from "./ImageCropper";
import ColorPicker from "./ColorPicker";
import { PlusIcon } from "components/Icons/PlusIcon";

export default function EventProfileTabpanel({ onClose }: any) {
  const [profileImage, setProfileImage] = useState<any>();
  const [cropProfileImage, setCropProfileImage] = useState<any>();

  const [companyLogo, setCompanyLogo] = useState<any>();
  const [cropCompanyLogo, setCropCompanyLogo] = useState<any>("");

  const [companyLogoColor, setCompanyLogoColor] = useState();

  const { universalData } = useContext(AppContext);

  const onSelectProfileImage = (e: any) => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setProfileImage("");
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropProfileImage(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };

  const onSelectEventLogo = (e: any) => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setCompanyLogo("");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropCompanyLogo(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue: formSetValue,
    watch,
    reset,
  } = useForm({});

  const submit = (data: any) => {
    const profileData = new FormData();
    profileData.append("name", data.name?.trim());
    profileData.append("jobTitle", data.jobTitle?.trim());
    profileData.append("work", data.work?.trim());
    profileData.append("bio", data.bio?.trim());
    profileData.append("companyLogoColor", companyLogoColor || "");
    if (companyLogo?.name) {
      profileData.append("companyLogo", companyLogo, companyLogo.name);
    }
    if (profileImage?.name) {
      profileData.append("profileImage", profileImage, profileImage.name);
    }
    profileData.append("userId", universalData.userId);
    profileData.append("org", universalData?.org?.id);

    updateUserProfile(profileData).then((res) => {
      toast.success(res.data.data.message);
      reset(data);
    });
  };

  useEffect(() => {
    getUserProfile(universalData).then((res) => {
      reset(res.data.data.data);
    });
  }, [reset, universalData]);

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <Wrapper>
      <ContentArea>
        <HeadingWrapper>
          <TitlesWrapper>
            <Heading variant="h1">Edit profile</Heading>
          </TitlesWrapper>
        </HeadingWrapper>
        <form onSubmit={handleSubmit(submit)}>
          <EditProfileWidget>
            <FormScrollWrap>
              <FormGroup>
                <LabelHeading variant="caption">Email</LabelHeading>
                <FormField
                  {...register("email")}
                  className="texture-only"
                  hiddenLabel
                />
              </FormGroup>
              <FormGroup>
                <LabelHeading variant="caption">Name</LabelHeading>
                <FormField
                  {...register("name", { required: true })}
                  hiddenLabel
                />
              </FormGroup>
              <FormGroup>
                <LabelHeading variant="caption">Job title</LabelHeading>
                <FormField {...register("jobTitle")} hiddenLabel />
              </FormGroup>
              <FormGroup>
                <LabelHeading variant="caption">Company</LabelHeading>
                <FormField {...register("work")} hiddenLabel />
              </FormGroup>
              <FormGroup>
                <LabelHeading variant="caption">Bio (Optional)</LabelHeading>
                <TextAreaField {...register("bio")} hiddenLabel />
              </FormGroup>
              <FormGroup>
                <HalfWrapper>
                  <HalfCol>
                    <LabelHeading variant="caption">Profile Image</LabelHeading>
                    <UploadPicWrapper>
                      <Button
                        className="file-upload-btn file-upload-btn-profile"
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<PencilIcon />}
                      >
                        <VisuallyHiddenInput type="file" onChange={(e: any) => onSelectProfileImage(e)} />
                        <PicWrap className="rounded">
                          <img
                            src={profileImage && URL.createObjectURL(profileImage)}
                            width={"52"}
                            height={"52"}
                            alt=""
                          />
                        </PicWrap>
                      </Button>
                    </UploadPicWrapper>
                  </HalfCol>
                  <HalfCol>
                    <LabelHeading variant="caption">Company logo</LabelHeading>
                    <UploadPicWrapper>
                      <Button
                        className="file-upload-btn"
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={!companyLogo ? <></> : <PencilIcon />}
                      >
                        <PicWrap>
                          <img
                            src={companyLogo && URL.createObjectURL(companyLogo)}
                            width={"52"}
                            height={"52"}
                            alt=""
                          />
                          <PlusIcon />
                        </PicWrap>
                        {!companyLogo &&
                          <>Upload</>
                        }
                        <VisuallyHiddenInput type="file" onChange={(e: any) => onSelectEventLogo(e)} />
                      </Button>
                      {companyLogo && (
                        <ColorPicker
                          title="Logo Background Color"
                          selectedColor={companyLogoColor}
                          setSelectedColor={setCompanyLogoColor}
                        />
                      )}
                    </UploadPicWrapper>
                  </HalfCol>
                </HalfWrapper>
              </FormGroup>
            </FormScrollWrap>
            <CustomDivider className="is-transparent" />
            <ButtonsWrapper>
              <ButtonDefault onClick={onClose} type="button">
                CANCEL
              </ButtonDefault>
              <ButtonPrimary type="submit">
                UPDATE
              </ButtonPrimary>
            </ButtonsWrapper>
          </EditProfileWidget>
        </form>
      </ContentArea>

      {cropProfileImage && (
        <ImageCropper
          open={true}
          data={{
            image: cropProfileImage,
            title: "Profile image",
            minWidth: 80,
            minHeight: 80,
            ratio: 1 / 1,
            feature: ["circle", "square", "freedom"],
          }}
          onCancel={() => {
            setCropProfileImage("");
            onSelectProfileImage("");
          }}
          onUpdate={(image: any) => {
            setProfileImage(image);
            setCropProfileImage("");
          }}
        />
      )}

      {cropProfileImage && (
        <ImageCropper
          open={true}
          data={{
            image: cropProfileImage,
            title: "Profile image",
            minWidth: 80,
            minHeight: 80,
            ratio: 1 / 1,
            feature: ["circle", "square", "freedom"],
          }}
          onCancel={() => {
            setCropProfileImage("");
            onSelectProfileImage("");
          }}
          onUpdate={(image: any) => {
            setProfileImage(image);
            setCropProfileImage("");
          }}
        />
      )}

      {cropCompanyLogo && (
        <ImageCropper
          open={true}
          data={{
            image: cropCompanyLogo,
            title: "Company logo",
            minWidth: 60,
            minHeight: 60,
            ratio: 1 / 1,
            feature: ["square", "freedom"],
          }}
          onCancel={() => {
            setCropCompanyLogo("");
            onSelectEventLogo("");
          }}
          onUpdate={(image: any) => {
            setCompanyLogo(image);
            setCropCompanyLogo("");
          }}
        />
      )}
    </Wrapper>
  );
}
