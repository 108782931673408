import { styled, Box } from '@mui/material';

export const PageWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  width: 100%;
  /* background-color: #131313; */
`;

export const AlignWrapper = styled(Box)`
  width: 100%;
  display: flex;
`;

export const Align = styled(Box)`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 107px;
  padding-bottom: 47px;

  @media (min-width: 768px){
    padding-bottom: 77px;
  }
  @media (min-width: 992px){
    padding-bottom: 107px;
  }
`;

export const ContainerWrapper = styled(Box)`
  width: 100%;
  max-width: 1110px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  &:after{
    content: '';
    display: block;
    clear: both;
  }
`;