import { Box, Typography } from "@mui/material";
import CourseDropdown from "components/CourseDropdown";
import { Wrapper, Section, BgWrapper, Heading } from "./style";
import SectionMainHeader from "components/SectionMainHeader";
import ButtonsFooter from "components/ButtonsFooter";
import BgImage from "../../assets/images/bg-img01.jpg";
import LandingVideo from "components/LandingVideo";
import { useContext } from "react";
import { AppContext } from "context";

const IntroSection = ({ pTop, pBottom, bgColor, refferOnTouch }: any) => {

  return (
    <Section
      className="IntroSection"
      sx={{
        backgroundColor: `${bgColor}`,
        paddingTop: `calc(${pTop} * 0.65)`,
        paddingBottom: `calc(${pBottom} * 0.65)`,

        "@media (min-width: 768px)": {
          paddingTop: `calc(${pTop} * 0.8)`,
          paddingBottom: `calc(${pBottom} * 0.8)`,
        },
        "@media (min-width: 1200px)": {
          paddingTop: `calc(${pTop} * 1)`,
          paddingBottom: `calc(${pBottom} * 1)`,
        },
      }}
    >
      <Wrapper>
        <CourseDropdown label="Meetings" id="intro-course" />
        <SectionMainHeader
          size="980px"
          description="Allow prospective investors and existing investors to book 1-on-1 meetings with you."
        >
          <Heading variant="h1">
            Are prospective and existing shareholders{" "}
            <strong>booking meetings with you?</strong>
          </Heading>
        </SectionMainHeader>
        <ButtonsFooter
          primaryLabel="Login"
          refferOnTouch={refferOnTouch}
          secondaryLabel="Get started"
          secondaryLink="/setup"
        />
        <LandingVideo />
      </Wrapper>
      {/* <BgWrapper>
      <img src={BgImage} alt="" />
    </BgWrapper> */}
    </Section>
  );
};
export default IntroSection;
