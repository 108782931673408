import React from "react";
import Flex from "components/FlexWrapper";

const PageFooterAccess = () => {
  return (
    <div className="meeting6ix-layout-footer-access">
      <div className="meeting6ix-layout-footer-access-heading">
        6ix is Your All-Access Pass to the Investment World
      </div>
      <div className="meeting6ix-layout-footer-access-text">
        Invest, attract capital, share expertise – All from one stage
      </div>

      <Flex className="meeting6ix-layout-footer-access-row">
        <Flex className="meeting6ix-layout-footer-access-row-box">
          <span>#1</span> Open your account on 6ix.com
        </Flex>
        <Flex className="meeting6ix-layout-footer-access-row-box">
          <span>#2</span> Invest, attract capital, share expertise – All from
          one stage
        </Flex>
        <Flex className="meeting6ix-layout-footer-access-row-box">
          <span>#3</span> Grow your net worth
        </Flex>
      </Flex>
    </div>
  );
};

export default PageFooterAccess;
