import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const TrippleDotsHorizontalIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.9001 9.00039C9.9001 8.50333 9.49715 8.10039 9.0001 8.10039C8.50304 8.10039 8.1001 8.50333 8.1001 9.00039C8.1001 9.49745 8.50304 9.90039 9.0001 9.90039C9.49715 9.90039 9.9001 9.49745 9.9001 9.00039Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.5998 9.00039C3.5998 8.50333 3.19686 8.10039 2.6998 8.10039C2.20275 8.10039 1.7998 8.50333 1.7998 9.00039C1.7998 9.49745 2.20275 9.90039 2.6998 9.90039C3.19686 9.90039 3.5998 9.49745 3.5998 9.00039Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.1999 9.00039C16.1999 8.50333 15.797 8.10039 15.2999 8.10039C14.8028 8.10039 14.3999 8.50333 14.3999 9.00039C14.3999 9.49745 14.8028 9.90039 15.2999 9.90039C15.797 9.90039 16.1999 9.49745 16.1999 9.00039Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};