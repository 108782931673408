import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M2.69922 4.50031H4.09922H15.2992" stroke="currentColor" strokeWidth="1.77769" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M6.1957 4.49835V3.09849C6.1957 2.72722 6.3432 2.37116 6.60576 2.10863C6.86831 1.84611 7.2244 1.69862 7.59571 1.69862H10.3957C10.767 1.69862 11.1231 1.84611 11.3857 2.10863C11.6482 2.37116 11.7957 2.72722 11.7957 3.09849V4.49835M13.8957 4.49835V14.2974C13.8957 14.6687 13.7482 15.0247 13.4857 15.2873C13.2231 15.5498 12.867 15.6973 12.4957 15.6973H5.4957C5.1244 15.6973 4.7683 15.5498 4.50575 15.2873C4.2432 15.0247 4.0957 14.6687 4.0957 14.2974V4.49835H13.8957Z" stroke="currentColor" strokeWidth="1.77769" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};