import { Navigate, Route, Routes } from "react-router-dom";
import TypesPage from "pages/Types";
import LoginPageOne from "pages/LoginPageOne";
import Reschedule from "pages/Reschedule";
import LoginPageTwo from "pages/LoginPageTwo";
import LoginPageThree from "pages/LoginPageThree";
import LoginPageFour from "pages/LoginPageFour";
import HeaderNew from "components/common/HeaderNew";
import RescheduleEventList from "components/RescheduleEventList";
import { LoginStepHandler } from "components/LoginStepHandler";
import TypesMeeting from "pages/TypesMeeting";
import HomePage from "pages/Home";
import RescheduleBooking from "pages/RescheduleBooking";
import PublicHeader from "components/PublicHeader/HeaderNew";
import { RegsiterUser } from "components/RegisterUser";
import { EventCancelation } from "components/EventCancelation";
import { PageNotFound } from "pages/PageNotFound/PageNotFound";

export const Authorized = () => (
  <Routes>
    <Route element={<RegsiterUser />}>
      <Route element={<HeaderNew />}>
        <Route path="/" element={<HomePage />} />
        <Route element={<LoginStepHandler />}>
          <Route path="/setup" element={<LoginPageOne />} />
          <Route path="/setup/login-step-2" element={<LoginPageTwo />} />
          <Route path="/setup/login-step-3" element={<LoginPageThree />} />
          <Route path="/setup/login-step-4" element={<LoginPageFour />} />
          <Route path="/types" element={<TypesPage />} />
          <Route path="/types/:type" element={<TypesPage />} />
          <Route path="/type-meetings" element={<TypesMeeting />} />
        </Route>
        {/* <Route path="/:username" element={<RescheduleEventList />} />
        <Route path="/:username/:eventName" element={<Reschedule />} />
        <Route
          path="/reschedule/:eventId/:userId/:orgId"
          element={<RescheduleBooking />}
        /> */}
      </Route>
    </Route>
    <Route element={<PublicHeader />}>
      <Route path="/:username" element={<RescheduleEventList />} />
      <Route path="/:username/:eventName" element={<Reschedule />} />
      <Route
        path="/reschedule/:username/:eventId/:userId/:orgId"
        element={<RescheduleBooking />}
      />
      <Route path="/cancel-event/:eventId" element={<EventCancelation />} />
    </Route>

    <Route path="/404" element={<PageNotFound />} />
    <Route path="*" element={<Navigate to={"/404"} />} />
  </Routes>
);
