import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import {
  CustomDivider,
  FieldTagline,
  FieldUnitDropdown,
  FieldUnitGroup,
  FieldsInlineWrapper,
  FormField,
  FormGroup,
  FormSelectField,
  Heading,
  LabelHeading,
  LinkPrimary,
  RadioSwitcherWrapper,
  RangeRow,
  RangesWrapper,
  ScheduleWrapper,
  SelectField,
  StampRow,
  SwitchEnabler,
  SwitchWrapper,
  Switcher,
  TextAreaField,
  TextNote,
  TimeCol,
  TimeSep,
  TimeText,
  TimingRow,
  Title,
  UnitDropButton,
  UnitDropItemButton,
  UnitDropdown,
  WidgetRow,
  WidgetWrapperBox,
  Wrapper,
} from "./style";
import { useContext, useEffect, useState } from "react";
import { UpDownFilledIcon } from "components/Icons/UpDownFilledIcon";
import { ExternalLinkIcon } from "components/Icons/ExternalLinkIcon";
import { Controller, useForm } from "react-hook-form";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { isEmpty } from "lodash";
import AvailabilityForm from "components/AvailabilityForm";
import { toast } from "react-toastify";
import { availablityTimeBuilder, formattedTime } from "utils/helper";
import { FidgetSpinner } from "react-loader-spinner";
import { AppContext } from "context";
import TimezoneSelect from "react-timezone-select";

export default function EditAvailablity({
  hideTitle = false,
}: {
  hideTitle?: boolean;
}) {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [edit, setEdit] = useState(false);
  const { universalData, member } = useContext(AppContext);
  const [timezone, setTimezone] = useState<any>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const onSubmit = (data: any) => {
    setLoading(true);
    baseApi
      .post("/api/updateavailability", {
        user_id: member?.userId,
        orgId: universalData?.org.id,
        availability: availablityTimeBuilder(data),
        timezone: timezone,
      })
      .then((res) => {
        toast.success(res?.data?.message);
        setEdit(false);
        setRefetch((prev) => !prev);
      })
      .catch((err) => toast.error(err?.response?.data?.error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setData(null);
    setFetching(true);
    baseApi
      .post("/api/getavailability", {
        user_id: member?.userId,
        orgId: universalData?.org.id,
      })
      .then((res) => {
        setData(res.data);
        setTimezone(res.data?.timezone);
      })
      .catch((err) => toast.error(err?.response?.data?.error))
      .finally(() => setFetching(false));
  }, [refetch, universalData?.org.id, member?.userId]);

  return (
    <WidgetRow>
      {fetching || !data ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // backgroundColor: "#2b2b2b",
            zIindex: "8",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          {!hideTitle && (
            <Heading variant="h2" className="mb-1">
              Availability
            </Heading>
          )}
          <WidgetWrapperBox className="avilability-box">
            <ScheduleWrapper>
              {edit ? (
                <AvailabilityForm
                  timezone={timezone}
                  data={data}
                  onSubmit={onSubmit}
                  loading={loading}
                >
                  <FormGroup>
                    <LabelHeading variant="caption">Timezone</LabelHeading>
                    <FormSelectField className="timezone">
                      <TimezoneSelect
                        className="timezone-select-container"
                        classNamePrefix="timezone"
                        value={timezone}
                        isDisabled={!edit}
                        onChange={(e: any) => setTimezone(e.value)}
                      />
                    </FormSelectField>
                  </FormGroup>
                </AvailabilityForm>
              ) : (
                <>
                  {Object.entries(data?.availability || {}).map((day: any) => (
                    <TimingRow>
                      <Title
                        variant="caption"
                        className={(isEmpty(day[1][0]) && "unavailable") || ""}
                      >
                        {day[0][0].toUpperCase() + day[0].slice(1)}
                      </Title>
                      <Box>
                        {isEmpty(day[1][0]) ? (
                          <TimeCol>
                            <StampRow>
                              <TimeText variant="caption">&nbsp;</TimeText>
                              <TimeSep className="hidden" />
                              <TimeText variant="caption">Unavailable</TimeText>
                            </StampRow>
                          </TimeCol>
                        ) : (
                          day[1].map((time: any) => (
                            <TimeCol>
                              <StampRow>
                                <TimeText variant="caption">
                                  {time.startTime}
                                </TimeText>
                                <TimeSep />
                                <TimeText variant="caption">
                                  {time.endTime}
                                </TimeText>
                              </StampRow>
                            </TimeCol>
                          ))
                        )}
                      </Box>
                    </TimingRow>
                  ))}

                  <FormGroup>
                    <LabelHeading variant="caption">Timezone</LabelHeading>
                    <FormSelectField className="timezone">
                      <TimezoneSelect
                        className="timezone-select-container"
                        classNamePrefix="timezone"
                        value={timezone}
                        isDisabled={!edit}
                        onChange={(e: any) => setTimezone(e.value)}
                      />
                    </FormSelectField>
                  </FormGroup>
                  <CustomDivider />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",

                      a: {
                        width: "inherit",
                        cursor: "pointer",
                        minWidth: "30%",
                      },
                    }}
                  >
                    <LinkPrimary
                      onClick={() => setEdit(true)}
                      disabled={member?.userId !== universalData?.userId}
                    >
                      Edit availability
                      <ExternalLinkIcon />
                    </LinkPrimary>
                  </Box>
                </>
              )}
            </ScheduleWrapper>
          </WidgetWrapperBox>
        </>
      )}
    </WidgetRow>
  );
}
