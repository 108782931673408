import EventFiltersHeadBar from "components/EventFiltersHeadBar";
import {
  ButtonCheckbox,
  CellHeading,
  ContentArea,
  DataTableWrapper,
  DescrText,
  HeadingWrapper,
  LinkText,
  TextDull,
  TitleHeading,
  TitlesWrapper,
  Wrapper,
} from "./style";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  CircularProgress,
} from "@mui/material";
import { CheckIcon } from "components/Icons/CheckIcon";
import { FilterBarsIcon } from "components/Icons/FilterBarsIcon";
import { ExternalLinkIcon } from "components/Icons/ExternalLinkIcon";
import baseApi from "api/baseApi";
import { AppContext } from "context";
import EventMeetingsDetail from "components/EventMeetingsDetail";
import { size } from "lodash";

export default function EventMeetingsTabpanel({}: any) {
  const [meetings, setMeetings] = useState<any>();
  const [meetingDetail, setMeetingDetail] = useState<any>({});
  const [sortMeetings, setSortMeetings] = useState<any>();
  const [filter, setFilter] = useState<any>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: formSetValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      durationType: "min",
    } as any,
  });

  const { universalData, member } = useContext(AppContext);

  const applyFilter = (name: string) => {
    if (filter === name) {
      setFilter(undefined);
    } else {
      setFilter(name);
    }
  };

  const sortingFilter = useMemo(
    () =>
      filter
        ? sortMeetings.slice(0).sort(function (a: any, b: any) {
            let textA = a[filter].toString().toUpperCase();
            let textB = b[filter].toString().toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
        : sortMeetings,
    [sortMeetings, filter]
  );

  useEffect(() => {
    baseApi
      .get("/api/bookingstats/bookedevent/", {
        params: {
          userId: member?.userId,
          orgId: universalData?.org?.id,
        },
      })
      .then((res: any) => {
        const dat = [...res.data];
        setMeetings(dat);
        setSortMeetings(dat);
      });
  }, [universalData?.org, member?.userId]);

  return (
    <>
      {size(meetingDetail) > 0 ? (
        <EventMeetingsDetail
          meetingDetail={meetingDetail}
          onReset={() => setMeetingDetail({})}
        />
      ) : (
        <Wrapper>
          <HeadingWrapper>
            <TitlesWrapper>
              <EventFiltersHeadBar
                meetings={meetings}
                setSortMeetings={setSortMeetings}
              />
            </TitlesWrapper>
          </HeadingWrapper>
          <ContentArea>
            <DataTableWrapper>
              <Table sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                      <ButtonCheckbox type="button">
                        <CheckIcon />
                      </ButtonCheckbox>
                    </TableCell> */}
                    <TableCell>
                      <CellHeading
                        variant="caption"
                        className={(filter === "eventName" && "active") || ""}
                      >
                        Meeting Name
                        <FilterBarsIcon
                          onClick={() => applyFilter("eventName")}
                        />
                      </CellHeading>
                    </TableCell>
                    <TableCell>
                      <CellHeading
                        variant="caption"
                        className={
                          (filter === "organizerName" && "active") || ""
                        }
                      >
                        Organizer
                        <FilterBarsIcon
                          onClick={() => applyFilter("organizerName")}
                        />
                      </CellHeading>
                    </TableCell>
                    <TableCell>
                      <CellHeading variant="caption">
                        Type
                        <FilterBarsIcon />
                      </CellHeading>
                    </TableCell>
                    <TableCell>
                      <CellHeading
                        variant="caption"
                        className={(filter === "duration" && "active") || ""}
                      >
                        Duration
                        <FilterBarsIcon
                          onClick={() => applyFilter("duration")}
                        />
                      </CellHeading>
                    </TableCell>
                    <TableCell>
                      <CellHeading
                        variant="caption"
                        className={(filter === "views" && "active") || ""}
                      >
                        Views
                        <FilterBarsIcon onClick={() => applyFilter("views")} />
                      </CellHeading>
                    </TableCell>
                    <TableCell>
                      <CellHeading
                        variant="caption"
                        className={(filter === "submissions" && "active") || ""}
                      >
                        Meetings Booked
                        <FilterBarsIcon
                          onClick={() => applyFilter("submissions")}
                        />
                      </CellHeading>
                    </TableCell>
                    <TableCell>
                      <CellHeading variant="caption">
                        Conversion Rate
                        <FilterBarsIcon />
                      </CellHeading>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {!sortingFilter ? (
                  <td colSpan={7}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        zIindex: "8",
                      }}
                    >
                      <CircularProgress color="inherit" />
                    </Box>
                  </td>
                ) : (
                  <TableBody>
                    {sortingFilter?.map((meeting: any) => (
                      <TableRow
                        onClick={() => setMeetingDetail(meeting)}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#282828",
                          },
                        }}
                      >
                        {/* <TableCell>
                          <ButtonCheckbox type="button">
                            <CheckIcon />
                          </ButtonCheckbox>
                        </TableCell> */}
                        <TableCell>
                          <TitleHeading variant="caption">
                            {meeting?.eventName}
                          </TitleHeading>
                          <DescrText variant="caption">
                            <LinkText
                              href={`${meeting.meetingUrl}`}
                              target="_blank"
                            >
                              <span className="link-holder">
                                {meeting?.meetingUrl} <ExternalLinkIcon />
                              </span>
                              {/* <TextDull variant="caption">(default)</TextDull> */}
                            </LinkText>
                          </DescrText>
                        </TableCell>
                        <TableCell>
                          <TitleHeading variant="caption">
                            {meeting?.organizerName}
                          </TitleHeading>
                        </TableCell>
                        <TableCell>
                          <TitleHeading variant="caption">
                            One-on-one
                          </TitleHeading>
                        </TableCell>
                        <TableCell>
                          <TitleHeading variant="caption">
                            {meeting?.duration} min
                          </TitleHeading>
                        </TableCell>
                        <TableCell>
                          <TitleHeading variant="caption">
                            {meeting?.views}
                          </TitleHeading>
                        </TableCell>
                        <TableCell>
                          <TitleHeading variant="caption">
                            {meeting?.submissions}
                          </TitleHeading>
                        </TableCell>
                        <TableCell>
                          <TitleHeading variant="caption">
                            {(
                              (parseFloat(
                                meeting?.submissions ? meeting?.submissions : 0
                              ) /
                                parseFloat(
                                  meeting?.views ? meeting?.views : 0
                                )) *
                              100
                            ).toFixed(2)}
                            %
                          </TitleHeading>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </DataTableWrapper>
          </ContentArea>
        </Wrapper>
      )}
    </>
  );
}
