import axios from "axios";

// Set up a base URL for your API
const baseURL = process.env.REACT_APP_BASE_URL; // Replace with your actual API base URL

// Create an Axios instance with a base URL
const baseApiAvoidPreflight = axios.create({
  baseURL,
});


baseApiAvoidPreflight.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    return Promise.reject(error);
  }
);

export default baseApiAvoidPreflight;
