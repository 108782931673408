import React, { useContext, useEffect, useRef } from "react";
import {
  ContentArea,
  ContentColumns,
  ContentSidebar,
  SidebarHeading,
  TabButton,
  TabsetList,
  Wrapper,
} from "./style";
import AddIcon from "@mui/icons-material/Add";
import { AppContext } from "context";
import LogoMarkCircle from "../../assets/images/6ix_mask_circle.png";
import EventTypesTabpanel from "components/EventTypesTabpanel";
import EventSetupTabpanel from "components/EventSetupTabpanel";
import EventBookingsTabpanel from "components/EventBookingsTabpanel";
import AvailabilityTabpanel from "components/AvailabilityTabpanel";
import EventIntegrationsTabpanel from "components/EventIntegrationsTabpanel";
import EventProfileTabpanel from "components/EventProfileTabpanel";
import EventBillingTabpanel from "components/EventBillingTabpanel";
import UserDropdown from "components/UserDropdown";
import EventMeetingsTabpanel from "components/EventMeetingsTabpanel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EventSettingsTabpanel from "components/EventSettingsTabpanel";
import { Box } from "@mui/material";

function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { pathname } = useLocation();
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        className="tab-content"
        id={`vertical-tabpanel-${index}`}
        {...other}
      >
        {pathname === "/types" && <EventTypesTabpanel />}
        {/* {pathname.includes('setup') && (
          <EventSetupTabpanel />
        )} */}
        {pathname.includes("bookings") && <EventBookingsTabpanel />}
        {pathname.includes("analytics") && <EventMeetingsTabpanel />}
        {pathname.includes("availability") && <AvailabilityTabpanel />}
        {pathname.includes("integration") && <EventIntegrationsTabpanel />}
        {pathname.includes("settings") && <EventSettingsTabpanel />}
        {/* {value === 6 && <EventProfileTabpanel />}
        {value === 7 && <EventBillingTabpanel />} */}
      </div>
    </>
  );
}

export default function EventTypes({ pTop, pBottom }: any) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const { org, universalData, member, setLoginRequest } =
    useContext(AppContext);

  const handleTabsChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (pathname === "/") {
      setValue(0);
    }
    if (pathname === "/types") {
      setValue(1);
    }
    // else if(pathname.includes('setup')){
    //   setValue(1);
    // }
    else if (pathname.includes("bookings")) {
      setValue(2);
    } else if (pathname.includes("analytics")) {
      setValue(3);
    } else if (pathname.includes("availability")) {
      setValue(4);
    } else if (pathname.includes("integration")) {
      setValue(5);
    } else if (pathname.includes("settings")) {
      setValue(6);
    }
  }, [pathname]);

  return (
    <Wrapper>
      <Box>
        <ContentSidebar className="types-block">
          <TabsetList orientation="horizontal" scrollButtons="auto" variant="scrollable" value={value}>
            <TabButton
              label="Overview"
              onClick={() => navigate("/")}
              className={(value === 0 && "Mui-selected") || ""}
              value={0}
            />
            {universalData && (
              <>
                <TabButton
                  label="Meeting Types"
                  onClick={() => navigate("/types")}
                  className={(value === 1 && "Mui-selected") || ""}
                  value={1}
                />
                {/* <TabButton label="Meeting Setup" onClick={() => navigate('/types/setup')} disabled={member?.userType === "New User"} /> */}
                <TabButton
                  label="Bookings"
                  onClick={() => navigate("/types/bookings")}
                  disabled={member?.userType === "New User"}
                  className={(value === 2 && "Mui-selected") || ""}
                  value={2}
                />
                <TabButton
                  label="Analytics"
                  onClick={() => navigate("/types/analytics")}
                  disabled={member?.userType === "New User"}
                  className={(value === 3 && "Mui-selected") || ""}
                  value={3}
                />
                <TabButton
                  label="Availability"
                  onClick={() => navigate("/types/availability")}
                  disabled={member?.userType === "New User"}
                  className={(value === 4 && "Mui-selected") || ""}
                  value={4}
                />
                <TabButton
                  label="Integrations"
                  onClick={() => navigate("/types/integrations")}
                  disabled={member?.userType === "New User"}
                  className={(value === 5 && "Mui-selected") || ""}
                  value={5}
                />
                <TabButton
                  label="Settings"
                  onClick={() => navigate("/types/settings")}
                  disabled={member?.userType === "New User"}
                  className={(value === 6 && "Mui-selected") || ""}
                  value={6}
                />
                {/* <TabButton label="Billing" /> */}
              </>
            )}
          </TabsetList>

          <button
            className="sixmeetings-users-newads-btn add"
            onClick={() =>
              universalData
                ? navigate("/types?new-event=true")
                : setLoginRequest(true)
            }
          >
            <AddIcon /> <div className="btn-text">New Meeting</div>
          </button>
        </ContentSidebar>
        {pathname.includes("/types") && (
          <ContentColumns>
            <ContentSidebar>
              <SidebarHeading variant="caption">
                {/* <i>
              <img src={LogoMarkCircle} width="24" height="24" alt="6ix" />
            </i>
            6ix Inc */}
                <UserDropdown label={"user email"} />
              </SidebarHeading>
            </ContentSidebar>
            <TabPanel value={value} index={0}>
              Item One
            </TabPanel>
          </ContentColumns>
        )}
      </Box>
    </Wrapper>
  );
}
