import { styled, Button } from '@mui/material';

export const Wrapper = styled('div')`
  position: fixed;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%);
  bottom: 20px;
  border: 1px solid #373737;
  background-color: rgba(31,31,31,.5);
  backdrop-filter: blur(25px);
  padding: 8px;
  justify-content: center;
  display: flex;
  z-index: 999;
  border-radius: 11px;
  /* opacity: 0;
  visibility: hidden; */
  gap: 10px;

  @media (min-width: 576px){
    gap: 20px;
  }
`;

export const ButtonPrimary = styled(Button)`
  min-width: 1px;
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  line-height: 1.5;
  font-weight: 400;
  padding: 10px 15px;
  font-size: 11px;

  @media (min-width: 576px){
    font-size: 14px;
  }
  @media (min-width: 768px){
    padding: 12px 36px;
  }

  &:hover{
    background-color: #131313;
    color: #fff;
    border-color: #E3C050;
  }
`;

export const ButtonSecondary = styled(Button)`
  min-width: 1px;
  background-color: transparent;
  color: #c0c0c0;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #454648;
  transition: all 0.3s ease;
  text-decoration: none;
  line-height: 1.5;
  font-weight: 400;
  padding: 10px 15px;
  font-size: 11px;

  @media (min-width: 576px){
    font-size: 14px;
  }
  @media (min-width: 768px){
    padding: 12px 36px;
  }

  &:hover{
    background-color: #131313;
    color: #fff;
    border-color: #E3C050;
  }
`;