import Login from "pages/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import Reschedule from "pages/Reschedule";
import HeaderNew from "components/common/HeaderNew";
import RescheduleEventList from "components/RescheduleEventList";
import HomePage from "pages/Home";
import PublicHeader from "components/PublicHeader/HeaderNew";
import { RegsiterUser } from "components/RegisterUser";
import RescheduleBooking from "pages/RescheduleBooking";
import { EventCancelation } from "components/EventCancelation";
import { PageNotFound } from "pages/PageNotFound/PageNotFound";

export const Unauthorized = () => (
  <Routes>
    <Route element={<RegsiterUser />}>
      <Route element={<HeaderNew />}>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/type-meetings" element={<TypesMeeting />} /> */}
        {[
          "/types",
          "/setup",
          "/setup/login-step-2",
          "/setup/login-step-3",
          "/setup/login-step-4",
        ].map((path) => (
          <Route path={path} element={<Navigate to="/" replace />} />
        ))}
        <Route path="/callback" element={<Login />} />
      </Route>
    </Route>

    <Route element={<PublicHeader />}>
      <Route path="/:username" element={<RescheduleEventList />} />
      <Route path="/:username/:eventName" element={<Reschedule />} />
      <Route
        path="/reschedule/:username/:eventId/:userId/:orgId"
        element={<RescheduleBooking />}
      />
    </Route>
    <Route path="/cancel-event/:eventId" element={<EventCancelation />} />

    <Route path="/404" element={<PageNotFound />} />
    <Route path="*" element={<Navigate to={"/404"} />} />
  </Routes>
);
