import { TimeClockIcon } from "components/Icons/TimeClockIcon";
import {
  BookHeading,
  BookWidget,
  BookingsList,
  BreadSubtitle,
  ButtonDefault,
  ButtonsGroup,
  DescriptionWrap,
  FirstCol,
  SecondCol,
  StatItem,
  TextList,
  Wrapper,
} from "./style";
import { CrossIcon } from "components/Icons/CrossIcon";
import { CalendarIcon } from "components/Icons/CalendarIcon";
import UpcomingsEditDropdown from "components/UpcomingsEditDropdown";
import { formattedDateString, formattedTime } from "utils/helper";
import {
  EmptyDescription,
  EmptyHeading,
  EmptyWrapper,
  IcnWrapper,
} from "components/PastTabpanel/style";
import { CalendarEmptyIcon } from "components/Icons/CalendarEmptyIcon";

export default function UpcomingTabpanel({
  bookings,
  handleCancelEvent,
  inAccessible,
  userName
}: any) {
  return (
    <Wrapper>
      <BookingsList>
        {bookings?.length > 0 ? (
          bookings?.map((booking: any) => (
            <BookWidget>
              <DescriptionWrap>
                <FirstCol>
                  <StatItem variant="caption">
                    <CalendarIcon />
                    {formattedDateString(booking.start)}
                  </StatItem>
                  <StatItem variant="caption">
                    <TimeClockIcon />
                    {formattedTime(booking.start)}
                  </StatItem>
                </FirstCol>
                <SecondCol>
                  <BookHeading variant="h2">
                    {booking.title}
                    {/* <BreadSubtitle
                      variant="caption"
                    >
                      with Daniel Barankin
                    </BreadSubtitle> */}
                  </BookHeading>
                </SecondCol>
              </DescriptionWrap>
              <ButtonsGroup>
                <ButtonDefault
                  type="button"
                  onClick={() => handleCancelEvent(booking.eventid)}
                  disabled={inAccessible}
                >
                  <CrossIcon />
                  Cancel
                </ButtonDefault>
                <UpcomingsEditDropdown label="Edit" id={booking} userName={userName} booking={booking} />
              </ButtonsGroup>
            </BookWidget>
          ))
        ) : (
          <EmptyWrapper>
            <IcnWrapper>
              <CalendarEmptyIcon />
            </IcnWrapper>
            <EmptyHeading variant="h2">You have no upcoming bookings</EmptyHeading>
            <EmptyDescription variant="body1">
            Your upcoming bookings will show up here.
            </EmptyDescription>
          </EmptyWrapper>
        )}
      </BookingsList>
    </Wrapper>
  );
}
