import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M14.75 2.99878H4.25C3.42157 2.99878 2.75 3.67035 2.75 4.49878V14.9988C2.75 15.8272 3.42157 16.4988 4.25 16.4988H14.75C15.5784 16.4988 16.25 15.8272 16.25 14.9988V4.49878C16.25 3.67035 15.5784 2.99878 14.75 2.99878Z" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M12.498 1.50122V4.50122" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M6.50195 1.50122V4.50122" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M2.75 7.49878H16.25" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};