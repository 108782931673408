import { useEffect } from "react";
import {
  auth,
  authorizeUser,
  universalLogin,
  userLogout,
} from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "utils/auth";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useExternalScripts } from "utils/useExternalScripts";

export const RegsiterUser = () => {
  const {
    isUserSession,
    UPDATE_USER_TOKEN,
    SET_USER_SESSION,
    LOGOUT_USER_SESSION,
  } = SESSION_AUTH();
  const [user]: any = useAuthState(auth);
  // useExternalScripts("https://accounts.google.com/gsi/client");

  useEffect(() => {
    if (user && !isUserSession?.userId) {
      universalLogin(user).then((res: any) => {
        if (res.data.code !== 600) SET_USER_SESSION(res.data);
      });
    }
  }, [isUserSession?.userId, user]);

  // useEffect(() => {
  //   if (isUserSession)
  //     authorizeUser(isUserSession)
  //       .then((res) => console.log(res))
  //       .catch((err) => LOGOUT_USER_SESSION(isUserSession));
  // }, []);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&amp;family=Roboto:wght@100;300;400;500;700;900&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Outlet />
    </>
  );
};
