import { styled, Box, Typography, FormControl, Button } from '@mui/material';

export const Wrapper = styled('div')`
  width: 100%;
  max-width: 952px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  display: flex;
`;

export const UserHeading = styled(Typography)`
  color: #e9e9e9;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 24px;
`;

export const AlignWrapper = styled(Box)`
  display: flex;
  width: 100%;
`;

export const Align = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
`;

export const SideWrapper = styled(Box)`
  padding: 36px;
  background-color: #131313;
  width: 288px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

export const TopWrap = styled(Box)`
  flex-grow: 1;
  min-height: 335px;
`;

export const BottomWrap = styled(Box)`
  padding-top: 40px;
`;

export const Subheading = styled(Typography)`
  color: #8C8E95;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 11px;

  .MuiSvgIcon-root{
    width: 20px;
    height: 20px;
    color: #E3C050;
    margin-top: -2px;
  }
`;

export const TextNote = styled(Typography)`
  color: #8C8E95;
  font-size: 13px;
  line-height: 1.2;
  display: block;
  margin-top: 10px;

  span{
    color: #c0c0c0;
  }
`;

export const Heading = styled(Typography)`
  color: #E9E9E9;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 16px;
`;

export const InfoWidgetWrapper = styled(Box)`
  background-color: #1F1F1F;
  border-radius: 8px;
  padding: 16px;
`;

export const InfoLineText = styled(Typography)`
  color: #E9E9E9;
  font-size: 16px;
  line-height: 1.5;
  display: block;
  position: relative;
  padding-left: 26px;

  + span{
    margin-top: 16px;
  }

  .MuiSvgIcon-root{
    position: absolute;
    width: 18px;
    height: 18px;
    color: #E3C050;
    left: 0;
    top: 3px;
  }
`;

export const FormInlineWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LabelHeading = styled(Typography)`
  color: #8C8E95;
  font-size: 14px;
  line-height: 1.2;
`;

export const SelectField = styled(FormControl)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #2B2B2B;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 26px;
  font-size: 12px;
  line-height: 14px;

  &.small{
    width: 64px;

    .MuiSelect-select{
      padding-right: 25px;
      min-height: 1px;
    }
  }

  &:after{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(45deg);
    border: solid currentColor;
    border-width: 0 1px 1px 0;
    content: '';
    width: 6px;
    height: 6px;
    margin-top: -2px;
    pointer-events: none;
  }

  .MuiInputBase-input{
    padding: 3px 10px;
    color: #e9e9e9;
    font-size: 12px;
    line-height: 14px;
    min-height: 1px;
  }

  input{

    + .MuiSvgIcon-root{
      color: #fff;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }

  .MuiChip-filled{
    background-color: #373737;
    color: #E9E9E9;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;

    .MuiSvgIcon-root{
      width: 12px;
      height: 12px;
      color: #8C8E95;
    }
  }

  .MuiSelect-icon{
    display: none;
  }
`;

export const PickWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DatePickerWrapper = styled(Box)`
  width: 100%;
  position: relative;
  
  &,
  .MuiStack-root,
  .MuiStack-root > div,
  .react-datepicker,
  .react-datepicker__month-container,
  .react-datepicker__time-container{
    height: 100%;
  }

  .MuiStack-root{
    padding-top: 0;
  }

  .react-datepicker{
    background-color: #1F1F1F;
    border-radius: 0;
    border: 0;
    display: flex;
    font-family: inherit;
  }

  .react-datepicker__month-container{
    width: 100%;
    padding: 36px;
  }

  .react-datepicker__time-container{
    width: 40%;
    flex-shrink: 0;
    border-left-color: #373737;

    .react-datepicker__header--time{
      display: none;
    }

    .react-datepicker__time,
    .react-datepicker__time-box{
      height: 100%;
    }

    .react-datepicker__time-list{
      height: 400px !important;
    }

    .react-datepicker__time-box{
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .react-datepicker__header,
  .react-datepicker__time{
    background-color: transparent;
    color: #8C8E95;
  }

  .react-datepicker__header{
    border: 0;
    padding: 0;
  }

  .react-datepicker__current-month{
    text-align: left;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    line-height: 1.2;
    margin-bottom: 12px;
  }

  .react-datepicker__day-names{
    display: flex;
    margin-bottom: 4px;
  }

  .react-datepicker__day-name{
    color: #8C8E95;
    font-size: 13px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0;
    padding: 8px;
    width: auto;
  }

  .react-datepicker__month{
    margin: 0;
  }

  .react-datepicker__week{
    display: flex;
    gap: 8px;

    + .react-datepicker__week{
      margin-top: 8px;
    }
  }

  .react-datepicker__day{
    background-color: #131313;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1;
    color: #fff;
    flex-grow: 1;
    flex-basis: 0;
    height: 44px;
    margin: 0;

    &.react-datepicker__day--outside-month,
    &.react-datepicker__day--disabled{
      background-color: #2B2B2B;
      color: #575757;
    }

    &.react-datepicker__day--disabled {
      background-color: #2B2B2B;
      color: #575757;
    }

    &.react-datepicker__day--selected,
    &.react-datepicker__day--today.react-datepicker__day--selected{
      position: relative;
      color: #E3C050;
    }

    &.react-datepicker__day--selected:before,
    &.react-datepicker__day--today.react-datepicker__day--selected:before{
      content: '';
      border-radius: 100%;
      width: 4px;
      height: 4px;
      position: absolute;
      left: 50%;
      bottom: 4px;
      transform: translateX(-50%);
      background-image: linear-gradient(45deg, rgba(227,192,80,1) 0%, rgba(227,192,80,1) 100%);
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .react-datepicker__navigation{
    top: 30px;
    right: calc(40% + 27px);
    left: auto;
  }

  .react-datepicker__navigation--previous{
    transform: translateX(-80px);
  }

  .react-datepicker__navigation-icon{

    &:before{
      width: 12px;
      height: 12px;
      border-width: 2px 2px 0 0;
      border-color: #E9E9E9;
      top: 6px;
      right: 0;
    }
  }

  .react-datepicker__time-list-item{
    font-size: 16px;
    line-height: 1.2;
    padding: 8px 4px !important;
    height: auto !important;
    border: 1px solid transparent;
    border-radius: 8px !important;
    margin-top: 8px;
    margin-bottom: 8px;

    &.react-datepicker__time-list-item--selected,
    &:hover{
      border-color: #E3C050 !important;
      color: #fff !important;
      background-color: #000 !important;
    }
  }
`;

export const CalendarWrapper = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 416px;
  width: 100%;
`;

export const TimeWrapper = styled(Box)`
  width: 228px;
  border-left: 1px solid #373737;
  overflow-y: auto;
  overflow-x: hidden;
  height: 416px;
  flex-shrink: 0;
`;

export const InnerWrapper = styled(Box)`
  width: 100%;
  height: 100%;
`;

export const BottomFoot = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width 100%;
  border-top: 1px solid #373737;
  padding: 16px 24px;
  justify-content: center;
`;

export const ButtonDefault = styled(Button)`
  background-color: #1f1f1f;
  border: 1px solid #373737;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 13px 20px;
  color: #fff;
  border-radius: 8px;
  min-width: 184px;

  &:hover{
    color: #fff;
    background-color: #454648;
    border-color: #e3c050;
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 13px 20px;
  min-width: 184px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;