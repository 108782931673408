import {
  ButtonPrimary,
  ButtonWrapper,
  ContentArea,
  Heading,
  HeadingWrapper,
  TabButton,
  TabsetList,
  TextPara,
  TitlesWrapper,
  Wrapper,
} from "./style";
import React, { useContext, useEffect, useState } from "react";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, getUserProfile } from "../../firebase";
import { useForm } from "react-hook-form";
import { ArrowRightIcon } from "components/Icons/ArrowRightIcon";
import { PlusIcon } from "components/Icons/PlusIcon";
import UpcomingTabpanel from "components/UpcomingTabpanel";
import PastTabpanel from "components/PastTabpanel";
import { toast } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
import { AppContext } from "context";
import CancelledTabpanel from "components/CancelledTabpanel";

function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const [bookings, setBookings] = useState<any>();
  const [refetch, setRefetch] = useState(false);
  const [fetching, setFetching] = useState(false);
  const { universalData, member } = useContext(AppContext);
  const [userName, setUserName] = useState<any>();
  const { children, value, index, ...other } = props;

  useEffect(() => {
		if (member?.userId) {
			baseApi.get(`/api/currentstage/user/${member?.userId}/?orgId=${universalData?.org.id}`)
				.then((res) => {
					const step = res.data;
          setUserName(step.username)
				})
				.catch((err) => toast.error(err?.response?.data?.error))
		}
	}, [universalData, member, member?.userId])

  useEffect(() => {
    if (member?.userId) {
      
      setFetching(true);
      baseApi
        .post(`/api/fetch/bookings/${member.userId}`, {
          orgId: universalData.org.id,
        })
        .then((res) => setBookings(res.data.bookings))
        .catch((err) => toast.error(err?.response?.data?.error))
        .finally(() => setFetching(false));
    }
  }, [universalData, member, refetch]);

  

  const handleCancelEvent = (id: any) => {
    baseApi
      .post("/api/delete/events/" + id)
      .then((res) => {
        setRefetch((prev) => !prev);
        toast.success("Booking Cancelled ");
      })
      .catch((err) => toast.error(err?.response?.data?.error));
  };

  return (
    <>
      <div role="tabpanel" id={`horizontal-tabpanel-${index}`} {...other}>
        {fetching || !bookings ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              // backgroundColor: "#2b2b2b",
              zIindex: "8",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <>
            {value === 0 && (
              <UpcomingTabpanel
                inAccessible={member?.userId !== universalData?.userId}
                bookings={bookings?.filter(
                  (booking: any) =>
                    booking.status === "Active" &&
                    new Date().getTime() <
                      new Date(booking?.start).getTime()
                )}
                userName={userName}
                handleCancelEvent={handleCancelEvent}
              />
            )}
            {value === 1 && (
              <PastTabpanel
                inAccessible={member?.userId !== universalData?.userId}
                bookings={bookings?.filter(
                  (booking: any) =>
                    booking.status === "Active" &&
                    new Date().getTime() >
                      new Date(booking?.start).getTime()
                )}
              />
            )}
            {value === 2 && (
              <CancelledTabpanel
                inAccessible={member?.userId !== universalData?.userId}
                bookings={bookings?.filter(
                  (booking: any) => booking.status === "Cancelled"
                )}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default function EventBookingsTabpanel({}: any) {
  const [eventTypes, setEvenTypes] = useState<any>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: formSetValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      durationType: "min",
    } as any,
  });

  const [value, setValue] = React.useState(0);
  const handleTabsChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setValue(newValue);
  };

  return (
    <Wrapper>
      <HeadingWrapper>
        <TitlesWrapper>
          <Heading variant="h1">Bookings</Heading>
          <TextPara variant="body1">
            See upcoming and past events booked through your event type links.
          </TextPara>
        </TitlesWrapper>
      </HeadingWrapper>
      <TabsetList value={value} onChange={handleTabsChange}>
        <TabButton label="Upcoming" />
        <TabButton label="Past" />
        <TabButton label="Cancelled" />
      </TabsetList>
      <ContentArea>
        <TabPanel value={value} index={0}>
          Item One
        </TabPanel>
      </ContentArea>
    </Wrapper>
  );
}
