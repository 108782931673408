import { styled, Box, Typography, Button, Menu, Link, TextField, FormControlLabel, Switch, FormControl, Divider } from '@mui/material';

export const Wrapper = styled('div')`
`;

export const IcnWrapper = styled('i')`
  display: block;
  margin-bottom: 16px;
  color: #454648;

  .MuiSvgIcon-root{
    width: 80px;
    height: 80px;
  }
`;

export const EmptyWrapper = styled(Box)`
  max-width: 260px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const EmptyHeading = styled(Typography)`
  color: #EDEDED;
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const EmptyDescription = styled(Typography)`
  color: #8C8E95;
  font-size: 14px;
  line-height: 20px;
`;