import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.5998 13.1682C10.4357 13.3203 10.219 13.4015 9.99846 13.4015C9.77839 13.4015 9.5612 13.3208 9.39677 13.1678L3.86215 8.02948C3.69063 7.87016 3.59848 7.65417 3.59848 7.43219C3.59848 7.20953 3.69119 6.99289 3.86376 6.83341C4.1992 6.52369 4.7305 6.52439 5.06502 6.83485L9.99847 11.4151L14.9319 6.83507C15.2664 6.52454 15.7978 6.52371 16.1332 6.83349C16.4861 7.15959 16.487 7.70248 16.135 8.02943L10.5998 13.1682Z" fill="white"/>
    </SvgIcon>
  );
};