import {
  BottomFoot,
  ButtonPrimary,
  ScheduleSuccessWrapper,
  HeadIcn,
  HeadWrap,
  HeadHeading,
  HeadDescr,
  CustomDivider,
  InfoList,
  ListDescr,
  ListTitle,
  ButtonLink,
  TextBack,
  DescrRow,
  InfoTextDescr,
  TextBold,
  ListIcons,
  ButtonSelector,
  Icn,
  DescrTag,
} from "./style";
import { ListItem } from "@mui/material";
import { CheckIcon } from "components/Icons/CheckIcon";
import Icon1 from "../../assets/images/google_calendar_31.png";
import Icon2 from "../../assets/images/outlook_calendar_goo.png";
import Icon3 from "../../assets/images/apple_calendar_21.png";
import {
  convertDateToTimeZone,
  formattedDateString,
  formattedTime,
  getDuration,
  rescheduleURl,
} from "utils/helper";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const EventInfoOutlook = ({
  eventInfo,
  event,
  step,
  resetHandler,
  handleCancel,
  calendar_type,
  timezone,
}: any) => {
  const navigate = useNavigate();
  const { pathname }: any = useLocation();

  const { eventId } = useParams();

  const startDate = convertDateToTimeZone(
    eventInfo?.start?.dateTime,
    timezone
  );

  return (
    <>
      <ScheduleSuccessWrapper>
        <HeadWrap>
          <HeadIcn>
            <CheckIcon />
          </HeadIcn>
          <HeadHeading variant="h3">
            Your meeting has been {eventId ? "rescheduled" : "scheduled"}
          </HeadHeading>
          <HeadDescr variant="body1">
            We emailed you and the other attendees a calendar invitation with
            all the details.
          </HeadDescr>
        </HeadWrap>
        <CustomDivider />
        <InfoList>
          <ListItem>
            <ListTitle variant="caption">What</ListTitle>
            <ListDescr>
              <DescrRow>
                <InfoTextDescr variant="body1">
                  <strong>{eventInfo?.subject}</strong>

                  {/* {` with ${eventInfo?.username}`} */}
                </InfoTextDescr>
              </DescrRow>
            </ListDescr>
          </ListItem>
          <ListItem>
            <ListTitle variant="caption">When</ListTitle>
            <ListDescr>
              <DescrRow>
                <InfoTextDescr variant="body1">
                  {formattedDateString(startDate)}
                  <br />
                  {formattedTime(startDate)} -{" "}
                  {` ${getDuration(eventInfo)} min`}
                </InfoTextDescr>
              </DescrRow>
            </ListDescr>
          </ListItem>
          <ListItem>
            <ListTitle variant="caption">Who</ListTitle>
            <ListDescr>
              <DescrRow>
                <InfoTextDescr variant="body1">
                  <TextBold variant="caption">
                    {eventInfo?.organizer?.emailAddress?.address}
                    <DescrTag className="warning" variant="caption">
                      Host
                    </DescrTag>
                  </TextBold>
                  {/* <TextSubtitle
										variant="caption"
									>
										Reason for cancellation (optional)
									</TextSubtitle> */}
                </InfoTextDescr>
              </DescrRow>
              <DescrRow>
                <InfoTextDescr variant="body1">
                  {eventInfo?.attendees?.map((item: any) => (
                    <TextBold variant="caption">
                      {item.emailAddress.name}
                    </TextBold>
                  ))}
                </InfoTextDescr>
              </DescrRow>
            </ListDescr>
          </ListItem>
          <ListItem>
            <ListTitle variant="caption">Where</ListTitle>
            <ListDescr>
              <DescrRow>
                <a
                  href={
                    eventInfo?.location?.displayName
                      ? eventInfo?.location?.displayName
                      : eventInfo?.webLink
                  }
                  target="_blank"
                >
                  <InfoTextDescr variant="body1">
                    {eventInfo?.location?.displayName
                      ? eventInfo?.location?.displayName
                      : eventInfo?.webLink}
                  </InfoTextDescr>
                </a>
              </DescrRow>
            </ListDescr>
          </ListItem>
          {/* <ListItem>
            <ListTitle variant="caption">Add to calendar</ListTitle>
            <ListDescr>
              <DescrRow>
                <ListIcons>
                  <ListItem>
                    <ButtonSelector type="button">
                      <Icn>
                        <img src={Icon1} width={"16"} height={"16"} alt="" />
                      </Icn>
                      <span>Google</span>
                    </ButtonSelector>
                  </ListItem>
                  <ListItem>
                    <ButtonSelector type="button">
                      <Icn>
                        <img src={Icon2} width={"16"} height={"16"} alt="" />
                      </Icn>
                      <span>Outlook</span>
                    </ButtonSelector>
                  </ListItem>
                  <ListItem>
                    <ButtonSelector type="button">
                      <Icn>
                        <img src={Icon3} width={"16"} height={"16"} alt="" />
                      </Icn>
                      <span>Apple</span>
                    </ButtonSelector>
                  </ListItem>
                </ListIcons>
              </DescrRow>
            </ListDescr>
          </ListItem> */}
        </InfoList>
        {/* <CustomDivider /> */}

        <TextBack
          variant="body1"
          sx={{
            marginBottom: "20px",
          }}
        >
          Need to make a change?{" "}
          <TextBack
            variant="body1"
            sx={{
              marginBottom: "20px",
            }}
          >
            Need to make a change?{" "}
            <ButtonLink
              type="button"
              onClick={() => {
                pathname.includes(eventInfo?.id)
                  ? navigate(0)
                  : navigate(rescheduleURl(eventInfo, event));
              }}
              // /${uri[2]}??_id=${eventInfo.id}&userId=${event.userId}&orgId=${event.orgId}&title=${event.title}&description=${event.description}&duration=${event.duration}&timezone=${event.timezone}
              // /${uri[1]}/${uri[2]}?_id=${event._id}&userId=${event.userId}&orgId=${event.orgId}&title=${event.title}&description=${event.description}&duration=${event.duration}&timezone=${event.timezone}
            >
              Reschedule
            </ButtonLink>{" "}
            or{" "}
            <ButtonLink type="button" onClick={handleCancel}>
              Cancel
            </ButtonLink>
          </TextBack>
        </TextBack>
        {/* <CustomDivider /> */}
        <BottomFoot
          sx={{
            justifyContent: "center",
          }}
        >
          <ButtonPrimary onClick={() => resetHandler()} type="button">
            Done
          </ButtonPrimary>
        </BottomFoot>
      </ScheduleSuccessWrapper>
    </>
  );
};
