import {
  BottomFoot,
  ButtonDefault,
  ButtonPrimary,
  DatePickerWrapper,
  Heading,
  InnerWrapper,
  LabelHeading,
  PickWrapper,
  SideWrapper,
  Subheading,
  TopWrap,
  Wrapper,
  FormField,
  TextAreaField,
  IcnWrap,
  SimpleSelectField,
  ScatList,
  DaterWrapper,
  NexterWrap,
  FormGroup,
  InlineProfilerWrap,
  DragButton,
  InfoPicWrap,
  SpanCheck,
  InfoText,
  ButtonTertiary,
  TextSelectedDate,
  TuneTimeSelect,
  TuneScrollWrap,
} from "./style";
import { TimeClockIcon } from "components/Icons/TimeClockIcon";
import { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  ListItem,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import baseApi from "api/baseApi";
import {
  formatedDateStringCalender,
  formattedDateString,
  googleEventHandler,
  outlookEventHandler,
  validateEmail,
} from "utils/helper";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import TimezoneSelect from "react-timezone-select";
import { ChipDeleteIcon } from "components/Icons/ChipDeleteIcon";
import { GlobeIcon } from "components/Icons/GlobeIcon";
import { DragMoveIcon } from "components/Icons/DragMoveIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import { EventInfoGoogle } from "./EventInfoGoogle";
import { CancelEvent } from "./CancelEvent";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";
import moment from "moment";
import momentTz from "moment-timezone";
import baseApiAvoidPreflight from "api/baseApiAvoidPreflight";
import { useQuery } from "utils/useQuery";
import { EventInfoOutlook } from "./EventInfoOutlook";
import { AppContext } from "context";
import InvalidMeetingPage from "components/InvalidMeetingPage";
import { Add, Delete } from "@mui/icons-material";
import { CrossIcon } from "components/Icons/CrossIcon";
import { UserAddIcon } from "components/Icons/UserAddIcon";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function RescheduleEventSection({}: any) {
  const query = useQuery();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const { username, eventName } = useParams();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [startTime, setStartTime] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const { universalData, member } = useContext(AppContext);
  const [eventData, setEventData] = useState<any>();
  const [guestEmail, setGuestEmail] = useState<string>("");

  const [availableSlots, setAvailableSlots] = useState<any>();

  const [eventInfo, setEventInfo] = useState<any>();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      duration: "30",
      guests: [],
    } as any,
  });

  const resetHandler = () => {
    // navigate("/" + username);
    // window.location.reload();
    window.location.href = "https://6ix.com";
  };

  const event = eventData;

  const handleCancel = () => {
    if (eventInfo.id) {
      baseApi.post("/api/delete/events/" + eventInfo.id).then((res) => {
        setStep(3);
      });
    }
  };

  const onSubmit = (data: any) => {
    if (startDate) {
      setLoading(true);
      const [hours, minutes] = startTime.split(":");
      const newStartDate = new Date(startDate);
      const da = `${newStartDate.getFullYear()}-${
        newStartDate.getMonth() + 1 < 10
          ? `0${newStartDate.getMonth() + 1}`
          : newStartDate.getMonth() + 1
      }-${
        newStartDate.getDate() < 10
          ? `0${newStartDate.getDate()}`
          : newStartDate.getDate()
      }T${hours}:${minutes}`;

      const specificDate = momentTz.tz(da, data.timezone);
      const start = specificDate.utc().format();
      specificDate.add(parseInt(event?.duration), "minutes");

      const end = specificDate.utc().format();

      baseApi
        .post(
          availableSlots.calendar_type === "Outlook"
            ? "/api/outlook/createevent/"
            : "/api/createvent",
          {
            ...(availableSlots.calendar_type === "Outlook"
              ? outlookEventHandler(event, start, end, data)
              : googleEventHandler(event, start, end, data)),
          }
        )
        .then((res) => {
          setStep((prev) => prev + 1);
          setEventInfo(res.data?.EventInfo);
          toast.success(res.data?.message);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.response?.data?.error);
        })
        .finally(() => setLoading(false));
    }
  };

  const thirtySlots = useMemo(
    () => availableSlots?.thirtyMinuteSlots,
    [availableSlots]
  );

  useEffect(() => {
    if (
      event?._id &&
      event?.userId &&
      event?.duration &&
      event?.orgId &&
      event?.timezone &&
      event?.status === "Active"
    ) {
      setFetching(true);
      baseApiAvoidPreflight
        .get("/api/getavailabilityslots/findslot", {
          params: {
            userId: event?.userId,
            timeslot: event?.duration,
            requestedzone: watch().timezone,
            orgId: event.orgId,
            _id: event._id,
          },
        })
        .then((res) => {
          setAvailableSlots(res.data);
          setStartDate(dayjs(res.data.thirtyMinuteSlots[0].date).format());
        })
        .catch((err) => toast.error(err?.response?.data?.error))
        .finally(() => setFetching(false));
    }
  }, [
    event?._id,
    event?.duration,
    event?.orgId,
    event?.timezone,
    event?.userId,
    navigate,
    setValue,
    username,
    watch().timezone,
  ]);

  useEffect(() => {
    if (username && eventName) {
      setFetching(true);
      baseApiAvoidPreflight
        .get("/api/" + username)
        .then((res) => {
          const evt = res.data.events.find((ev: any) =>
            ev.url.includes(eventName)
          );

          if (evt) {
            setEventData(
              res.data.events.find((ev: any) => ev.url.includes(eventName))
            );
          } else {
            toast.error("Event does not exist");
            navigate("/" + username);
          }
        })
        .catch((err) => toast.error(err?.response?.data?.error))
        .finally(() => setFetching(false));
    }
  }, [username, eventName, navigate]);

  if (!eventData)
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          // backgroundColor: "#2b2b2b",
          zIindex: "8",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );

  return (
    <>
      {event && (
        <>
          {event?.status !== "Active" ? (
            <InvalidMeetingPage />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              {step < 2 && (
                <Wrapper>
                  <SideWrapper>
                    <TopWrap>
                      <Subheading variant="caption">{event.title}</Subheading>
                      <Heading variant="h2" className="meeting-description">
                        {event.description}
                      </Heading>
                      <ScatList>
                        <ListItem>
                          <IcnWrap>
                            <TimeClockIcon />
                          </IcnWrap>
                          {event.duration + " mins"}
                        </ListItem>
                        <ListItem>
                          <IcnWrap>
                            <GlobeIcon />
                          </IcnWrap>
                          <SimpleSelectField className="timezone">
                            <Controller
                              control={control}
                              defaultValue={
                                Intl.DateTimeFormat().resolvedOptions().timeZone
                              }
                              name="timezone"
                              render={({ field: { onChange, value } }) => (
                                <TimezoneSelect
                                  className="timezone-select-container"
                                  classNamePrefix="timezone"
                                  value={value}
                                  onChange={(e: any) => onChange(e.value)}
                                />
                              )}
                            />
                          </SimpleSelectField>
                        </ListItem>
                      </ScatList>
                    </TopWrap>
                  </SideWrapper>
                  <PickWrapper>
                    <TopWrap>
                      <InnerWrapper>
                        {step === 1 && (!startDate || !startTime) && (
                          <DaterWrapper className="date-time-pm">
                            {fetching && (
                              <Box className="loader-holder">
                                <CircularProgress color="inherit" />
                              </Box>
                            )}
                            <DatePickerWrapper>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DateTimePicker"]}>
                                  <DatePicker
                                    inline
                                    open
                                    selected={startDate}
                                    onChange={(date: any) => setStartDate(date)}
                                    includeDates={thirtySlots?.map(
                                      (slot: any) =>
                                        (dayjs(slot.date) as any).$d
                                    )}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </DatePickerWrapper>
                            {/* {startDate && ( */}
                            <TuneTimeSelect>
                              <TextSelectedDate variant="caption">
                                {formatedDateStringCalender(startDate)}
                              </TextSelectedDate>
                              <TuneScrollWrap>
                                {thirtySlots
                                  ?.filter(
                                    (slot: any) =>
                                      formattedDateString(slot.date) ===
                                      formattedDateString(startDate)
                                  )
                                  ?.map((sl: any) => (
                                    <div>
                                      <Button
                                        variant={
                                          startTime === sl.startTime
                                            ? "outlined"
                                            : "text"
                                        }
                                        onClick={() =>
                                          setStartTime(sl.startTime)
                                        }
                                      >
                                        {moment(sl.startTime, "h mm").format(
                                          "LT"
                                        )}
                                      </Button>
                                    </div>
                                  ))}
                              </TuneScrollWrap>
                            </TuneTimeSelect>
                            {/* )} */}
                          </DaterWrapper>
                        )}
                        {startDate && startTime && (
                          <NexterWrap>
                            <FormGroup>
                              <LabelHeading variant="caption">
                                Your Name
                              </LabelHeading>
                              <FormField  {...register("name", {required: "required"})} hiddenLabel />
                            </FormGroup>
                            <FormGroup>
                              <LabelHeading variant="caption">
                                Your Email
                              </LabelHeading>
                              <FormField
                                {...register("email", {
                                  required: "required",
                                  pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message:
                                      "Entered value does not match email format",
                                  },
                                })}
                                hiddenLabel
                              />
                              {errors.email && (
                                <span role="alert">{errors.email.message}</span>
                              )}
                            </FormGroup>

                            <FormGroup className="text-area">
                              <LabelHeading variant="caption">
                                Additional Notes
                              </LabelHeading>
                              <TextAreaField
                                {...register("notes")}
                                placeholder="Please share anything that will help prepare for our meeting."
                                hiddenLabel
                              />
                            </FormGroup>

                            <FormGroup>
                              <LabelHeading variant="caption">
                                Guests
                              </LabelHeading>
                              {watch("guests").map(
                                (guest: any, index: number) => (
                                  <>
                                    <div className="item-holder">
                                      <FormField
                                        {...register(`guests.${index}`)}
                                        hiddenLabel
                                        placeholder="Enter an email(s) and press Enter"
                                      />
                                      <IconButton
                                      className="btn-trash"
                                        color="primary"
                                        onClick={() =>
                                          setValue(
                                            "guests",
                                            watch("guests").filter(
                                              (_: any, i: any) => i !== index
                                            )
                                          )
                                        }
                                        // disabled={watch("guests").length === 1} // Disable delete if only one guest is present
                                      >
                                        <CrossIcon />
                                      </IconButton>
                                    </div>
                                  </>
                                )
                              )}

                              <ButtonDefault
                                onClick={() =>
                                  setValue("guests", [...watch("guests"), ""])
                                }
                                variant="contained"
                                className="grayed small"
                                type="button"
                                startIcon={<UserAddIcon />}
                                sx={{ mb: 2, mt: 2 }}
                              >
                                Add guest
                              </ButtonDefault>
                            </FormGroup>
                            <FormGroup className="d-none">
                              <InlineProfilerWrap>
                                <DragButton type="button">
                                  <DragMoveIcon />
                                </DragButton>
                                <SpanCheck>
                                  <CheckIcon />
                                </SpanCheck>
                                <InfoPicWrap>
                                  {/* <img src="" alt="" /> */}
                                </InfoPicWrap>
                                <InfoText variant="caption">
                                  romeo2@6ix.com
                                </InfoText>
                                <ButtonTertiary type="button">
                                  Create profile
                                </ButtonTertiary>
                              </InlineProfilerWrap>
                            </FormGroup>
                            <FormGroup className="d-none">
                              <LabelHeading variant="caption">
                                Additional notes
                              </LabelHeading>
                              <TextAreaField
                                hiddenLabel
                                multiline
                                placeholder="Please share anything that will help prepare for our meeting."
                              />
                            </FormGroup>
                          </NexterWrap>
                        )}
                      </InnerWrapper>
                    </TopWrap>
                    {startTime && startDate && (
                      <BottomFoot>
                        <>
                          <ButtonDefault
                            type="button"
                            onClick={() => {
                              setStartTime("");
                              setStep(1);
                            }}
                          >
                            Back
                          </ButtonDefault>
                          <ButtonPrimary
                            disabled={(guestEmail as any) || loading}
                            type="submit"
                          >
                            Confirm
                          </ButtonPrimary>
                        </>
                      </BottomFoot>
                    )}
                  </PickWrapper>
                </Wrapper>
              )}
              {step === 2 && (
                <>
                  {availableSlots.calendar_type === "Outlook" ? (
                    <EventInfoOutlook
                      event={event}
                      eventInfo={eventInfo}
                      step={step}
                      resetHandler={resetHandler}
                      handleCancel={handleCancel}
                      calendar_type={availableSlots.calendar_type}
                      timezone={watch().timezone}
                    />
                  ) : (
                    <EventInfoGoogle
                      event={event}
                      eventInfo={eventInfo}
                      step={step}
                      resetHandler={resetHandler}
                      handleCancel={handleCancel}
                      calendar_type={availableSlots.calendar_type}
                      timezone={watch().timezone}
                    />
                  )}
                </>
              )}
              {step === 3 && (
                <CancelEvent
                  eventInfo={eventInfo}
                  step={step}
                  resetHandler={resetHandler}
                />
              )}
            </form>
          )}
        </>
      )}
    </>
  );
}
