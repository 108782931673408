import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { ButtonDropdownWrapper, ButtonOpener, StdMenu, ButtonItem } from './style';
import { CaretIcon } from 'components/Icons/CaretIcon';
import { CopyIcon } from 'components/Icons/CopyIcon';
import { useNavigate } from 'react-router-dom';
import { rescheduleBookingURL, rescheduleURl } from 'utils/helper';

export default function UpcomingsEditDropdown({label, id, booking, userName} : any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event : any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReschedule = () => {
    navigate(rescheduleBookingURL(userName, booking))
    handleClose();
  }

  

  return (
    <ButtonDropdownWrapper>
      <ButtonOpener id={id} aria-controls={`${label}-menu`} aria-haspopup="true" aria-expanded={Boolean(anchorEl)} onClick={handleClick}>
        {label}
        <i className='icn-wrap'>
          <CaretIcon />
        </i>
      </ButtonOpener>
      <StdMenu id="stats-export-mneu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem>
          <ButtonItem type="button" onClick={handleReschedule}>
            <CopyIcon />
            Reschedule booking
          </ButtonItem>
        </MenuItem>
        <MenuItem>
          <ButtonItem type="button" disabled={true}>
            <CopyIcon />
            Request reschedule
          </ButtonItem>
        </MenuItem>
        <MenuItem>
          <ButtonItem type="button" disabled={true}>
            <CopyIcon />
            Edit location
          </ButtonItem>
        </MenuItem>
      </StdMenu>
    </ButtonDropdownWrapper>
  );
}