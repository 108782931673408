import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const CopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M15.5 6.75H8.75C7.92157 6.75 7.25 7.42157 7.25 8.25V15C7.25 15.8284 7.92157 16.5 8.75 16.5H15.5C16.3284 16.5 17 15.8284 17 15V8.25C17 7.42157 16.3284 6.75 15.5 6.75Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M4.25 11.25H3.5C3.10218 11.25 2.72064 11.092 2.43934 10.8107C2.15804 10.5294 2 10.1478 2 9.75001V3C2 2.60218 2.15804 2.22064 2.43934 1.93934C2.72064 1.65804 3.10218 1.5 3.5 1.5H10.25C10.6478 1.5 11.0294 1.65804 11.3107 1.93934C11.592 2.22064 11.75 2.60218 11.75 3V3.75" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};