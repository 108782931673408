import { SearchIcon } from "components/Icons/SearchIcon";
import {
  ButtonLink,
  FilterCol,
  FormCol,
  FormColsWrapper,
  FormField,
  FormGroup,
  IconLabel,
  LabelHeading,
  SearchFieldWrapper,
  SelectField,
  Wrapper,
} from "./style";
import { Controller, useForm } from "react-hook-form";
import { MenuItem, Select } from "@mui/material";
import UserDropdown from "components/UserDropdown";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "context";

export default function EventFiltersHeadBar({
  meetings,
  setSortMeetings,
}: any) {
  const { member } = useContext(AppContext);
  const ref = useRef<any>();
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      role: "member",
      email: "",
    },
  });

  const onChangeHandler = (e: any) => {
    const value = e.target.value.toLowerCase();
    if (value === "") setSortMeetings(meetings);
    else {
      setSortMeetings(
        meetings.filter((meeting: any) =>
          meeting.eventName.toLowerCase().includes(value)
        )
      );
    }
  };

  const reset = () => {
    const input = ref.current.children[0].children[0];
    input.value = "";
    setSortMeetings(meetings);
  };

  useEffect(() => {
    reset();
  }, [member])

  return (
    <Wrapper>
      <FilterCol>
        <SearchFieldWrapper>
          <IconLabel>
            <SearchIcon />
          </IconLabel>
          <FormField
            ref={ref}
            name="search"
            hiddenLabel
            placeholder="Search meeting name"
            onChange={onChangeHandler}
          />
        </SearchFieldWrapper>
      </FilterCol>
      <FilterCol>
        <FormColsWrapper>
          <FormCol>
            <FormGroup>
              <LabelHeading variant="caption">Owner</LabelHeading>
              <SelectField className="no-drop">
                <UserDropdown />
              </SelectField>
            </FormGroup>
          </FormCol>
          {/* <FormCol>
            <FormGroup>
              <LabelHeading variant="caption">Meeting Type</LabelHeading>
              <SelectField>
                <Controller
                  control={control}
                  name="role"
                  render={({ field: { onChange, value } }) => (
                    <Select value={value} onChange={onChange}>
                      <MenuItem value="member">All Meeting Types</MenuItem>
                      <MenuItem value="customer">Meeting Types</MenuItem>
                    </Select>
                  )}
                />
              </SelectField>
            </FormGroup>
          </FormCol> */}
          <ButtonLink onClick={() => reset()} type="button">
            Clear Results
          </ButtonLink>
        </FormColsWrapper>
      </FilterCol>
    </Wrapper>
  );
}
