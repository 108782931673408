import { Link } from "@mui/material";
import { SecondaryLink, PrimaryLink, Wrapper } from "./style";
import { useContext } from "react";
import { AppContext } from "context";
import { useNavigate } from "react-router-dom";

const ButtonsFooter = ({
  primaryLabel,
  refferOnTouch,
  secondaryLabel,
  secondaryLink,
}: any) => {
  const navigate = useNavigate();
  const { universalData, setLoginRequest } = useContext(AppContext);

  return (
    <Wrapper>
      {primaryLabel && 1 > 2 && (
        <PrimaryLink onClick={() => refferOnTouch()}>
          {primaryLabel}
        </PrimaryLink>
      )}
      {secondaryLabel && (
        <SecondaryLink
          onClick={() =>
            universalData
              ? navigate("/setup")
              : setLoginRequest((prev: any) => !prev)
          }
        >
          { universalData ? secondaryLabel : primaryLabel}
        </SecondaryLink>
      )}
    </Wrapper>
  );
};

export default ButtonsFooter;
