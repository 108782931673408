import { ContainerWrapper, PageWrapper, AlignWrapper, Align } from "./style";
import RescheduleEventSection from "components/RescheduleEventSection";

const Reschedule = () => {

  return (
    <>
      <div className="sixmeetings-content">
        <div className="sixmeetings-adss">
          <PageWrapper>
            <AlignWrapper>
              <Align>
                <ContainerWrapper>
                  <RescheduleEventSection />
                </ContainerWrapper>
              </Align>
            </AlignWrapper>
          </PageWrapper>
        </div>
      </div>
    </>
  );
};
export default Reschedule;