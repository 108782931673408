import { Menu, MenuItem, MenuList } from "@mui/material";
import { ButtonIcon, DropItemButton } from "./style";
import { PencilIcon } from "components/Icons/PencilIcon";
import { CopyIcon } from "components/Icons/CopyIcon";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { useState } from "react";
import { TrippleDotsHorizontalIcon } from "components/Icons/TrippleDotsHorizontalIcon";
import { each, filter } from "lodash";

export const DropDown = ({ event, reset, setBooker, handleAddNewModalOpen, onSubmit, handleDeleteEvent, eventsList, inAccessible }: any) => {
	const [anchorEl, setAnchorEl] = useState(null); // State to manage menu visibility

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const getClonedEvent =(event: any) => {
		let updatedEvent = {...event, title: event.title + ' - copy', pageName: event.pageName + ' - copy', _id: null};
		

		const checkEventsTypes = (list: any) => {
			filter(list, (obj) => {
				if(obj.pageName === updatedEvent.pageName) {
					updatedEvent = {...updatedEvent, title: updatedEvent.title + ' - copy', pageName: updatedEvent.pageName + ' - copy'}
					checkEventsTypes(eventsList);
				}
				return;
			});
		}

		checkEventsTypes(eventsList);


		return updatedEvent;
	}

	return (
		<>
			<ButtonIcon onClick={handleClick}>
				<TrippleDotsHorizontalIcon />
			</ButtonIcon>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuList>
					<MenuItem>
						<DropItemButton onClick={() => {
							reset(event)
							if (event?.bookerDurations?.length > 0) setBooker(true)
							handleAddNewModalOpen()
							handleClose()
						}} type="button">
							<PencilIcon />
							Edit
						</DropItemButton>
					</MenuItem>
					<MenuItem>
						<DropItemButton disabled={inAccessible} onClick={() => {
							// onSubmit({ ...event, title: event.title + ' - copy', pageName: event.pageName + ' - copy' , _id: null })
							onSubmit(getClonedEvent(event))
							handleClose()
						}} type="button">
							<CopyIcon />
							Duplicate
						</DropItemButton>
					</MenuItem>
					<MenuItem>
						<DropItemButton disabled={inAccessible} onClick={() => {
								handleDeleteEvent(event)
								handleClose()
						}} 
							className="delete"
							type="button">
							<DeleteIcon />
							Delete
						</DropItemButton>
					</MenuItem>
				</MenuList>
			</Menu >
		</>
	);
};