import { styled, Box, Typography, Divider, Button, TextField, FormControl } from '@mui/material';

export const Wrapper = styled('div')`
  color: #C0C0C0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
`;

export const HeadingWrapper = styled(Box)`
  margin-bottom: 36px;
  text-align: center;
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 12px;
  color: #fff;
`;

export const Description = styled(Typography)`
  font-size: 14px;
  line-height: 1.5;

  @media (min-width: 768px){
    font-size: 16px;
  }
`;

export const Title = styled(Typography)`
  font-size: 18px;
  line-height: 22px;
  display: block;
  color: #E9E9E9;
`;

export const StepsWrapper = styled(Box)`
  margin-bottom: 36px;
`;

export const StepBarsWrapper = styled(Box)`
  margin-top: 16px;
  display: flex;
  gap: 12px;
`;

export const StepBar = styled(Box)`
  flex-grow: 1;
  flex-basis: 0;
  height: 8px;
  border-radius: 99px;
  background-color: #2B2B2B;

  &.active{
    background-color: #E2BF78;
  }
`;

export const BoxWrapper = styled(Box)`
  border: 1px solid #373737;
  border-radius: 11px;
  background-color: #1f1f1f;
  padding: 20px;
`;

export const ActionBtnsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const ButtonDefault = styled(Button)`
  flex-grow: 1;
  flex-basis: 0;
  min-width: calc(100% - 10px);
  background-color: #2B2B2B;
  border: 1px solid #373737;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 19px 16px;
  color: #fff;
  border-radius: 8px;
  text-transform: none;

  @media (min-width: 576px){
    min-width: calc(50% - 10px);
  }

  &:hover{
    color: #fff;
    background-color: #454648;
    border-color: #e3c050;
  }
`;

export const SeperatorOr = styled(Box)`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 24px;
  margin-bottom: 24px;

  &:before,
  &:after{
    content: '';
    display: block;
    background-color: #373737;
    height: 1px;
    width: 100%;
  }

  span{
    background-color: #1f1f1f;
    display: inline-block;
    vertical-align: top;
    padding: 4px;
    flex-shrink: 0;
  }
`;

export const FormGroup = styled(Box)`
  
  + div{
    margin-top: 24px;
  }
`;

export const FormField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 44px;
  font-size: 14px;
  line-height: 18px;

  &.timezone{

    > div,
    > div > div[class*="control"]{
      min-height: 42px;
      height: auto;

      &:focus {
        box-shadow: 0 0 0 1px #E3C050 !important;
      }
    }

    > div > div[class*="css-t3"] {
      box-shadow: 0 0 0 1px #E3C050 !important;
    }

    > div > div[class*="control"]{
      background-color: transparent;
      color: inherit;
      border: 0;

      &:focus {
        box-shadow: 0 0 0 1px #E3C050 !important;
      }
    }

    [class*="IndicatorsContainer"]{
      display: none;
    }

    [class*="-menu"]{
      background-color: #131313;
      border: 1px solid #373737;
      margin-top: 0;

      > * > *{

        &:hover{
          background-color: #454648;
          color: #fff;
        }
      }
    }
  }

  input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }
`;

export const LabelHeading = styled(Typography)`
  font-size: 13px;
  line-height: 15px;
  display: block;
  color: #8C8E95;
  margin-bottom: 8px;
`;

export const CaptionWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  background-color: #131313;
  border: 1px solid #373737;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 576px){
    flex-wrap: nowrap;
  }

  input,
  fieldset,
  .MuiFormControl-root{
    border-color: transparent !important;
    background-color: transparent !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    height: calc(100% + 7px) !important;
    width: calc(100% + 2px) !important;
    top: -6px !important;
    left: -1px !important;
    border-radius: 0 4px 4px 0 !important;
  }

  .label-caption{
    background-color: #2B2B2B;
    color: #8C8E95;
    font-size: 13px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    padding: 10px 12px;
    flex-shrink: 0;
    width: 100%;
    justify-content: center;
    text-align: center;

    @media (min-width: 576px){
      font-size: 16px;
      justify-content: flex-start;
      width: auto;
    }
  }
`;

export const FormSelectField = styled(FormControl)`
  color: #fff;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  min-height: 46px;
  font-size: 14px;
  line-height: 18px;

  &:after{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(45deg);
    border: solid currentColor;
    border-width: 0 1px 1px 0;
    content: '';
    width: 8px;
    height: 8px;
    margin-top: -2px;
    pointer-events: none;
  }

  &.timezone{

    > div,
    > div > div[class*="control"]{
      min-height: 42px;
      height: auto;

      &:focus {
        box-shadow: 0 0 0 1px #E3C050 !important;
      }
    }

    > div > div[class*="css-t3"] {
      box-shadow: 0 0 0 1px #E3C050 !important;
    }

    > div > div[class*="control"]{
      background-color: transparent;
      color: inherit;
      border: 0;

      &:focus {
        box-shadow: 0 0 0 1px #E3C050 !important;
      }
    }

    [class*="IndicatorsContainer"]{
      display: none;
    }

    [class*="-menu"]{
      background-color: #131313;
      border: 1px solid #373737;
      margin-top: 0;

      > * > *{

        &:hover{
          background-color: #454648;
          color: #fff;
        }
      }
    }
  }

  .MuiInputBase-input{
    padding: 8px;
    color: #fff;
    font-size: 14px;
    line-height: 28px;
  }

  input{

    + .MuiSvgIcon-root{
      color: #fff;
      top: 10px;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    /* outline: none !important;
    border: 0 !important;
    box-shadow: none !important; */
  }

  .MuiSelect-icon{
    display: none;
  }
`;

export const TextNote = styled(Typography)`
  font-size: 13px;
  line-height: 15px;
  display: block;
  color: #8C8E95;
  margin-top: 8px;
`;

export const ButtonPrimary = styled(Button)`
  margin-top: 32px;
  display: block;
  min-width: 1px;
  width: 100%;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 13px 20px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }

  &.Mui-disabled{
    color: #575757;
    border-color: #2B2B2B;
    background-color: #2B2B2B;
  }
`;

export const ErrorText = styled(Typography)`
  font-size: 14px;
  line-height: 1;
  color: #E64135;
  display: block;
  margin-top: 8px;
`;


export const ProfileWrapper = styled(Box)`
  overflow: hidden;
  margin: -20px -20px 20px;
  position: relative;

  .btn-edit {
    position: absolute;
    left: 10px;
    bottom: 10px;
    font-size: 10px;
    line-height: 1.2;
    padding: 5px;
    display: inline-flex;
    width: inherit;
  }
`;

export const PicWrapper = styled(`span`)`
  width: 100%;
  max-height: 200px;
  border-radius: 8px;
  background-color: #2B2B2B;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8C8E95;
  position: relative;
  overflow: hidden;

  .MuiSvgIcon-root{
    width: 24px;
    height: 24px;
  }

  img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;