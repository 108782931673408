/* global google */
import React, { useState, useEffect, useContext } from "react";
import "./style.scss";
import {
  Wrapper,
  NavWrapper,
  Separator,
  ActionsWrapper,
  LoginButton,
  ProfileModal,
  ModalContent,
  EditPicButton,
  ButtonPrimary,
  ButtonWrapper,
  TextAreaField,
  FormField,
  LabelHeading,
  FormGroup,
  CustomSelect,
} from "./style";
import MenuItem from "@mui/material/MenuItem";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { signInWithCredentialResponse, auth } from "../../firebase";
import { LoginPopup } from "components/Account/Login/LoginPopup";
import { useAuthState } from "react-firebase-hooks/auth";
import SelectorDropdown from "components/SelectorDropdown";
import ProfileDropdown from "components/ProfileDropdown";
import { PencilIcon } from "components/Icons/PencilIcon";
import LanguageTranslate from "components/LanguageTranslate/LanguageTranslate";
import AddIcon from "@mui/icons-material/Add";
import { UserIcon } from "components/Icons/UserIcon";
import { SESSION_AUTH } from "utils/auth";
import { AppContext } from "context";
import { List, ListItemButton, ListItemText } from "@mui/material";
import HeaderApps from "./HeaderApps";
import HeaderLogo from "components/HeaderLogo";
import StickyHeader from "components/StickyHeader";
import HeaderAttract from "./HeaderAttract";
import HeaderInvest from "./HeaderInvest";
import HeaderExpertise from "./HeaderExpertise";
import Flex from "components/FlexWrapper";
import HeaderSidebar from "./HeaderSidebar";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import MenuIcon from "assets/images/icons/MenuIcon";

const HeaderNew = () => {
  const { pathname } = useLocation();
  const { isUserSession } = SESSION_AUTH();
  const { username } = useParams();
  const navigate = useNavigate();
  const [loading] = useAuthState(auth);
  const { setUniversalData, loginRequest, setLoginRequest, universalData, setOpenSidebar, openSidebar } =
    useContext(AppContext);
  const [userInfo, setUserInfo] = useState("");
  const [mobSidebar, setMobSidebar] = useState(true);
  const [showTopHeader, setShowTopHeader] = useState(true);

  const initializeGSI = () => {
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      cancel_on_tap_outside: true,
      callback: loginWithWithCredential,
    });

    google.accounts.id.prompt();
  };

  const loginWithWithCredential = async (response) => {
    const data = await signInWithCredentialResponse(response);
    if (data) {
      setLoginRequest(false);
      navigate(0);
    }
  };

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };

  useEffect(() => {
    if (isUserSession) {
      setUniversalData(isUserSession);
      setUserInfo(isUserSession);
    } else {
      setUserInfo("");
      initializeGSI();
    }
  }, [isUserSession]);

  useEffect(() => {
    if(pathname.includes('setup')) {
      setShowTopHeader(false)
    } else {
      setShowTopHeader(true)
    }
  }, [pathname])

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if(universalData && isUserSession?.org?.id !== universalData?.org?.id) {
      window.location.reload()
    }
  }, [universalData?.org?.id, isUserSession?.org?.id])

  return (
    <>
      <Wrapper>
        {openSidebar && <HeaderSidebar />}
        {showTopHeader && <StickyHeader />}
        <div className="inner-header">
          <NavWrapper>
            
            {/* <Logo href="/">
              <img src={logo} alt="6ix Leads" width={"86"} height={"34"} />
            </Logo> */}
            {/* <HeaderLogo /> */}
            {/* <HeaderApps /> */}

            {isMobile ? (
              <Flex className="meeting6ix-layout-header-left add">
                <div
                  className="meeting6ix-layout-header-menuButton"
                  onClick={() => setOpenSidebar(!openSidebar)}
                >
                  <MenuIcon close={openSidebar} />
                </div>
                <div className="meeting6ix-layout-header-lineV"></div>
                <HeaderLogo />
              </Flex>
            ) : (
              <Flex className="meeting6ix-layout-header-left add">
                <HeaderLogo />
                {showTopHeader &&
                  <>
                    <HeaderInvest />
                    <HeaderAttract />
                    <HeaderExpertise />
                  </>
                }
              </Flex>
            )}
          </NavWrapper>
          <ActionsWrapper>
            {userInfo && (
              <>
                <SelectorDropdown label={userInfo.email} id="six" />
                <Separator />
              </>
            )}
            {/* <LanguageTranslate /> */}
            {/* <AppsDropdown label="APPS" id="apps" /> */}
            {userInfo ? (
              <ProfileDropdown user={userInfo} />
            ) : !loading ? (
              <>
                {!username && (
                  <LoginButton onClick={handleLogin}>
                    <i className="icn-wrap">
                      <UserIcon />
                    </i>
                    LOGIN
                  </LoginButton>
                )}
              </>
            ) : null}
          </ActionsWrapper>
          <div
            className={
              mobSidebar ? "sixmeetings-users" : "sixmeetings-users open"
            }
          >
            {/* new_ads button */}
            <div className="sixmeetings-users-newads">
              <div className="sixmeetings-users-newads_wrapper">
                <div className="sixmeetings_btn-wrapper">
                  {pathname.includes("types") ? (
                    <button
                      className="sixmeetings-users-newads-btn ass"
                      onClick={() => {
                        setMobSidebar(!mobSidebar);
                        navigate("/types");
                      }}
                    >
                      <AddIcon /> New Meeting
                    </button>
                  ) : (
                    <button
                      className="sixmeetings-users-newads-btn add"
                      onClick={() => {
                        setMobSidebar(!mobSidebar);
                        navigate("/types");
                      }}
                    >
                      <AddIcon /> New Meeting
                    </button>
                  )}
                </div>

                <nav aria-label="sidebar navigation">
                  <List className="side-nav-list" disablePadding>
                    {userInfo && (
                      <>
                        <ListItemButton
                          component="a"
                          href="/types"
                          className="sbwListButton"
                        >
                          <ListItemText primary={"Types"} />
                        </ListItemButton>
                      </>
                    )}
                  </List>
                </nav>
              </div>
            </div>
          </div>
          {/* sidebar_end */}
          <div
            className={mobSidebar ? "d-none" : "sixmeetings-overlay"}
            onClick={() => setMobSidebar(true)}
          ></div>
          {!userInfo && loginRequest ? (
            <LoginPopup dropOut={true} cancel={true} handleLogin={handleLogin} />
          ) : null}
          <ProfileModal open={open} onClose={handleClose}>
            <ModalContent>
              <form>
                {/* <PictureWrapper>
                  <img src={userInfo.profileImage || avatar} alt="" />
                </PictureWrapper> */}
                <EditPicButton>
                  <PencilIcon />
                  Edit photo
                </EditPicButton>
                <FormGroup>
                  <LabelHeading variant="caption">Email</LabelHeading>
                  <FormField name="email" hiddenLabel />
                </FormGroup>
                <FormGroup>
                  <LabelHeading variant="caption">Name</LabelHeading>
                  <FormField name="name" hiddenLabel />
                </FormGroup>
                <FormGroup>
                  <LabelHeading variant="caption">Phone Number</LabelHeading>
                  <FormField name="phone_num" hiddenLabel />
                </FormGroup>
                <FormGroup>
                  <LabelHeading variant="caption">Job title</LabelHeading>
                  <FormField name="job_title" hiddenLabel />
                </FormGroup>
                <FormGroup>
                  <LabelHeading variant="caption">Company</LabelHeading>
                  <FormField name="company" hiddenLabel />
                </FormGroup>
                <FormGroup>
                  <LabelHeading variant="caption">Investor Type</LabelHeading>
                  <CustomSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select"
                  >
                    <MenuItem>Ten</MenuItem>
                    <MenuItem>Twenty</MenuItem>
                    <MenuItem>Thirty</MenuItem>
                  </CustomSelect>
                </FormGroup>
                <FormGroup>
                  <LabelHeading variant="caption">Bio (Optional)</LabelHeading>
                  <TextAreaField name="bio" hiddenLabel />
                </FormGroup>
                <FormGroup>
                  <LabelHeading variant="caption">
                    Company Website (Optional)
                  </LabelHeading>
                  <FormField name="company_website" hiddenLabel />
                </FormGroup>
                <ButtonWrapper>
                  <ButtonPrimary type="submit">Save</ButtonPrimary>
                </ButtonWrapper>
              </form>
            </ModalContent>
          </ProfileModal>
        </div>
      </Wrapper>
      <Outlet />

      {/* <EventFooter /> */}
    </>
  );
};

export default HeaderNew;
