import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FilterBarsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M2.93489 7.96484L9.60156 7.96484" stroke="currentColor" strokeWidth="1.336" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M2.93489 3.96484L13.6016 3.96484" stroke="currentColor" strokeWidth="1.336" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M2.93489 11.9648L5.60156 11.9648" stroke="currentColor" strokeWidth="1.336" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};