import { styled, Box, Typography, Button, Menu, Link, TextField, FormControlLabel, Switch, FormControl, Divider } from '@mui/material';

export const Wrapper = styled('div')`
  
`;

export const BookWidget = styled(Box)`
  padding: 16px;
  border: 1px solid #373737;
  background-color: #2b2b2b;
  border-radius: 8px;

  @media (min-width: 992px){
    display: flex;
    gap: 12px;
    padding: 24px;
  }

  + div{
    margin-top: 16px;
  }
`;

export const DescriptionWrap = styled(Box)`

  @media (min-width: 576px){
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 18px;
  }
  @media (min-width: 992px){
    margin-bottom: 0;
  }
`;

export const BookingsList = styled(Box)`
  
`;

export const ButtonsGroup = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @media (min-width: 992px){
    justify-content: flex-end;
  }
`;

export const FirstCol = styled(Box)`
  margin-bottom: 12px;

  @media (min-width: 576px){
    width: calc(50% - 5px);
    margin-bottom: 0;
  }
`;

export const SecondCol = styled(Box)`
  margin-bottom: 12px;

  @media (min-width: 576px){
    width: calc(50% - 5px);
    text-align: right;
    margin-bottom: 0;
  }
  @media (min-width: 992px){
    text-align: left;
  }
`;

export const BookHeading = styled(Typography)`
  color: #E9E9E9;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;

export const BreadSubtitle = styled(Typography)`
  color: #E9E9E9;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
  display: block;
`;

export const TextList = styled(Typography)`
  display: block;
  color: #8C8E95;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 24px;
`;

export const StatItem = styled(Typography)`
  color: #E9E9E9;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  + span{
    margin-top: 8px;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
    color: #E3C050;
  }
`;

export const ButtonDefault = styled(Button)`
  background-color: transparent;
  border: 1px solid #373737;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px 16px;
  color: #C0C0C0;
  border-radius: 8px;
  text-transform: none;
  gap: 8px;

  &:disabled {
    color: #C0C0C0;
    background-color: transparent;
    border-color: #373737;
    opacity: 0.4;
  }

  &:hover{
    color: #fff;
    background-color: #454648;
    border-color: #e3c050;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
    margin-top: -1px;
  }
`;