import { StickyHeaderHolder } from "./style";

const StickyHeader = () => {
  

  return (
    <StickyHeaderHolder>
      <strong>Interested in Managing Meetings?</strong>
      <a href="/setup" className="header-top-link">Get started for FREE</a>
    </StickyHeaderHolder>
  );
};

export default StickyHeader;
