import { styled, Typography, Box, Tabs, Tab } from '@mui/material';

export const Wrapper = styled(Box)`
  width: 100%;
  padding-top: 91px;
  padding-bottom: 24px;
`;

export const SidebarHeading = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #C0C0C0;
  border-bottom: 1px solid #2B2B2B;
  padding-bottom: 16px;
  margin-bottom: 16px;
  display: block;

  i{
    vertical-align: -0.4em;
    margin-right: 8px;
    line-height: 0;

    img{
      max-width: 100%;
      height: auto;
    }
  }
`;

export const ContentColumns = styled(Box)`

  @media (min-width: 768px){
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: flex-start;
  }
  @media (min-width: 1200px){
    gap: 36px;
  }

  .tab-content {
    flex: 1;
  }
`;

export const ContentSidebar = styled(Box)`
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  

  .sixmeetings-users-newads-btn {

  }

  @media (min-width: 768px){
    /* width: 220px; */
    flex-shrink: 0;
    margin-bottom: 0;
  }


`;

export const ContentArea = styled(Box)`
  width: 100%;
  position: relative;
`;

export const UserHeading = styled(Typography)`
  font-size: 12px;
  color: #a1a1a1;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 20px;
`;

export const IconText = styled(`span`)`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid #575757;
  background-color: #373737;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .MuiSvgIcon-root{
    width: 20px;
    height: 20px;
  }
`;

export const TabsetList = styled(Tabs)`
  border-bottom: 1px solid #2b2b2b;
  .MuiTabs-indicator{
    display: none;
    /* background: #E3C050; */
  }
`;

export const TabButton = styled(Tab)`
  line-height: 40px;
  font-size: 16px;
  color: #575757;
  padding: 0px 8px 5px;
  text-align: left;
  text-transform: none;
  min-width: 1px;
  display: block;
  min-height: 1px;
  max-width: none;
  transition: color 0.3s ease;
  align-items: flex-start;
  text-transform: capitalize;
  border-bottom: 2px solid transparent;
  opacity: 1;
  color: #8C8E95;
  border-radius: 0;
  &:hover {
    /* border-color: #2B2B2B; */
    /* background: #2B2B2B; */
  }

  &.Mui-selected,
  &.Mui-selected:hover{
    color: #fff;
    /* background: rgba(43, 43, 43, 0.5); */
    border-color: #E3C050;
  }

  &:disabled,
  &.Mui-disabled {
    color: #575757;
    opacity: 0.7;
  }
`;