import CardBox from "components/CardBox";
import { Col, Header, HeaderHeading, ImageHolder, Wrapper, AlignBox, ItemHeading, Body, IconWrapper, ScrollWrapper } from "./style";
import Logo6ix from "../../assets/images/6ix-meetings-logo.svg"
import { TickIcon } from "components/Icons/TickIcon";
import { CrossIcon } from "components/Icons/CrossIcon";

const FeatureTableColumn = ({}: any) => (
  <CardBox>
    <ScrollWrapper>
      <Wrapper>
        <Header
          className="head"
        >
          <Col>
            <AlignBox>
              <HeaderHeading
                variant="h3"
              >
                Features & Capabilites
              </HeaderHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ImageHolder>
                <img src={Logo6ix} width={"60"} height={"34"} alt="6ix leads" />
              </ImageHolder>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <HeaderHeading
                variant="h3"
              >
                Other Tools
              </HeaderHeading>
            </AlignBox>
          </Col>
        </Header>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Investor-Centric Targeting
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Goal-Oriented Ad Designs
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Measurable Investor Impact Reports
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Budget Scalability
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Automatic
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Manual
              </ItemHeading>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Responsive Ad Designs
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Automatic
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Manual
              </ItemHeading>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Integrated Investor Engagement Metrics
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Automatic
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Manual
              </ItemHeading>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Ads Optimized for Multiple Devices
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Real-time Ad Performance Analytics
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Instant Feedback Loop for Ad Refinement
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Direct Communication Channel with Investors
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Customizable Ad Campaign Goals
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper>
                <CrossIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Advanced Investor Segmentation
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Retargeting Capabilities for Engaged Investors
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
        <Body
          className="body-row"
        >
          <Col>
            <AlignBox>
              <ItemHeading
                variant="h4"
              >
                Multi-Platform Ad Delivery
              </ItemHeading>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
          <Col>
            <AlignBox>
              <IconWrapper
                className="verified"
              >
                <TickIcon />
              </IconWrapper>
            </AlignBox>
          </Col>
        </Body>
      </Wrapper>
    </ScrollWrapper>
  </CardBox>
);

export default FeatureTableColumn;