import { Description, Heading, Wrapper, HeadingWrapper, StepBar, StepBarsWrapper, StepsWrapper, Title, BoxWrapper, ButtonPrimary, StepBackWrapper, LeftWrapper, ButtonLink, RightWrapper, SwitchEnabler, SwitchWrapper, AdditionalRow, Switcher, FormGroup, FormSelectField, FieldsWrapper, AdditionalBtns, ButtonIcon, FormAdditionalRow, SepSign, RepeaterWrap, ErrorText } from "./style";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FormControlLabel, MenuItem, Select } from "@mui/material";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { PlusIcon } from "components/Icons/PlusIcon";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { error } from "console";
import { Navigate, useNavigate } from "react-router-dom";
import AvailabilityForm from "components/AvailabilityForm";
import { availablityTimeBuilder } from "utils/helper";
import { useContext, useState } from "react";
import { AppContext } from "context";

export default function LoginStepThree({ setStep }: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { universalData } = useContext(AppContext)

  const onSubmit = (data: any) => {
    setLoading(true)
    baseApi.post('/api/adduserschedule', {
      userId: universalData?.userId,
      orgId: universalData?.org.id,
      availability: availablityTimeBuilder(data)
    }).then((res) => {
      toast.success(res?.data?.message)
      navigate('/setup/login-step-4')
    }).catch((err) => toast.error(err?.response?.data?.error))
      .finally(() => setLoading(false))
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Wrapper>
        <HeadingWrapper>
          <Heading
            variant="h1"
          >
            Set your availability
          </Heading>
          <Description
            variant="body1"
          >
            Define ranges of time when you are available on a recurring basis. You can create more of these later and assign them to different calendars.
          </Description>
        </HeadingWrapper>
        <StepsWrapper>
          <Title
            variant="h2"
          >
            Step 3 of 4
          </Title>
          <StepBarsWrapper>
            <StepBar className="active"></StepBar>
            <StepBar className="active"></StepBar>
            <StepBar className="active"></StepBar>
            <StepBar></StepBar>
          </StepBarsWrapper>
        </StepsWrapper>
        <AvailabilityForm onSubmit={onSubmit} loading={loading} />
        {/* <StepBackWrapper>
          <LeftWrapper>
            <ButtonLink onClick={() => navigate('/login-step-2')} type="button">
              Prev step
            </ButtonLink>
          </LeftWrapper>
          <RightWrapper>
            <ButtonLink onClick={() => navigate('/login-step-4')} type="button">
              Skip this step
            </ButtonLink>
          </RightWrapper>
        </StepBackWrapper> */}
      </Wrapper>
    </LocalizationProvider>

  )
}