import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const TimeClockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" stroke="currentColor" strokeWidth="1.90857" strokeLinecap="round" strokeLinejoin="round"/>
      <path fill="none" d="M9.4668 5.2002V10.0002L12.6668 11.6002" stroke="currentColor" strokeWidth="1.90857" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};