import { Authorized } from "./Authorized";
import { Unauthorized } from "./Unauthorized";
import { SESSION_AUTH } from "utils/auth";

export const AppRouter = () => {
  const { isUserSession } = SESSION_AUTH()
  
  return (
    <>
      { isUserSession ? <Authorized /> : <Unauthorized />}
    </>
  )
}
